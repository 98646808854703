import PageHome from '../components/PageHome';
import PageRefill from '../components/PageRefill';
import PageRefillSuccess from '../components/PageRefillSuccess';
import PageRefillError from '../components/PageRefillError';

export default [
    {
        path: '/credits',
        component: PageHome
    }, {
        path: '/credits/refill',
        component: PageRefill
    }, {
        path: '/credits/refill/success',
        name: '/credits/refill/success',
        component: PageRefillSuccess
    }, {
        path: '/credits/refill/error',
        name: '/credits/refill/error',
        component: PageRefillError
    }
]
