<template>
    <div>
        <div class="columns">
            <div class="column hide-xs">
                <router-link to="/surveys/archive">
                    <el-button
                        class="m-1"
                        icon="el-icon-tickets"
                        round
                    >{{ $t('surveys.archive.btn') }}</el-button>
                </router-link>
            </div>
            <div class="column" style="flex-basis: auto;">
                <h1 class="text-primary text-center hide-xs">{{ $t('surveys.home.h') }}</h1>
                <h1 class="text-primary show-xs">{{ $t('surveys.home.h') }}</h1>
            </div>
            <div class="column text-right">
                <router-link to="/surveys/add">
                    <el-button
                        class="hide-xs m-1"
                        type="danger"
                        icon="el-icon-plus"
                        round
                    >{{ $t('surveys.home.btn_new') }}</el-button>
                    <el-button
                        class="show-xs m-1 mb-2"
                        type="danger"
                        icon="el-icon-plus"
                        circle
                    ></el-button>
                </router-link>
                <router-link to="/surveys/archive">
                    <el-button
                        class="show-xs m-1"
                        icon="el-icon-tickets"
                        circle
                    ></el-button>
                </router-link>
            </div>
        </div>

        <div v-if="mylist.length && (SURVEYS_PROMO.length || SURVEYS_ACTIVE.length) && amount < 500">
            <br>
            <el-card shadow="never" class="text-center mb-2">
                <i class="el-icon-warning icon-3x text-warning"></i>
                <h2 class="m-2"><strong>{{ $t('credits.nomoney.h') }}</strong></h2>
                <div class="columns">
                    <div class="column">
                        <p class="text-gray">{{ $t('credits.nomoney.txt') }}</p>
                    </div>
                </div>
                <router-link v-if="true" :to="'/credits/refill'">
                    <el-button
                        class="m-2"
                        type="warning"
                        round
                    >{{ $t('credits.home.btn_buy') }}</el-button>
                </router-link>
            </el-card>
            <br><br>
        </div>

        <div class="text-center">
            <transition
                mode="out-in"
                name="el-fade-in"
            >
                <div v-if="loading.newer" v-loading="loading.newer"></div>
                <el-button v-else size="mini" icon="el-icon-refresh" circle @click="loadNewer"></el-button>
            </transition>
        </div>
        <br>

        <template v-if="mylist.length">
            <template v-if="SURVEYS_NEW.length">
                <h5 class="text-center text-gray">{{ $t('surveys.home.h_new') }}</h5>
                <br>
                <survey-list-item
                    v-for="survey in SURVEYS_NEW"
                    :key="survey.id"
                    :survey="survey"
                />
                <br>
            </template>

            <template v-if="SURVEYS_PROMO.length">
                <h5 class="text-center text-gray">{{ $t('surveys.home.h_promo') }}</h5>
                <br>
                <survey-list-item
                    v-for="survey in SURVEYS_PROMO"
                    :key="survey.id"
                    :survey="survey"
                />
                <br>
            </template>

            <template v-if="SURVEYS_ACTIVE.length">
                <h5 class="text-center text-gray">{{ $t('surveys.home.h_active') }}</h5>
                <br>
                <survey-list-item
                    v-for="survey in SURVEYS_ACTIVE"
                    :key="survey.id"
                    :survey="survey"
                />
                <br>
            </template>

            <template v-if="SURVEYS_STOPPED.length">
                <h5 class="text-center text-gray">{{ $t('surveys.home.h_stopped') }}</h5>
                <br>
                <survey-list-item
                    v-for="survey in SURVEYS_STOPPED"
                    :key="survey.id"
                    :survey="survey"
                />
                <br>
            </template>

            <div v-if="!bottom" class="text-center">
                <div v-if="loading.older" v-loading="loading.older"></div>
                <el-button v-else size="mini" icon="el-icon-more" circle @click="loadOlder"></el-button>
            </div>
        </template>
        <div v-else class="text-center m-2 p-2 text-gray">{{ $t('surveys.home.nodata') }}</div>

        <template v-if="user.profile_lang_first == 'pl'">
            <br>
            <h3 class="text-center text-primary"><strong>answeo</strong> <small>poleca <strong>ProfiTest.pl</strong></small></h3>
            <h5 class="text-center text-primary">profesjonalne narzędzie do tworzenia ankiet online</h5>
            <br>
            <el-card class="centered" style="max-width: 1000px;">
                <div class="px-1">
                    <a href="https://profitest.pl?utm_source=answeo&utm_campaign=my-surveys&utm_content=banner-bottom" target="_blank">
                        <img class="img-responsive centered py-2" src="@/assets/banner-profitest-pl.png" />
                    </a>
                </div>
            </el-card>
        </template>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import SurveyListItem from '@/Surveys/components/SurveyListItem';
export default {
    components: {
        SurveyListItem
    },
    data () {
        return {
            loading: {
                newer: false,
                older: false
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user,
            account: state => state.credits.account,
            bottom: state => state.surveys.mylistBottom,
            mylist: state => state.surveys.mylist
        }),
        ...mapGetters([
            'SURVEYS_NEW',
            'SURVEYS_PROMO',
            'SURVEYS_ACTIVE',
            'SURVEYS_STOPPED'
        ]),
        amount () {
            return this.account.balance - this.account.balance_lock;
        }
    },
    created () {
        this.loadNewer();
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
            'SURVEYS_MY_LOAD_NEWER',
            'SURVEYS_MY_LOAD_OLDER'
        ]),
        loadNewer () {
            this.loading.newer = true;
            this.SURVEYS_MY_LOAD_NEWER().then(() => {
                this.loading.newer = false;
            });
        },
        loadOlder () {
            this.loading.older = true;
            this.SURVEYS_MY_LOAD_OLDER().then(() => {
                this.loading.older = false;
            });
        }
    }
}
</script>

<style lang="scss">
</style>
