<template>
    <el-container id="app-theme">
        <el-header style="height: auto; font-weight: 200;">
            <div class="container bg-primary">
                <div class="columns col-oneline col-gapless d-flex-center">
                    <div class="column pb-1" :class="userAfterSetup?'':'text-center'">
                        <span
                            class="c-hand"
                            style="font-size: 1.5em;"
                            @click="home"
                        >
                            ans<span class="text-bold">we</span>o
                        </span>
                        <!-- <div
                            v-if="!userAfterSetup"
                            class="absolute"
                            style="right: 8px; top: 8px;"
                        >
                            <el-dropdown
                                class="text-light"
                                @command="setLocale"
                            >
                                <span class="el-dropdown-link c-hand">
                                    {{ locale.split(/[-_]/)[0].toUpperCase() }}<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item
                                        v-for="lang in languages"
                                        :key="lang.code"
                                        :command="lang.code"
                                    >{{ lang.name }}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div> -->
                    </div>
                    <div
                        v-if="userAfterSetup"
                        class="column hide-xs"
                        style="flex-grow: 3;"
                    >
                        <main-theme-menu />
                    </div>
                    <div v-if="userAfterSetup" class="column text-right">
                        <el-dropdown @command="$event ? $router.push($event) : signOut()">
                            <div class="d-flex d-flex-center text-light c-hand">
                                <div class="text-large text-ellipsis mr-1" style="max-width: 110px;">{{ user.name }}</div>
                                <i class="icon ion-ios-contact" style="font-size: 2.5em;"></i>
                            </div>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="/settings">
                                    <i class="icon ion-ios-cog"></i>
                                    {{ $t('app.settings') }}
                                </el-dropdown-item>
                                <el-dropdown-item v-if="user.privileges === 'ADMIN'" command="/admin/withdrawals">
                                    <i class="icon ion-ios-construct"></i>
                                    {{ 'Admin Panel' || $t('app.admin') }}
                                </el-dropdown-item>
                                <el-dropdown-item
                                    divided
                                    :command="$router.currentRoute.path"
                                />
                                <el-dropdown-item command="/auth/account">
                                    <i class="icon ion-ios-contact"></i>
                                    {{ $t('app.account') }}
                                </el-dropdown-item>
                                <el-dropdown-item
                                    class="text-error"
                                    command=""
                                >
                                    <i class="icon ion-ios-power"></i>
                                    {{ $t('app.signOut') }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </div>
            <div v-if="userAfterSetup" class="show-xs p-1">
                <main-theme-menu />
            </div>
            <template v-if="user.delete_time">
                <el-alert
                    type="warning"
                    show-icon center
                    :closable="false"
                    :title="$t('auth.del.h')"
                    :description="$t('auth.del.sub', {time: $d(parseDate(user.delete_time), 'long')})"
                ></el-alert>
                <div class="text-center">
                    <el-button
                        style="width: 100%; border-radius: 0;"
                        type="danger"
                        @click="undelete"
                    >{{ $t('auth.del.btn_cl') }}</el-button>
                </div>
            </template>
        </el-header>
        <el-main id="main-view">
            <div class="container grid-xl pt-1">
                <slot />
            </div>
        </el-main>
        <!-- <el-footer class="text-center p-0" style="height: auto;">
            <div class="hide-sm p-2">
                <small>
                    <span class="text-primary text-large">ans</span><span class="text-error text-large text-bold">we</span><span class="text-primary text-large">o</span>
                    <span class="text-error"><i class="icon ion-ios-heart"></i></span>
                    <span class="text-primary">You</span>
                </small>
            </div>
            <small class="show-sm">
                <span class="text-primary text-large">ans</span><span class="text-error text-large text-bold">we</span><span class="text-primary text-large">o</span>
                <span class="text-error"><i class="icon ion-ios-heart"></i></span>
                <span class="text-primary">You</span>
            </small>
            <router-link
                v-if="user.profile_id"
                class="c-hand"
                to="/affiliate"
                tag="div"
            >
                <el-alert
                    :title="$t('app.affiliate.txt.recommend')"
                    type="success"
                    center
                    show-icon
                    :closable="false"
                ></el-alert>
            </router-link>
        </el-footer> -->
    </el-container>
</template>

<script>
import parseDate from 'date-fns/parse';
import { mapState, mapMutations, mapActions } from 'vuex';
import { i18nSetLanguage } from '@/locale/i18n';
import languages from '@/locale/languages';
import Api from '@/App/api';
import MainThemeMenu from '@/App/components/MainThemeMenu';
export default {
    components: {
        MainThemeMenu
    },
    props: ['userAfterSetup'],
    data () {
        return {
            languages: []
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
        locale () {
            return this.$i18n.locale;
        }
    },
    watch: {
        $route: function (route) {
             setTimeout(() => {
                 document.getElementById('main-view').scrollTop = 0;
             }, 300);
        }
    },
    created () {
        this.languages = languages;
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
            'AUTH_ACCOUNT_DELETE'
        ]),
        parseDate,
        home () {
            if (this.user.id) {
                this.$router.push('/');
            } else {
                document.location = '/';
            }
        },
        setLocale (locale) {
            i18nSetLanguage(locale.replace('_', '-'));
        },
        signOut () {
            Api.signOut().then((response) => {
                document.location = process.env.APP_BASE_URL;
            });
        },
        undelete () {
            this.AUTH_ACCOUNT_DELETE();
        }
    }
}
</script>

<style lang="scss">
</style>
