<template>
  <div>
    <h1 class="text-center text-primary">Administration Panel</h1>
    <el-tabs stretch v-model="page" @tab-click="navigate">
      <el-tab-pane label="Withdrawals" name="withdrawals"></el-tab-pane>
      <el-tab-pane label="Recharges" name="recharges"></el-tab-pane>
      <el-tab-pane label="Vouchers" name="vouchers"></el-tab-pane>
    </el-tabs>
    <br>
    <router-view />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
    components: {
    },
    data () {
        return {
            page: this.$route.path.split('/').pop() || 'withdrawals'
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        navigate () {
          this.$router.push('/admin/'+this.page);
        }
    }
}
</script>
