const SURVEYS_CLEAR = 'SURVEYS_CLEAR';
const SURVEYS_PUSH_NEWER = 'SURVEYS_PUSH_NEWER';
const SURVEYS_PUSH_OLDER = 'SURVEYS_PUSH_OLDER';
const SURVEYS_ARCHIVE_CLEAR = 'SURVEYS_ARCHIVE_CLEAR';
const SURVEYS_ARCHIVE_PUSH_NEWER = 'SURVEYS_ARCHIVE_PUSH_NEWER';
const SURVEYS_ARCHIVE_PUSH_OLDER = 'SURVEYS_ARCHIVE_PUSH_OLDER';
const SURVEYS_COMMENTS_PUSH = 'SURVEYS_COMMENTS_PUSH';
const SURVEYS_COMMENTS_CLEAR = 'SURVEYS_COMMENTS_CLEAR';
const SURVEYS_ARCHIVE_DELETE = 'SURVEYS_ARCHIVE_DELETE';
const SURVEYS_DELETE = 'SURVEYS_DELETE';

export default {
    [SURVEYS_CLEAR] (state, payload) {
        state.mylist = [];
        state.mylistBottom = false;
    },
    [SURVEYS_PUSH_NEWER] (state, payload) {
        for (var item of payload) {
            var index = state.mylist.findIndex(x => x.id === item.id);
            if (index >= 0) {
                state.mylist.splice(index, 1);
            }
        }
        state.mylist.splice(0, 0, ...payload);
    },
    [SURVEYS_PUSH_OLDER] (state, payload) {
        for (var item of payload) {
            var index = state.mylist.findIndex(x => x.id === item.id);
            if (index >= 0) {
                state.mylist.splice(index, 1);
            }
        }
        state.mylist.push(...payload);
        if (payload.length == 0) {
            state.mylistBottom = true;
        }
    },
    [SURVEYS_ARCHIVE_CLEAR] (state, payload) {
        state.archive = [];
        state.archiveBottom = false;
    },
    [SURVEYS_ARCHIVE_PUSH_NEWER] (state, payload) {
        for (var item of payload) {
            var index = state.archive.findIndex(x => x.id === item.id);
            if (index >= 0) {
                state.archive.splice(index, 1);
            }
        }
        state.archive.splice(0, 0, ...payload);
    },
    [SURVEYS_ARCHIVE_PUSH_OLDER] (state, payload) {
        for (var item of payload) {
            var index = state.archive.findIndex(x => x.id === item.id);
            if (index >= 0) {
                state.archive.splice(index, 1);
            }
        }
        state.archive.push(...payload);
        if (payload.length == 0) {
            state.archiveBottom = true;
        }
    },
    [SURVEYS_COMMENTS_PUSH] (state, payload) {
        for (var item of payload) {
            var index = state.comments.findIndex(x => x.id === item.id);
            if (index >= 0) {
                state.comments.splice(index, 1);
            }
        }
        state.comments.push(...payload);
        if (payload.length == 0) {
            state.commentsBottom = true;
        }
    },
    [SURVEYS_COMMENTS_CLEAR] (state, payload) {
        state.comments = [];
        state.commentsBottom = false;
    },
    [SURVEYS_ARCHIVE_DELETE] (state, payload) {
        state.archive.splice(state.archive.findIndex(x => x.id === payload.id), 1);
    },
    [SURVEYS_DELETE] (state, payload) {
        state.mylist.splice(state.mylist.findIndex(x => x.id === payload.id), 1);
    }
}
