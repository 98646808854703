<template>
    <div>
        <div v-loading.fullscreen.lock="initializing"></div>
        <el-container v-if="!initializing" id="app-theme">
            <el-header style="height: 28px; font-weight: 200; position: fixed; top: 0; left: 0; right: 0; z-index: 99;">
                <div class="container bg-primary">
                    <div class="d-flex d-flex-center d-flex-space-between">
                        <div v-if="!responding"></div>
                        <div class="pb-1">
                            <span style="font-size: 1em;">
                                ans<span class="text-bold">we</span>o
                            </span>
                        </div>
                        <div v-if="!responding"></div>
                        <div v-if="responding" class="text-ellipsis pb-1">
                            <small>
                                <i class="el-icon-time"></i>
                                <relative-time
                                    :time="response.expire_time"
                                    @update="responding = ($event < 0)"
                                />
                            </small>
                        </div>
                        <div v-if="responding" class="">
                            <el-dropdown size="mini" @command="report">
                                <el-button size="mini" type="primary" class="px-2">
                                    {{ $t('responds.report.btn') }} <i class="el-icon-arrow-down el-icon--right hide-xs"></i>
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <template v-if="!confirming">
                                        <el-dropdown-item command="WRONG_SURVEY_LINK">{{ $t('responds.report.WRONG_SURVEY_LINK') }}</el-dropdown-item>
                                        <el-dropdown-item command="NOT_A_SURVEY">{{ $t('responds.report.NOT_A_SURVEY') }}</el-dropdown-item>
                                        <el-dropdown-item command="NO_END_LINK">{{ $t('responds.report.NO_END_LINK') }}</el-dropdown-item>
                                    </template>
                                    <template v-else >
                                        <el-dropdown-item command="WRONG_END_LINK">{{ $t('responds.report.WRONG_END_LINK') }}</el-dropdown-item>
                                    </template>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                </div>
            </el-header>
            <el-main id="main-view" class="responding-view">
                <div v-if="!ruuid" class="container grid-xl pt-1">
                    <div class="text-center col-6 col-md-8 col-sm-10 col-xs-12 col-mx-auto">
                        <br>
                        <div class="text-center">
                          <small
                            v-for="(lang, i) in languages"
                            :key="lang.code"
                            class="c-hand"
                          >
                            <span v-if="i">&centerdot;</span>
                            <a class="m-1" @click="setLocale(lang.code)">{{ lang.name }}</a>
                          </small>
                        </div>
                        <br>
                        <h3>{{ $t('responds.start.recommend.h') }}</h3>
                        <p v-html="$t('responds.start.recommend.txt')"></p>
                        <br>
                        <router-link
                            to="/auth/sign-in"
                            target="_blank"
                        >
                            <el-button type="primary" round>{{ $t('responds.start.recommend.btn') }}</el-button>
                        </router-link>
                        <br><br>
                        <p><small class="text-gray">{{ $t('responds.start.recommend.info') }}</small></p>
                    </div>
                </div>
                <div v-else-if="profileUpdateRequired" class="container grid-xl pt-1 text-center">
                  <br><br>
                  <h3>{{ $t('responds.start.profile.h') }}</h3>
                  <br>
                  <p>{{ $t('responds.start.profile.txt') }}</p>
                  <br>
                  <el-button
                    type="primary"
                    round
                    @click="home"
                  >
                    {{ $t('responds.start.profile.btn') }}
                    <i class="el-icon-arrow-right el-icon-right"></i>
                  </el-button>
                </div>
                <div v-else-if="error" class="container grid-xl pt-1">
                    <div class="text-center col-6 col-md-8 col-sm-10 col-xs-12 col-mx-auto">
                        <el-alert
                            :title="error"
                            type="error"
                            :closable="false"
                        ></el-alert>
                    </div>
                </div>
                <div v-else-if="question" class="container grid-xl pt-1">
                    <respond-screen-out-question
                        :question="question"
                        :answer="answers['q'+question.id]"
                        @update="$set(answers, 'q'+question.id, $event)"
                        @next="start"
                    />
                </div>
                <div v-else-if="!response || response.expired" class="container grid-xl pt-1">
                    <div class="text-center col-6 col-md-8 col-sm-10 col-xs-12 col-mx-auto">
                        <el-alert
                            :title="$t('responds.errors.RESPOND_FORBIDDEN')"
                            type="error"
                            :closable="false"
                        ></el-alert>
                    </div>
                </div>
                <div v-else-if="response.complete_time == response.create_time" class="container grid-xl pt-1">
                    <respond-screen-out />
                </div>
                <div v-else-if="response.complete_time" class="container grid-xl pt-1">
                    <respond-complete
                        :response="response"
                        :suuid="suuid"
                        :ruuid="ruuid"
                        @update:charity_percent="response.charity_percent = $event"
                    />
                </div>
                <div v-else-if="confirming" class="container grid-xl pt-1">
                    <page-confirm
                        :remail="response.remail"
                        :rcode="rcode"
                    />
                </div>
                <iframe
                    v-else-if="url"
                    :src="url"
                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                    width="100%"
                    height="100%"
                    @load="confirm"
                ></iframe>
            </el-main>
        </el-container>
        <el-dialog
            v-if="maxTimeInfo"
            :title="$t('responds.start.time.h')"
            :visible.sync="maxTimeInfo"
            :show-close="false"
            center
        >
            <div class="text-center">
                <p class="my-2">{{ $t('responds.start.time.txt1') }} <strong><relative-time :time="response.expire_time" /></strong> {{ $t('responds.start.time.txt2') }}</p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" round @click="maxTimeInfo = false">{{ $t('responds.start.time.btn') }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { differenceInYears } from 'date-fns';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { i18nSetLanguage } from '@/locale/i18n';
import languages from '@/locale/languages';
import Api from '@/Responds/api';
import PageConfirm from '@/Responds/components/PageConfirm';
import RespondScreenOut from '@/Responds/components/RespondScreenOut';
import RespondScreenOutQuestion from '@/Responds/components/RespondScreenOutQuestion';
import RespondComplete from '@/Responds/components/RespondComplete';
import RelativeTime from '@/App/components/utils/RelativeTime';
export default {
    components: {
        PageConfirm,
        RespondScreenOut,
        RespondScreenOutQuestion,
        RespondComplete,
        RelativeTime
    },
    data () {
        return {
            languages,
            initializing: true,
            response: null,
            question: null,
            answers: {},
            error: null,
            suuid: null,
            ruuid: null,
            rcode: null,
            profileUpdateRequired: false,
            responding: false,
            maxTimeInfo: false,
            confirming: false,
            utm_medium: ''
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
        ...mapGetters([
        ]),
        url () {
            if (this.response && this.response.survey_url.match(/^https?:\/\//i)) {
                // var token = '';
                // if (this.response.survey_url.match(/^https?:\/\/profitest\.pl/i)) {
                //     token = '/answeo.'+this.response.id+'.'+this.ruuid;
                // }
                var utm = 'utm_source=answeo.com&utm_medium='+this.utm_medium;
                var parts = this.response.survey_url.split('?');
                // var url = parts[0].replace(/\/$/,'') + token + '?' + utm;
                var url = parts[0] + '?' + utm;
                if (parts.length > 1) {
                    url = url + '&' + parts[1];
                }
                return url.replace('[UID]', this.ruuid).replace('[SID]', this.response.id);
            } else {
                return null;
            }
        }
    },
    created () {
        this.suuid = this.$route.params.suuid;
        this.ruuid = this.$route.params.ruuid || this.user.uuid || null;
        this.utm_medium = this.$route.params.ruuid ? 'search' : 'affiliate';
        this.start();
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        setLocale (locale) {
          i18nSetLanguage(locale.replace('_', '-'));
        },
        start () {
            this.initializing = true;
            if (this.ruuid) {
                Api.respond({
                    suuid: this.suuid,
                    ruuid: this.ruuid,
                    answers: JSON.stringify(this.answers)
                }).then((response) => {
                    this.response = response.data;
                    this.question = null;
                    this.responding = !this.response.complete_time && !this.response.expired;
                    this.maxTimeInfo = this.responding;
                    this.initializing = false;
                }).catch((error) => {
                    if (error.response.status === 403 && error.response.data.message === 'Profile update required') {
                        this.profileUpdateRequired = true;
                    } else if (error.response.status === 400) {
                        this.question = error.response.data;
                        this.error = null;
                    } else if (error.response.status === 422 || error.response.status === 404) {
                        this.question = null;
                        this.error = this.$t('responds.errors.NOT_FOUND');
                    } else {
                        this.question = null;
                        this.error = this.$t('responds.errors.'+error.response.data.code);
                    }
                    this.initializing = false;
                });
            } else {
                this.initializing = false;
            }
        },
        report (code) {
            this.$confirm(this.$t('responds.report.dialog.msg'), this.$t('responds.report.'+code), {
                confirmButtonText: this.$t('responds.report.dialog.btn_ok'),
                cancelButtonText: this.$t('responds.report.dialog.btn_cancel'),
                showClose: false
            }).then(() => {
                this.$message({
                    type: 'info',
                    message: this.$t('responds.report.dialog.done')
                });
                Api.report({
                    suuid: this.suuid,
                    ruuid: this.ruuid,
                    code: code
                });
            }).catch(() => {});
        },
        confirm ($event) {
            try {
                var parts = $event.target.contentWindow.location.split('/');
                this.rcode = parts[parts.length - 1];
                this.confirming = true;
            } catch (e) {}
        },
        home () {
          document.location = process.env.APP_BASE_URL;
        }
    }
}
</script>

<style lang="scss">
#main-view.responding-view {
    padding: 0;
    margin: 0;
    position: fixed;
    left: 0;
    right: 0;
    top: 28px;
    bottom: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    iframe {
        width: 100%;
        height: 100%;
        border: none;
        margin: 0;
        padding: 0;
        html, body {
          margin: 0;
          padding: 0;
        }
    }
}
</style>
