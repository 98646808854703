<template>
  <div>
    <div class="container grid-xs">
      <el-input type="email" v-model="recharge.email" placeholder="Email"></el-input>
      <el-input-number class="el-select--block" v-model="recharge.amount" :precision="2" :step="0.01" :min="0.01"></el-input-number>
      <el-button type="primary" class="el-select--block" :disabled="!recharge.email" :loading="recharge.loading" @click="rechargeAccount">Recharge</el-button>
    </div>
    <br>
    <div class="text-center">
        <transition
            mode="out-in"
            name="el-fade-in"
        >
            <div v-if="historyLoading == 'newer'" v-loading="historyLoading"></div>
            <el-button v-else size="mini" icon="el-icon-refresh" circle @click="historyLoadNewer"></el-button>
        </transition>
    </div>
    <br>
    <template v-if="history.length">
    <el-table
        :data="history"
        size="mini"
    >
      <el-table-column
          prop="id"
          align="left"
          label="TransID"
      ></el-table-column>
      <el-table-column
          align="left"
          label="User"
      >
        <div slot-scope="scope">{{ scope.row.email }}</div>
      </el-table-column>
      <el-table-column
          align="center"
          label="Open Time"
          width="150"
      >
        <div slot-scope="scope" class="text-nowrap">{{ scope.row.open_time.substr(0, 16) }}</div>
      </el-table-column>
      <!-- <el-table-column
          prop="close_time"
          align="center"
          label="Close Time"
          width="150"
      >
        <div slot-scope="scope" class="text-nowrap">{{ (scope.row.close_time || scope.row.expire_time).substr(0, 16) }}</div>
      </el-table-column> -->
      <el-table-column
          align="center"
          label="Gateway"
          width="90"
      >
        <div slot-scope="scope">
          <el-popover placement="bottom" popper-class="recharge-transaction-data" trigger="hover" :open-delay="2000">
            <pre><small>{{ scope.row.data }}</small></pre>
            <span slot="reference">{{ scope.row.data.gateway }}</span>
          </el-popover>
        </div>
      </el-table-column>
      <el-table-column
          align="right"
          label="Recharge"
          width="90"
      >
        <div slot-scope="scope">$ {{ (scope.row.amount/100.0).toFixed(2) }}</div>
      </el-table-column>
      <el-table-column
          align="right"
          label="VAT"
          width="50"
      >
        <div v-if="scope.row.data.vat !== undefined" slot-scope="scope">{{ scope.row.data.vat }}%</div>
      </el-table-column>
      <el-table-column
          align="right"
          label="Tax"
          width="70"
      >
        <div v-if="scope.row.data.tax !== undefined" slot-scope="scope">$ {{ (scope.row.data.tax/100.0).toFixed(2) }}</div>
      </el-table-column>
      <!-- <el-table-column
          align="center"
          label="Status"
          fixed="right"
          width="100"
      >
        <div slot-scope="scope">
          <span>{{ scope.row.status }}</span>
        </div>
      </el-table-column> -->
      <el-table-column
        align="center"
        label="Invoice"
      >
        <div v-if="scope.row.company_invoice" slot-scope="scope">
          <el-popover placement="bottom" popper-class="recharge-transaction-data" trigger="hover" :open-delay="2000">
            <pre><small>{{ scope.row.company_invoice }}</small></pre>
            <i class="el-icon-view" slot="reference"></i>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>
    <br>
    <div v-if="!historyBottom" class="text-center">
        <div v-if="historyLoading == 'older'" v-loading="historyLoading"></div>
        <el-button v-else size="mini" icon="el-icon-more" circle @click="historyLoadOlder"></el-button>
    </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import Api from '../api';

export default {
    components: {
    },
    data () {
        return {
            history: [],
            historyBottom: false,
            historyLoading: null,
            recharge: {
              email: null,
              amount: 0.01,
              loading: false
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
    },
    created () {
        this.historyLoadNewer();
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        historyLoadNewer () {
          this.historyLoading = 'newer';
          this.history = [];
          this.historyLoadOlder();
        },
        historyLoadOlder () {
          this.historyLoading = this.historyLoading || 'older';
          if (this.history.length) {
            var last = this.history[this.history.length - 1];
            var params = {
                id: last.id
            };
          } else {
            var params = {
                id: 2147483647
            };
          }
          Api.recharges(params).then((response) => {
            for (var item of response.data) {
              item.data = JSON.parse(item.data);
              item.company_invoice = item.company_invoice ? JSON.parse(item.company_invoice) : '';
              this.history.push(item);
            }
            if (response.data.length == 0) {
                this.historyBottom = true;
            }
            this.historyLoading = null;
          });
        },
        async rechargeAccount () {
          this.recharge.loading = true;
          await Api.rechargeAccount({
            email: this.recharge.email,
            amount: this.recharge.amount * 100
          });
          this.recharge.loading = false;
          this.recharge.email = null;
          this.recharge.amount = 0.01;
          this.historyLoadNewer();
        }
    }
}
</script>

<style>
.recharge-transaction-data {
  max-width: 300px;
  max-height: 300px;
  overflow: auto;
}
</style>
