<template>
    <div class="col-6 col-md-8 col-sm-10 col-xs-12 col-mx-auto">
        <h1 class="text-center text-primary">{{ $t('auth.email.h') }}</h1>
        <el-alert
            v-show="message"
            class="mb-2"
            type="info"
            show-icon
            :closable="false"
            :title="message"
        ></el-alert>
        <div
            v-if="done"
            class="text-right"
        >
            <el-alert
                class="text-left mb-2"
                type="info"
                show-icon
                :closable="false"
                :title="$t('auth.email.done.h')"
                :description="$t('auth.email.done.txt')"
            ></el-alert>
            <el-button
                type="primary"
                @click="cancel"
            >{{ $t('auth.email.btn_done') }}</el-button>
        </div>
        <el-form
            v-else
            @submit.native.prevent="submit"
        >
            <el-form-item
                :label="$t('auth.email.f.email.l')"
                :error="errors.first('email')"
            >
                <el-input
                    v-model="form.email"
                    name="email"
                    type="email"
                    v-validate="'required|email'"
                    :data-vv-as="$t('auth.email.f.email.e')"
                    :placeholder="$t('auth.email.f.email.p')"
                ></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('auth.email.f.pass.l')"
                :error="errors.first('password')"
            >
                <el-input
                    v-model="form.password"
                    name="password"
                    type="password"
                    v-validate="'required'"
                    :data-vv-as="$t('auth.email.f.pass.e')"
                    :placeholder="$t('auth.email.f.pass.p')"
                ></el-input>
            </el-form-item>
            <el-form-item class="text-right">
                <el-button @click="cancel">{{ $t('auth.email.f.btn_cl') }}</el-button>
                <el-button
                    native-type="submit"
                    type="primary"
                    :loading="loading"
                    :disabled="form.email == user.email || errors.any()"
                >{{ $t('auth.email.f.btn_ok') }}</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
    components: {
    },
    $_veeValidate: {
        validator: 'new'
    },
    data () {
        return {
            form: {
                email: null,
                password: null
            },
            loading: false,
            done: false,
            message: ''
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        })
    },
    created () {
        this.form.email = this.user.email;
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
            'AUTH_ACCOUNT_EMAIL',
            // 'AUTH_ACCOUNT_EMAIL_CANCEL'
        ]),
        cancel () {
            this.$router.push('/auth/account/');
        },
        submit () {
            this.$validator.validate().then((result) => {
                if (result) {
                    this.message = '';
                    this.loading = true;
                    this.AUTH_ACCOUNT_EMAIL(this.form).then(() => {
                        this.done = true;
                        this.loading = false;
                    }).catch((e) => {
                        this.message = e.response.data ? this.$t('auth.'+e.response.data.code) : ''+e;
                        this.loading = false;
                    });
                }
            });
        }
    }
}
</script>

<style lang="scss">
</style>
