<template>
    <div>
        <br><br>
        <div v-loading.fullscreen.lock="pageloading"></div>
        <div v-show="!pageloading" class="col-6 col-lg-8 col-md-10 col-sm-11 col-xs-12 col-mx-auto">
            <h1 class="text-center text-primary">{{ $t('auth.passchng.h') }}</h1>
            <br>

            <el-alert
                v-if="wrongsec"
                class="mb-2"
                type="info"
                show-icon
                :closable="false"
                :title="$t('auth.passchng.err')"
            ></el-alert>

            <template v-else-if="success">
            <el-alert
                type="info"
                show-icon
                :closable="false"
                :title="$t('auth.passchng.done')"
            ></el-alert>
            <br>
            <div class="text-center">
                <el-button
                    type="primary"
                    @click="next"
                >OK</el-button>
            </div>
            </template>

            <template v-else >
            <el-alert
                v-show="errormsg"
                class="mb-2"
                type="info"
                show-icon
                :closable="false"
                :title="errormsg"
            ></el-alert>
            <el-form
                class="pt-2"
                @submit.native.prevent="change"
            >
                <el-form-item
                    :label="$t('auth.passchng.f.pass.l')"
                    :error="errors.first('password')"
                >
                    <el-input
                        v-model="form.password"
                        name="password"
                        :type="passwordShow ? 'text' : 'password'"
                        v-validate="'required'"
                        :data-vv-as="$t('auth.passchng.f.pass.e')"
                        :placeholder="$t('auth.passchng.f.pass.p')"
                    >
                        <el-tooltip
                            v-if="passwordShow"
                            class="d-block"
                            effect="light"
                            placement="top-end"
                            slot="suffix"
                            :content="$t('auth.passHide')"
                        >
                            <i
                                class="icon ion-ios-eye-off mx-2 c-hand text-error"
                                style="font-size: 1.5em;"
                                @click="passwordShow = !passwordShow"
                            ></i>
                        </el-tooltip>
                        <el-tooltip
                            v-else
                            class="d-block"
                            effect="light"
                            placement="top-end"
                            slot="suffix"
                            :content="$t('auth.passShow')"
                        >
                            <i
                                class="icon ion-ios-eye mx-2 c-hand"
                                style="font-size: 1.5em;"
                                @click="passwordShow = !passwordShow"
                            ></i>
                        </el-tooltip>
                    </el-input>
                </el-form-item>
                <el-form-item
                    :label="$t('auth.passchng.f.repass.l')"
                    :error="errors.first('repassword')"
                >
                    <el-input
                        v-model="form.repassword"
                        name="repassword"
                        :type="passwordShow ? 'text' : 'password'"
                        v-validate="{ required: true, is: form.password }"
                        :data-vv-delay="1000"
                        :data-vv-as="$t('auth.passchng.f.repass.e')"
                        :placeholder="$t('auth.passchng.f.repass.p')"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <div class="columns">
                        <div class="column col-6 col-xs-12">
                            <el-button
                                native-type="submit"
                                type="primary" style="width: 100%;"
                                :disabled="errors.any()"
                                :loading="loading"
                            >{{ $t('auth.passchng.f.btn_ok') }}</el-button>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Api from '@/Auth/api';
export default {
    components: {
    },
    $_veeValidate: {
        validator: 'new'
    },
    data () {
        return {
            pageloading: false,
            wrongsec: false,
            form: {
                password: null,
                repassword: null
            },
            passwordShow: false,
            loading: false,
            success: false,
            errormsg: ''
        }
    },
    computed: {
        ...mapState({
        })
    },
    created () {
        Api.changePassword({
            id: this.$route.params.id,
            sec: this.$route.params.sec,
            password: null
        }).catch((e) => {
            if (e.response.status != 422) {
                this.wrongsec = true;
            }
            this.pageloading = false;
        });
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        change () {
            this.$validator.validate().then((result) => {
                if (result) {
                    this.loading = true;
                    Api.changePassword({
                        id: this.$route.params.id,
                        sec: this.$route.params.sec,
                        password: this.form.password
                    }).then((response) => {
                        this.success = true;
                        this.loading = false;
                    }).catch((e) => {
                        if (e.response.status != 422) {
                            this.wrongsec = true;
                        } else {
                            this.errormsg = this.$t('auth.AUTH_VALIDATION_ERRORS')
                        }
                        this.loading = false;
                    });
                }
            });
        },
        next () {
            document.location = process.env.APP_BASE_URL;
        }
    }
}
</script>

<style lang="scss">
</style>
