import axios from 'axios';

export default {
    account (account) {
        return axios.get('/accounts/'+account.id);
    },
    history (account, params) {
        return axios.get('/accounts/'+account.id+'/transactions', { params });
    },
    withdraw (data) {
        return axios.post('/credits/withdraw', data);
    },
    voucher (data) {
        return axios.post('/credits/voucher/redeem', data);
    }
}
