<template>
    <div>
        <blockquote
            v-for="c in comments"
            :key="c.id"
        >
            <p class="mb-1">{{ c.comment }}</p>
            <cite class="text-gray"><small>{{ $d(parseDate(c.complete_time), 'normal') }} - {{ c.name }}</small></cite>
        </blockquote>
        <br>
        <div v-if="!bottom" class="text-center">
            <div v-loading="loading"></div>
            <el-button v-if="!loading"  size="mini" icon="el-icon-more" circle @click="load"></el-button>
        </div>
    </div>
</template>

<script>
import parseDate from 'date-fns/parse';
import { mapState, mapMutations, mapActions } from 'vuex';
import Api from '@/Auth/api';
export default {
    components: {
    },
    props: {
        survey: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            loading: false
        }
    },
    computed: {
        ...mapState({
            comments: state => state.surveys.comments,
            bottom: state => state.surveys.commentsBottom
        })
    },
    created () {
        this.SURVEYS_COMMENTS_CLEAR();
        this.load();
    },
    methods: {
        ...mapMutations([
            'SURVEYS_COMMENTS_CLEAR'
        ]),
        ...mapActions([
            'SURVEYS_COMMENTS_LOAD'
        ]),
        parseDate,
        load () {
            if (!this.loading) {
                this.loading = true;
                this.SURVEYS_COMMENTS_LOAD(this.survey).then((response) => {
                    this.loading = false;
                });
            }
        }
    }
}
</script>

<style lang="scss">
</style>
