<template>
    <div>
        <label class="el-form-item__label" style="float: none;">
            {{ $t('surveys.editor.f.url.l') }}
            <el-popover
                width="300"
                trigger="hover"
            >
                <i slot="reference" class="el-icon-info text-secondary"></i>
                <div v-html="$t('surveys.editor.f.url.info')"></div>
            </el-popover>
        </label>
        <el-form-item
            v-for="u in variants"
            :key="u.id"
            :error="errors.first(scope+'.url-'+u.id)"
        >
            <el-input
                :name="'url-'+u.id"
                maxlength="255"
                :data-vv-scope="scope"
                v-validate="{ required: true, max: 255, url: true, regex: /^https/}"
                :data-vv-as="$t('surveys.editor.f.url.e')"
                :data-vv-delay="1000"
                :value="u.val"
                @input="updateUrl(u.id, $event.trim())"
                placeholder="https://..."
            >
                <div slot="append">
                    <el-tooltip
                        v-if="u.val"
                        effect="light"
                        placement="bottom-end"
                        :content="$t('surveys.editor.f.url.tip1')"
                    >
                        <el-button @click="preview(u.val)">
                            <i class="el-icon-view icon-2x"></i>
                        </el-button>
                    </el-tooltip>
                    <el-tooltip
                        v-if="u.id"
                        effect="light"
                        placement="bottom-end"
                        :content="$t('surveys.editor.f.url.tip3')"
                    >
                        <el-button@click="delUrl(u.id)">
                            <i class="el-icon-delete icon-2x"></i>
                        </el-button>
                    </el-tooltip>
                    <el-tooltip
                        v-else
                        effect="light"
                        placement="bottom-end"
                        :content="$t('surveys.editor.f.url.tip2')"
                    >
                        <el-button @click="addUrl">
                            <i class="el-icon-circle-plus icon-2x"></i>
                        </el-button>
                    </el-tooltip>
                </div>
            </el-input>
        </el-form-item>
        <el-form-item
            :label="$t('surveys.editor.f.title.l')"
            :error="errors.first(scope+'.name')"
        >
            <el-input
                name="name"
                maxlength="512"
                :data-vv-scope="scope"
                v-validate="'required|max:512'"
                :data-vv-as="$t('surveys.editor.f.title.e')"
                :data-vv-delay="1000"
                :value="name"
                @input="$emit('update:name', $event)"
                :placeholder="$t('surveys.editor.f.title.p')"
            >
            </el-input>
        </el-form-item>
        <el-form-item
            :label="$t('surveys.editor.f.desc.l')"
            :error="errors.first(scope+'.description')"
        >
            <el-input
                type="textarea"
                :autosize="{ minRows: 3 }"
                name="description"
                maxlength="1024"
                :data-vv-scope="scope"
                v-validate="'max:1024'"
                :data-vv-as="$t('surveys.editor.f.desc.e')"
                :data-vv-delay="1000"
                :value="description"
                @input="$emit('update:description', $event)"
                :placeholder="$t('surveys.editor.f.desc.p')"
            ></el-input>
        </el-form-item>
        <el-form-item
            :label="$t('surveys.editor.f.msg.l')"
            :error="errors.first(scope+'.complete_msg')"
        >
            <el-input
                type="textarea"
                :autosize="{ minRows: 3 }"
                name="complete_msg"
                maxlength="1024"
                :data-vv-scope="scope"
                v-validate="'max:1024'"
                :data-vv-as="$t('surveys.editor.f.msg.e')"
                :data-vv-delay="1000"
                :value="complete_msg"
                @input="$emit('update:complete_msg', $event)"
                :placeholder="$t('surveys.editor.f.msg.p')"
            ></el-input>
            <p v-if="id" class="m-0 mt-1 text-warning" style="line-height: 1.5em;"><i class="el-icon-warning"></i> {{ $t('surveys.editor.f.msg.info') }}</p>
        </el-form-item>
        <el-dialog
            :visible.sync="dialogShow"
            fullscreen
            append-to-body
        >
            <span
                class="text-ellipsis"
                slot="title"
            >{{ dialogLink }}</span>
            <span slot="footer" class="p-2 m-2">&nbsp;</span>
            <iframe
                style="border: 1px solid #ccc; width: 100%; height: 60vh;"
                :src="dialogLink"
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            ></iframe>
        </el-dialog>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
    components: {
    },
    inject: {
        $validator: '$validator'
    },
    props: {
        scope: {
            type: String,
            required: true
        },
        id: {
          type: Number
        },
        urls: {
            type: Array
        },
        name: {
            type: String
        },
        description: {
            type: String
        },
        complete_msg: {
            type: String
        }
    },
    data () {
        return {
            dialogShow: false,
            dialogLink: null
        }
    },
    computed: {
        ...mapState({
        }),
        variants () {
            var i = 0;
            return this.urls.map(x => Object.assign({}, {
                id: i++,
                val: x
            }));
        }
    },
    created () {
        if (!this.urls.length) {
            this.addUrl();
        }
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        addUrl () {
            var urls = [...this.urls];
            urls.push('');
            this.$emit('update:urls', urls);
        },
        delUrl (index) {
            if (this.urls.length) {
                var urls = [...this.urls];
                urls.splice(index, 1);
                this.$emit('update:urls', urls);
            }
        },
        updateUrl (index, value) {
            var urls = [...this.urls];
            urls.splice(index, 1, value);
            this.$emit('update:urls', urls);
        },
        preview (url) {
            this.dialogLink = url;
            this.dialogShow = true;
        }
    }
}
</script>

<style lang="scss">
</style>
