import ElementUI from 'element-ui/lib/locale/lang/en';
import VeeValidate from 'vee-validate/dist/locale/en';
import DateFns from 'date-fns/locale/en';
import App from '@/App/locale/en-US';
import Auth from '@/Auth/locale/en-US';
import Responds from '@/Responds/locale/en-US';
import Surveys from '@/Surveys/locale/en-US';
import Credits from '@/Credits/locale/en-US';

export let dateTimeFormats = {
    'en-US': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        normal: {
            year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    }
}

export let numberFormats = {
    'en-US': {
        currency: {
            style: 'currency', currency: 'USD'
        }
    }
}

export let veeValidate = {
    'en-US': VeeValidate
}

export let dateFns = {
    'en-US': DateFns
}

export let messages = {
    'en-US': {
        UNKNOWN_ERROR: 'Unknown error has occured',
        ...ElementUI,
        ...App,
        ...Auth,
        ...Responds,
        ...Surveys,
        ...Credits
    }
}
