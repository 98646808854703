<template>
    <div>
        <br>
        <el-card class="text-center">
            <br>
            <p>{{ $t('app.affiliate.txt.useit') }}:</p>
            <p class="text-primary">https://answeo.com/aff/{{ user.affiliate_code }}</p>
            <p><small>{{ $t('app.affiliate.txt.useinfo') }}</small></p>
            <br>
            <p>
                <a target="_blank" :href="mailto">
                    <el-button type="danger">{{ $t('app.affiliate.btn') }}</el-button>
                </a>
            </p>
        </el-card>
        <br>
        <div class="text-center">
            <small v-if="affiliateInfo.count">{{ $t('app.affiliate.txt.info.count')}} <b>{{ affiliateInfo.count }}</b><br></small>
            <small v-if="affiliateInfo.name">{{ $t('app.affiliate.txt.info.name')}} <b>{{ affiliateInfo.name }}</b><br></small>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
    components: {
    },
    data () {
        return {
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user,
            affiliateInfo: state => state.app.affiliateInfo
        }),
        mailto () {
            return 'mailto:?subject=' + this.$t('app.affiliate.subject') + '&body=' + encodeURIComponent(this.$t('app.affiliate.body', [this.user.affiliate_code, this.user.name]));
        }
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ])
    }
}
</script>

<style lang="scss">
</style>
