<template>
    <div>
        <h1 class="text-center text-primary">{{ $t('surveys.add') }}</h1>
        <br>
        <el-steps :active="step">
            <el-step :title="$t('surveys.editor.menu.url')" icon="el-icon-edit"></el-step>
            <el-step :title="$t('surveys.editor.menu.target')" icon="icon icon-3x ion-ios-contact"></el-step>
            <el-step :title="$t('surveys.editor.menu.out')" icon="el-icon-share"></el-step>
            <el-step :title="$t('surveys.editor.menu.price')" icon="el-icon-goods"></el-step>
        </el-steps>
        <br>
        <el-form @submit.native.prevent="submit">
            <page-editor-survey
                v-if="step === 0"
                :scope="steps[0]"
                v-bind.sync="form"
            />
            <page-editor-audience
                v-if="step === 1"
                :scope="steps[1]"
                v-bind.sync="form"
            />
            <page-editor-screen-out
                v-if="step === 2"
                :scope="steps[2]"
                v-bind.sync="form"
            />
            <page-editor-credits
                v-if="step === 3"
                :scope="steps[3]"
                v-bind.sync="form"
            />
            <br>
            <el-form-item>
                <div class="columns col-gapless">
                    <div class="column">
                        <el-button
                            v-show="step > 0"
                            @click="back"
                            icon="el-icon-arrow-left"
                        >{{ $t('surveys.editor.f.btn_back') }}</el-button>
                    </div>
                    <div class="column text-right text-nowrap">
                        <router-link to="/surveys">
                            <el-button>{{ $t('surveys.editor.f.btn_cancel') }}</el-button>
                        </router-link>
                        <el-button
                            v-show="step < 3"
                            type="primary"
                            :disabled="errors.any(formscope)"
                            @click="next"
                        >{{ $t('surveys.editor.f.btn_next') }} <i class="el-icon-arrow-right el-icon-right"></i></el-button>
                        <el-button
                            v-show="step === 3"
                            type="primary"
                            native-type="submit"
                            icon="el-icon-check"
                            :disabled="errors.any()"
                            :loading="loading"
                        >{{ $t('surveys.editor.f.btn_finish') }}</el-button>
                    </div>
                </div>
            </el-form-item>
        </el-form>
        <template v-if="user.profile_lang_first == 'pl'">
            <br>
            <h3 class="text-center text-primary"><strong>answeo</strong> <small>poleca <strong>ProfiTest.pl</strong></small></h3>
            <h5 class="text-center text-primary">profesjonalne narzędzie do tworzenia ankiet online</h5>
            <br>
            <el-card class="centered" style="max-width: 1000px;">
                <div class="px-1">
                    <a href="https://profitest.pl?utm_source=answeo&utm_campaign=add-survey&utm_content=banner-bottom" target="_blank">
                        <img class="img-responsive centered py-2" src="@/assets/banner-profitest-pl.png" />
                    </a>
                </div>
            </el-card>
        </template>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import PageEditorSurvey from '@/Surveys/components/PageEditorSurvey';
import PageEditorAudience from '@/Surveys/components/PageEditorAudience';
import PageEditorScreenOut from '@/Surveys/components/PageEditorScreenOut';
import PageEditorCredits from '@/Surveys/components/PageEditorCredits';
export default {
    components: {
        PageEditorSurvey,
        PageEditorAudience,
        PageEditorScreenOut,
        PageEditorCredits
    },
    $_veeValidate: {
        validator: 'new'
    },
    data () {
        return {
            form: {
                urls: [''],
                name: null,
                description: null,
                complete_msg: null,
                complete_max_time: 3600,
                complete_quota: 100,
                profile_sex: null,
                profile_age_min: 16,
                profile_age_max: 99,
                profile_lang: this.$store.state.app.user.profile_lang_first,
                profile_country_living: null,
                screen_out: [],
                profile_geoname_in: [],
                profile_geoname_out: [],
                related_in: [],
                related_out: [],
                price: 100,
                cost: 0,
                count_complete: 0,
                count_progress: 0
            },
            steps: ['survey', 'audience', 'screenout', 'credits'],
            step: 0,
            loading: false
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
        formscope () {
            return this.steps[ this.step ];
        }
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
            'SURVEYS_ADD'
        ]),
        next () {
            this.$validator.validate(this.formscope+'.*').then((result) => {
                if (result) {
                    this.step++;
                    this.scrollTop();
                }
            });
        },
        back () {
            this.step--;
            this.scrollTop();
        },
        submit () {
            this.$validator.validate().then((result) => {
                if (result) {
                    this.loading = true;
                    var data = Object.assign({}, this.form);
                    data.screen_out = JSON.stringify(data.screen_out);
                    this.SURVEYS_ADD(data).then((response) => {
                        this.loading = false;
                        this.$router.push('/surveys/'+response.data.id+'/publish');
                    });
                }
            });
        },
        scrollTop () {
            this.$nextTick(() => {
                document.getElementById('main-view').scrollTop = 0;
            });
        }
    }
}
</script>

<style lang="scss">
</style>
