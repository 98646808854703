<template>
    <div>
        <el-form label-position="left" @submit.native.prevent="submit">
            <el-form-item
                :label="$t('app.profile.f.sex.l')"
                :error="errors.first('sex')"
            >
                <el-radio-group
                    class="el-radio-group--block"
                    v-model="form.profile_sex"
                    required
                    name="sex"
                    v-validate="'required'"
                    :data-vv-as="$t('app.profile.f.sex.e')"
                >
                    <el-radio-button label="female">{{ $t('app.profile.f.sex.female') }}</el-radio-button>
                    <el-radio-button label="male">{{ $t('app.profile.f.sex.male') }}</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item
                :label="$t('app.profile.f.birth.l')"
                :error="errors.first('birth')"
            >
                <div class="el-input el-input-group">
                    <el-select
                        v-model="birth_year"
                        name="year"
                        class="el-input-group--append"
                        filterable
                        :placeholder="$t('app.profile.f.birth.y')"
                    >
                        <el-option
                            v-for="i in 84"
                            :key="i"
                            :label="current_year - 15 - i"
                            :value="current_year - 15 - i"
                        ></el-option>
                    </el-select>
                    <el-select
                        v-model="birth_month"
                        name="month"
                        class="el-input-group--append el-input-group--prepend"
                        filterable
                        :placeholder="$t('app.profile.f.birth.m')"
                    >
                        <el-option
                            v-for="i in 12"
                            :key="i"
                            :label="format(new Date(2000, i-1), 'MMMM')"
                            :value="i"
                        ></el-option>
                    </el-select>
                    <el-select
                        v-model="birth_day"
                        name="day"
                        class="el-input-group--prepend"
                        filterable
                        :placeholder="$t('app.profile.f.birth.d')"
                    >
                        <el-option
                            v-for="i in (birth_year && birth_month ? getDaysInMonth(new Date(birth_year, birth_month-1)) : 31)"
                            :key="i"
                            :label="i"
                            :value="i"
                        ></el-option>
                    </el-select>
                </div>
                <input
                    v-model="form.profile_birth_date"
                    v-validate="'required'"
                    name="birth"
                    :data-vv-as="$t('app.profile.f.birth.e')"
                    type="hidden"
                />
            </el-form-item>
            <!-- <el-form-item
                :label="$t('app.profile.f.live.l')"
                :error="errors.first('live')"
            >
                <el-select
                    v-model="form.profile_country_living"
                    name="live"
                    class="el-select--block"
                    filterable
                    placeholder="..."
                    v-validate="'required'"
                    :data-vv-as="$t('app.profile.f.live.e')"
                    @change="form.profile_city_living = null"
                >
                    <el-option
                        v-for="item in countries"
                        :key="item.code"
                        :label="item.nameEnglish + ' - ' + item.nameNative"
                        :value="item.code"
                    >
                        <span class="float-left mr-2 text-gray">{{ item.nameEnglish }}</span>
                        <span class="float-left">{{ item.nameNative }}</span>
                    </el-option>
                </el-select>
            </el-form-item> -->
            <el-form-item
              :label="$t('app.profile.f.live.l')"
              :error="errors.first('geoname_id_place')"
            >
              <div class="el-input">
                <el-autocomplete
                    v-if="!form.profile_geoname_id_place"
                    name="city"
                    class="el-select--block"
                    :placeholder="$t('app.profile.f.city.p')"
                    :debounce="500"
                    :trigger-on-focus="false"
                    :fetch-suggestions="loadCities"
                    v-model="cityname"
                    @select="setCity"
                    @blur="cityname = null"
                >
                  <i
                    class="el-icon-search el-input__icon"
                    slot="suffix"
                  ></i>
                  <div slot-scope="{ item }" class="my-2" style="line-height: normal;">
                    <geoname-place :place="item" />
                  </div>
                </el-autocomplete>
                <el-card v-else shadow="never" style="line-height: normal;">
                  <geoname-place :place="city" />
                  <el-button
                    class="mt-2"
                    size="mini"
                    @click="clearCity"
                  >{{ $t('app.profile.f.city.clear') }}</el-button>
                </el-card>
              </div>
              <input
                  v-model="form.profile_geoname_id_place"
                  v-validate="'required'"
                  name="geoname_id_place"
                  :data-vv-as="$t('app.profile.f.city.e')"
                  type="hidden"
              />
            </el-form-item>
            <el-form-item
                :label="$t('app.profile.f.first.l')"
                :error="errors.first('first')"
            >
                <el-select
                    v-model="form.profile_lang_first"
                    name="first"
                    class="el-select--block"
                    filterable
                    placeholder="..."
                    v-validate="'required'"
                    :data-vv-as="$t('app.profile.f.first.e')"
                >
                    <el-option
                        v-for="item in languages"
                        :key="item.code"
                        :label="item.nameEnglish + ' - ' + item.nameNative"
                        :value="item.code"
                    >
                        <span class="float-left mr-2 text-gray">{{ item.nameEnglish }}</span>
                        <span class="float-left">{{ item.nameNative }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                v-show="form.profile_lang_first"
                :label="$t('app.profile.f.second.l')"
                :error="errors.first('second')"
            >
                <el-select
                    v-model="form.profile_lang_second"
                    name="second"
                    class="el-select--block"
                    filterable clearable
                    placeholder="..."
                >
                    <el-option
                        v-for="item in languages"
                        :key="item.code"
                        :label="item.nameEnglish + ' - ' + item.nameNative"
                        :value="item.code"
                    >
                        <span class="float-left mr-2 text-gray">{{ item.nameEnglish }}</span>
                        <span class="float-left">{{ item.nameNative }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item
                v-show="form.profile_lang_second"
                :label="$t('app.profile.f.third.l')"
                :error="errors.first('third')"
            >
                <el-select
                    v-model="form.profile_lang_third"
                    name="third"
                    class="el-select--block"
                    filterable clearable
                    placeholder="..."
                >
                    <el-option
                        v-for="item in languages"
                        :key="item.code"
                        :label="item.nameEnglish + ' - ' + item.nameNative"
                        :value="item.code"
                    >
                        <span class="float-left mr-2 text-gray">{{ item.nameEnglish }}</span>
                        <span class="float-left">{{ item.nameNative }}</span>
                    </el-option>
                </el-select>
            </el-form-item> -->
            <el-form-item class="text-right">
                <el-button
                    native-type="submit"
                    type="primary"
                    :loading="loading"
                    :disabled="errors.any()"
                >{{ $t('app.profile.f.btn_ok') }}</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import Api from './../api';
import parseDate from 'date-fns/parse';
import countries from 'world-countries';
import languages from 'lang-list/data/languages.json';
import getDaysInMonth from 'date-fns/get_days_in_month';
import isValid from 'date-fns/is_valid';
import { format } from '@/locale/i18n';
import { mapState, mapMutations, mapActions } from 'vuex';
import GeonamePlace from '@/App/components/utils/GeonamePlace';
export default {
    components: {
      GeonamePlace
    },
    $_veeValidate: {
        validator: 'new'
    },
    data () {
        return {
            form: {
                profile_sex: null,
                profile_birth_date: null,
                profile_country_living: null,
                profile_city_living: null,
                profile_lang_first: null,
                profile_lang_second: null,
                profile_lang_third: null,
                profile_geoname_id_country: null,
                profile_geoname_id_state: null,
                profile_geoname_id_place: null
            },
            current_year: new Date().getFullYear(),
            birth_year: null,
            birth_month: null,
            birth_day: null,
            loading: false,
            cities: [],
            city: null,
            cityname: null
        }
    },
    watch: {
        birth_year (value) {
            this.setProfileBirthDate();
        },
        birth_month (value) {
            this.setProfileBirthDate();
        },
        birth_day (value) {
            this.setProfileBirthDate();
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
        // countries () {
        //     var list = [];
        //     for (var country of countries) {
        //         var nativeLangs = Object.keys(country.name.native);
        //         if (nativeLangs.length) {
        //             list.push({
        //                 code: country.cca2,
        //                 cioc: country.cioc,
        //                 nameEnglish: country.name.common,
        //                 nameNative: country.name.native[nativeLangs[0]].common
        //             })
        //         }
        //     }
        //     return list.sort((a, b) => a.nameEnglish.localeCompare(b.nameEnglish));
        // },
        languages () {
            var list = [];
            for (var code of Object.keys(languages)) {
                list.push({
                    code: code,
                    nameEnglish: languages[code].int,
                    nameNative: languages[code].native
                });
            }
            return list.sort((a, b) => a.nameEnglish.localeCompare(b.nameEnglish));
        }
    },
    async created () {
        this.form.profile_sex = this.user.profile_sex;
        this.form.profile_birth_date = this.user.profile_birth_date;
        this.form.profile_country_living = this.user.profile_country_living;
        this.form.profile_city_living = this.user.profile_city_living;
        this.form.profile_lang_first = this.user.profile_lang_first;
        this.form.profile_lang_second = this.user.profile_lang_second;
        this.form.profile_lang_third = this.user.profile_lang_third;
        this.form.profile_geoname_id_country = this.user.profile_geoname_id_country;
        this.form.profile_geoname_id_state = this.user.profile_geoname_id_state;
        this.form.profile_geoname_id_place = this.user.profile_geoname_id_place;
        if (this.form.profile_birth_date) {
            var birth_date = parseDate(this.form.profile_birth_date);
            this.birth_year = birth_date.getFullYear();
            this.birth_month = birth_date.getMonth()+1;
            this.birth_day = birth_date.getDate();
        }
        if (this.form.profile_geoname_id_place) {
          await Api.geonameGetCity(this.form.profile_geoname_id_place).then(response => this.city = response.data[0]);
        }
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
            'APP_USER_PROFILE_SAVE'
        ]),
        format,
        getDaysInMonth,
        setProfileBirthDate () {
            if (this.birth_year && this.birth_month && this.birth_day) {
                if (this.birth_day <= getDaysInMonth(new Date(this.birth_year, this.birth_month-1))) {
                    var date = new Date(this.birth_year, this.birth_month-1, this.birth_day);
                    if (isValid(date)) {
                        return this.form.profile_birth_date = format(date, 'YYYY-MM-DD');
                    }
                } else {
                    this.birth_day = null;
                }
            }
            return this.form.profile_birth_date = null;
        },
        loadCities (value, cb) {
          value = value.trim();
          if (value.length > 1) {
            Api.geonameSearchCities({ q: value, filter: 'cities' }).then((result) => {
              cb(result.data);
            }).catch((e) => {
              cb([]);
            });
          } else {
            cb([]);
          }
        },
        setCity(item) {
          this.city = item;
          this.form.profile_country_living = item.country;
          this.form.profile_geoname_id_country = item.country_geoname_id;
          this.form.profile_geoname_id_state = item.state_geoname_id;
          this.form.profile_geoname_id_place = item.place_geoname_id;
        },
        clearCity () {
          this.city = null;
          this.form.profile_country_living = null;
          this.form.profile_geoname_id_country = null;
          this.form.profile_geoname_id_state = null;
          this.form.profile_geoname_id_place = null;
        },
        submit () {
            this.$validator.validate().then((result) => {
                if (result) {
                    this.loading = true;
                    if (!this.form.profile_lang_second) {
                        this.form.profile_lang_third = null;
                    }
                    var notify = this.user.profile_id;
                    this.APP_USER_PROFILE_SAVE(this.form).then((response) => {
                        this.loading = false;
                        if (notify) this.$notify.success({
                            title: this.$t('app.profile.f.done'),
                            message: this.$t('app.profile.f.donemsg'),
                            showClose: true
                        });
                    });
                }
            });
        }
    }
}
</script>

<style lang="scss">
</style>
