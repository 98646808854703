<template>
    <div>
        <br>
        <!-- <el-card>
            <h1 class="text-center">Miejsce na reklamę</h1>
        </el-card>
        <br><br> -->
        <div class="columns text-center">
            <div class="column col-8 col-md-12 col-mx-auto">
                <div class="text-error">
                    <i class="el-icon-close circle-close-outline icon-4x"></i>
                    <h5>{{ $t('responds.start.out.reject.h') }}</h5>
                </div>
                <p>{{ $t('responds.start.out.reject.info') }}</p>
                <br>
            </div>
        </div>
        <div class="text-center">
            <el-button
                type="danger"
                @click="next"
            >
                {{ $t('responds.start.out.reject.btn') }}
                <i class="el-icon-arrow-right el-icon-right"></i>
            </el-button>
        </div>
        <br><br>
        <!-- <el-card>
            <h1 class="text-center">Miejsce na reklamę</h1>
        </el-card>
        <br> -->
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
export default {
    components: {
    },
    data () {
        return {
        }
    },
    computed: {
        ...mapState({
        }),
        ...mapGetters([
        ])
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        next () {
            document.location = process.env.APP_BASE_URL+'/';
        }
    }
}
</script>

<style lang="scss">
</style>
