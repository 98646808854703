import Vue from 'vue';

import Router from 'vue-router';
Vue.use(Router);

import app from '@/App/router';
import auth from '@/Auth/router';
import responds from '@/Responds/router';
import surveys from '@/Surveys/router';
import credits from '@/Credits/router';

export default new Router({
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    base: process.env.APP_BASE_URL,
    routes: [
        ...app,
        ...responds,
        ...surveys,
        ...credits,
        ...auth
    ]
})
