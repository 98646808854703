import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
    state: {
        user: {},
        affiliateInfo: {
            name: null,
            count: 0
        },
        homelist: [],
        homelistBottom: false
    },
    getters,
    mutations,
    actions
}
