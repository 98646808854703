import PageSignIn from '../components/PageSignIn';
import PageSignInEmailNewAccount from '../components/PageSignInEmailNewAccount';
import PageSignInEmailConfirm from '../components/PageSignInEmailConfirm';
import PageLostPasswordReset from '../components/PageLostPasswordReset';
import PageEmailChangeConfirm from '../components/PageEmailChangeConfirm';
import PageAccount from '../components/PageAccount';
import PageAccountName from '../components/PageAccountName';
import PageAccountEmail from '../components/PageAccountEmail';
import PageAccountPassword from '../components/PageAccountPassword';
import PageAccountLanguage from '../components/PageAccountLanguage';
import PageAccountTimezone from '../components/PageAccountTimezone';

export default [
    {
        path: '/auth/sign-in',
        name: '/auth/sign-in',
        component: PageSignIn
    }, {
        path: '/auth/sign-in-email-new-account',
        name: '/auth/sign-in-email-new-account',
        component: PageSignInEmailNewAccount
    }, {
        path: '/auth/sign-in-email-confirm/:id/:sec',
        name: '/auth/sign-in-email-confirm',
        component: PageSignInEmailConfirm
    }, {
        path: '/auth/lost-password-reset/:id/:sec',
        name: '/auth/lost-password-reset',
        component: PageLostPasswordReset
    }, {
        path: '/auth/email-change-confirm/:id/:sec',
        name: '/auth/email-change-confirm',
        component: PageEmailChangeConfirm
    }, {
        path: '/auth/account',
        component: PageAccount
    }, {
        path: '/auth/account/name',
        component: PageAccountName
    }, {
        path: '/auth/account/email',
        component: PageAccountEmail
    }, {
        path: '/auth/account/password',
        component: PageAccountPassword
    }, {
        path: '/auth/account/language',
        component: PageAccountLanguage
    }, {
        path: '/auth/account/timezone',
        component: PageAccountTimezone
    }
]
