<template>
    <div class="container grid-lg text-center">
        <h3 class="text-error"><i class="el-icon-warning"></i> {{ $t('credits.refill.err.h') }}</h3>
        <br>
        <p>{{ $t('credits.refill.err.info') }}</p>
        <br>
        <router-link to="/auth/sign-in">
            <el-button
                v-if="!user.id"
                type="primary"
            >Zaloguj się</el-button>
        </router-link>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Api from '../api';
export default {
    components: {
    },
    data () {
        return {
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        })
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ])
    }
}
</script>

<style lang="scss">
</style>
