<template>
    <div class="col-8 col-md-10 col-sm-12 col-mx-auto">
        <div v-if="null === this.user.company" class="text-center">
            <h4 class="text-primary">{{ $t('app.setup.account.type') }}</h4>
            <p class="text-gray">{{ $t('app.setup.account.txt') }}</p>
            <div class="columns">
                <div
                    v-show="loading !== 'company'"
                    class="column col-6 col-xs-12 col-mx-auto my-2"
                >
                    <el-card shadow="hover">
                        <h1>{{ $t('app.setup.account.private.h') }}</h1>
                        <p class="text-gray">
                            <span>{{ $t('app.setup.account.private.txt1') }}</span><br>
                            <span>{{ $t('app.setup.account.private.txt2') }}</span>
                        </p>
                        <el-button
                            type="primary"
                            :loading="loading == 'private'"
                            @click="companySetUp(false)"
                        >{{ $t('app.setup.account.private.btn') }}</el-button>
                    </el-card>
                </div>
                <div
                    v-show="loading !== 'private'"
                    class="column col-6 col-xs-12 col-mx-auto my-2"
                >
                    <el-card shadow="hover">
                        <h1>{{ $t('app.setup.account.business.h') }}</h1>
                        <p class="text-gray">
                            <span>{{ $t('app.setup.account.business.txt1') }}</span><br>
                            <span>{{ $t('app.setup.account.business.txt2') }}</span>
                        </p>
                        <el-button
                            type="primary"
                            :loading="loading == 'company'"
                            @click="companySetUp(true)"
                        >{{ $t('app.setup.account.business.btn') }}</el-button>
                    </el-card>
                </div>
            </div>
        </div>
        <div v-else-if="true === this.user.company">
            <h4 class="text-center text-primary">{{ $t('app.setup.business.h') }}</h4>
            <br>
            <p>{{ $t('app.setup.business.txt.invoice') }}</p>
            <invoice-settings />
        </div>
        <div v-else >
            <h4 class="text-center text-primary">{{ $t('app.setup.private.h') }}</h4>
            <br>
            <p class="mb-0">{{ $t('app.setup.private.txt.affiliate') }}</p>
            <affiliate-link />
            <br><br>
            <p>{{ $t('app.setup.private.txt.research') }}</p>
            <el-card shadow="always" class="py-0">
                <researcher-settings />
            </el-card>
            <br><br>
            <p>{{ $t('app.setup.private.txt.profile') }}</p>
            <el-card shadow="always" class="py-0">
                <respondent-profile />
            </el-card>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import RespondentProfile from '@/App/components/RespondentProfile';
import AffiliateLink from '@/App/components/AffiliateLink';
import ResearcherSettings from '@/App/components/ResearcherSettings';
import InvoiceSettings from '@/App/components/InvoiceSettings';
export default {
    components: {
        RespondentProfile,
        AffiliateLink,
        ResearcherSettings,
        InvoiceSettings
    },
    data () {
        return {
            loading: null
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        })
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
            'APP_SETUP_COMPANY'
        ]),
        companySetUp (value) {
            this.loading = value ? 'company' : 'private';
            this.APP_SETUP_COMPANY({ company: value }).then((response) => {
                this.loading = null;
            });
        }
    }
}
</script>

<style lang="scss">
</style>
