import axios from 'axios';

export default {
    size (params) {
        return axios.get('/population', { params });
    },
    add (survey) {
        return axios.post('/surveys', survey);
    },
    save (survey) {
        return axios.patch('/surveys/'+survey.id, survey);
    },
    activate(survey) {
        return axios.patch('/surveys/'+survey.id+'/activate');
    },
    stop (survey) {
        return axios.patch('/surveys/'+survey.id+'/stop');
    },
    resume (survey) {
        return axios.patch('/surveys/'+survey.id+'/resume');
    },
    archive (survey) {
        return axios.patch('/surveys/'+survey.id+'/archive');
    },
    delete (survey) {
        return axios.delete('/surveys/'+survey.id);
    },
    comments (survey, params) {
        return axios.get('/surveys/'+survey.uuid+'/comments', { params });
    },
    my (params) {
        return axios.get('/surveys/my', { params });
    },
    myarchive (params) {
        return axios.get('/surveys/archive', { params });
    },
    placeLoad(ids) {
        return axios.get('/geonames/'+ids.join(','));
    },
    placeSearch(params) {
        return axios.get('/geonames/search', { params });
    },
    relatedLoad(survey_id) {
        return axios.get('/surveys/'+survey_id+'/related');
    },
    relatedSearch(params) {
        return axios.get('/related-search', { params });
    },
    responses(survey_id, params) {
      return axios.get('/surveys/'+survey_id+'/responses', { params });
    },
    payments(survey_id, params) {
      return axios.get('/surveys/'+survey_id+'/payments', { params });
    },
    pay(survey_id, params) {
      return axios.patch('/surveys/'+survey_id+'/pay', params);
    }
}
