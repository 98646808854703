<template>
    <el-dialog
        :visible="visible"
        @update:visible="$emit($event)"
        :show-close="false"
        :before-close="(done) => { loading || done(); }"
    >
        <h3 slot="title" class="m-0">{{ $t('credits.withdraw.h') }}</h3>
        <el-alert
            v-show="errormsg"
            class="mb-2"
            type="info"
            show-icon
            :closable="false"
            :title="errormsg"
        ></el-alert>
        <el-form
            class="pt-2"
            @submit.native.prevent="submit"
        >
            <el-form-item
                :label="$t('credits.withdraw.f.email.l')"
                :error="errors.first('email')"
            >
                <el-input
                    :disabled="loading"
                    v-model="form.email"
                    name="email"
                    type="email"
                    v-validate="'required|email'"
                    :data-vv-delay="1000"
                    :data-vv-as="$t('credits.withdraw.f.email.e')"
                    :placeholder="$t('credits.withdraw.f.email.p')"
                ></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('credits.withdraw.f.pass.l')"
                :error="errors.first('password')"
            >
                <el-input
                    :disabled="loading"
                    v-model="form.password"
                    name="password"
                    :type="passwordShow ? 'text' : 'password'"
                    v-validate="'required'"
                    :data-vv-as="$t('credits.withdraw.f.pass.e')"
                    :placeholder="$t('credits.withdraw.f.pass.p')"
                >
                    <el-tooltip
                        v-if="passwordShow"
                        class="d-block"
                        effect="light"
                        placement="top-end"
                        slot="suffix"
                        :content="$t('auth.passHide')"
                    >
                        <i
                            class="icon ion-ios-eye-off mx-2 c-hand text-error"
                            style="font-size: 1.5em;"
                            @click="passwordShow = !passwordShow"
                        ></i>
                    </el-tooltip>
                    <el-tooltip
                        v-else
                        class="d-block"
                        effect="light"
                        placement="top-end"
                        slot="suffix"
                        :content="$t('auth.passShow')"
                    >
                        <i
                            class="icon ion-ios-eye mx-2 c-hand"
                            style="font-size: 1.5em;"
                            @click="passwordShow = !passwordShow"
                        ></i>
                    </el-tooltip>
                </el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button v-if="!loading" @click="$emit('update:visible', false)">{{ $t('credits.withdraw.f.btn_cancel') }}</el-button>
            <el-button type="primary" :loading="loading" @click="submit">{{ $t('credits.withdraw.f.btn_ok') }}</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Api from '../api';
export default {
    components: {
    },
    $_veeValidate: {
        validator: 'new'
    },
    props: {
        visible: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            form: {
                email: null,
                password: null
            },
            passwordShow: false,
            loading: false,
            errormsg: ''
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user,
        })
    },
    created () {
        this.form.email = this.user.email
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        submit () {
            this.$validator.validate().then((result) => {
                if (result) {
                    this.loading = true;
                    Api.withdraw(this.form).then((response) => {
                        this.loading = false;
                        this.$emit('update:visible', false);
                        this.$emit('done');
                    }).catch((e) => {
                        this.loading = false;
                        this.errormsg = e.response.data && e.response.data.code ? this.$t('credits.withdraw.'+e.response.data.code) : ''+e;
                    });
                }
            });
        }
    }
}
</script>

<style lang="scss">
</style>
