<template>
  <div>
    <!-- <div class="text-center">
      <el-button
        size="mini"
        type="primary"
        @click="addnew = true"
      >New</el-button>
    </div>
    <el-dialog title="Add a new voucher" :visible.sync="addnew">
      <el-form :model="form">
        OK
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addnew = false">Cancel</el-button>
        <el-button type="primary" @click="add">Add</el-button>
      </span>
    </el-dialog>
    <br> -->
    <div class="text-center">
        <transition
            mode="out-in"
            name="el-fade-in"
        >
            <div v-if="historyLoading == 'newer'" v-loading="historyLoading"></div>
            <el-button v-else size="mini" icon="el-icon-refresh" circle @click="historyLoadNewer"></el-button>
        </transition>
    </div>
    <br>
    <template v-if="history.length">
    <el-table
        :data="history"
        size="mini"
    >
      <el-table-column
          prop="campaign"
          align="left"
          label="Campaign"
      ></el-table-column>
      <el-table-column
          align="center"
          label="Valid Time"
          width="150"
      >
        <div slot-scope="scope" class="text-nowrap">{{ scope.row.valid_time.substr(0, 16) }}</div>
      </el-table-column>
      <el-table-column
          align="center"
          label="Prolong"
      >
        <div slot-scope="scope">{{ Math.floor(scope.row.prolong / 86400) }}</div>
      </el-table-column>
      <el-table-column
          align="right"
          label=""
          width="50"
      >
        <div slot-scope="scope">{{ scope.row.user_email_hash ? '...@' : '' }}</div>
      </el-table-column>
      <el-table-column
          align="left"
          label="Subject"
      >
        <div slot-scope="scope">{{ scope.row.user_email_domain }}</div>
      </el-table-column>
      <el-table-column
          align="center"
          label="Code"
      >
        <h6 slot-scope="scope">{{ scope.row.code }}</h6>
      </el-table-column>
      <el-table-column
          align="right"
          label="Amount"
      >
        <h6 slot-scope="scope">${{ (scope.row.amount/100.0).toFixed(2) }}</h6>
      </el-table-column>
      <el-table-column
          align="right"
          label="Left"
      >
        <h6 slot-scope="scope">{{ scope.row.valid_count }}</h6>
      </el-table-column>
      <el-table-column
          align="right"
          label="Used"
      >
        <h6 slot-scope="scope"><strong>{{ scope.row.valid_count_used }}</strong></h6>
      </el-table-column>
      <el-table-column
          align="right"
          label="Cost"
      >
        <h6 slot-scope="scope">${{ (scope.row.valid_count_used * scope.row.amount/100.0).toFixed(2) }}</h6>
      </el-table-column>
    </el-table>
    <br>
    <div v-if="!historyBottom" class="text-center">
        <div v-if="historyLoading == 'older'" v-loading="historyLoading"></div>
        <el-button v-else size="mini" icon="el-icon-more" circle @click="historyLoadOlder"></el-button>
    </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import Api from '../api';

export default {
    components: {
    },
    data () {
        return {
            history: [],
            historyBottom: false,
            historyLoading: null,
            // addnew: false,
            // form: {
            //   code: null,
            //   campaign: null,
            //   valid_count: 1,
            //   valid_time: new Date(),
            //   prolong: 1,
            //   amount: 1,
            //   email: null,
            //   taxid: null
            // }
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
    },
    created () {
        this.historyLoadNewer();
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        historyLoadNewer () {
          this.historyLoading = 'newer';
          this.history = [];
          this.historyLoadOlder();
        },
        historyLoadOlder () {
          this.historyLoading = this.historyLoading || 'older';
          if (this.history.length) {
            var last = this.history[this.history.length - 1];
            var params = {
                id: last.id
            };
          } else {
            var params = {
                id: 2147483647
            };
          }
          Api.vouchers(params).then((response) => {
            for (var item of response.data) {
              // item.data = JSON.parse(item.data);
              this.history.push(item);
            }
            if (response.data.length == 0) {
                this.historyBottom = true;
            }
            this.historyLoading = null;
          });
        },
        // add () {
        //   Api.vouchersAdd(this.form).then((response) => {
        //     response.data.data = JSON.parse(response.data.data);
        //     // ...
        //   }).finally(() => {
        //     this.addnew = false;
        //   });
        // }
    }
}
</script>
