import axios from 'axios';

export default {
    me () {
        return axios.get('/me');
    },
    delete () {
        return axios.delete('/auth/me');
    },
    signInEmail (data) {
        return axios.post('/auth/sign-in-email', data);
    },
    signInEmailConfirm (data) {
        return axios.patch('/auth/sign-in-email-confirm/'+data.id+'/'+data.sec);
    },
    sendActivationLink (data) {
        return axios.post('/auth/sign-in-email-resend', data);
    },
    sendPasswordRecoveryLink (data) {
        return axios.post('/auth/password-reset-request', data);
    },
    changePassword (data) {
        return axios.patch('/auth/password-reset/'+data.id+'/'+data.sec, data);
    },
    userSetProps (props) {
        return axios.patch('/auth/me', props);
    },
    userPasswordChange (data) {
        return axios.patch('/auth/password-change', data);
    },
    userEmailChange (data) {
        return axios.patch('/auth/email-change', data);
    },
    userEmailChangeConfirm (data) {
        return axios.patch('/auth/email-change-confirm/'+data.id+'/'+data.sec);
    },
    // userEmailChangeCancel () {
    //     return axios.patch('/auth/email-change-cancel');
    // }
}
