<template>
    <div>
        <br><br>
        <div v-loading.fullscreen.lock="loading"></div>
        <div v-show="!loading" class="col-6 col-lg-8 col-md-10 col-sm-11 col-xs-12 col-mx-auto">
            <h1 class="text-center text-primary">{{ $t('auth.emailok.h') }}</h1>
            <br>
            <el-alert
                v-show="errormsg"
                class="mb-2"
                type="info"
                show-icon
                :closable="false"
                :title="errormsg"
            ></el-alert>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Api from '@/Auth/api';
export default {
    components: {
    },
    data () {
        return {
            loading: true,
            errormsg: ''
        }
    },
    computed: {
        ...mapState({
        })
    },
    created () {
        Api.signInEmailConfirm({
            id: this.$route.params.id,
            sec: this.$route.params.sec
        }).then((response) => {
            // this.loading = false;
            document.location = process.env.APP_BASE_URL;
        }).catch((e) => {
            if (e.response.status == 404) {
                this.errormsg = this.$t('auth.AUTH_WRONG_SEC');
            } else {
                this.errormsg = e.response.data ? this.$t('auth.'+e.response.data.code) : ''+e;
            }
            this.loading = false;
        });
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ])
    }
}
</script>

<style lang="scss">
</style>
