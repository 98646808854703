<template>
    <div v-loading="loading">
        <br><br class="hide-sm">
        <div class="columns">
            <div class="column col-8 col-md-10 col-sm-12 col-mx-auto">
                <h5 class="text-gray text-center">{{ $t('responds.start.out.h') }}</h5>
            </div>
        </div>
        <br><br class="hide-sm">
        <el-card>
            <div
                slot="header"
                style="font-weight: 600; white-space: pre-line; font-size: 1.2em;"
            >{{ question.value }}</div>
            <div
                v-for="a in question.answers"
                :key="a.id"
                class="d-flex my-2 c-hand"
                @click="toggle(a.id)"
            >
                <i
                    v-if="answers.indexOf(a.id) < 0"
                    class="icon ion-ios-radio-button-off mr-2"
                    style="font-size: 1.4em;"
                ></i>
                <i
                    v-else
                    class="icon ion-ios-checkmark-circle mr-2"
                    style="font-size: 1.4em;"
                ></i>
                <span style="font-size: 1.3em;">{{ a.value }}</span>
            </div>
        </el-card>
        <br>
        <div class="text-center">
            <el-button
                type="primary"
                round
                :disabled="disabled"
                @click="next"
            >
                {{ $t('responds.start.out.btn') }}
                <i class="el-icon-arrow-right"></i>
            </el-button>
        </div>
        <br><br>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
export default {
    components: {
    },
    props: {
        question: {
            type: Object,
            required: true
        },
        answer: {
            type: Array,
            default () {
                return [];
            }
        }
    },
    data () {
        return {
            answers: [],
            loading: false
        }
    },
    computed: {
        ...mapState({
        }),
        ...mapGetters([
        ]),
        disabled () {
            if (this.question.type == 'single') {
                return this.answers.length == 0;
            } else {
                return this.question.min > this.answers.length || this.answers.length > this.question.max;
            }
        }
    },
    created () {
        this.answers.push(...this.answer);
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        toggle (id) {
            var index = this.answers.indexOf(id);
            if (index < 0) {
                if (this.question.type == 'single') {
                    this.answers.splice(0, this.answers.length);
                    this.answers.push(id);
                } else if (this.answers.length < this.question.max) {
                    this.answers.push(id);
                }
            } else {
                this.answers.splice(index, 1);
            }
            this.$emit('update', this.answers);
        },
        next () {
            this.loading = true;
            this.$emit('next');
        }
    }
}
</script>

<style lang="scss">
</style>
