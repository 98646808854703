<template>
    <div class="col-6 col-md-8 col-sm-12 col-mx-auto">
        <br>
        <el-form-item>
            <h5>{{ $t('surveys.editor.f.price.h') }}</h5>
        </el-form-item>
        <el-form-item :label="$t('surveys.editor.f.price.l') + ' (USD)'">
            <el-input-number
                class="el-select--block"
                :min="0.1"
                :step="0.1"
                :precision="2"
                :value="realprice"
                @input="$emit('update:price', Math.floor($event*100))"
            ></el-input-number>
        </el-form-item>
        <p v-if="percent" class="my-1"><small class="text-gray">{{ $t('surveys.editor.f.price.percent', [percent, finalprice]) }}</small></p>
        <p><small class="text-gray">{{ $t('surveys.editor.f.price.txt') }}</small></p>
        <el-form-item :label="$t('surveys.editor.f.quota.l', [count_complete + count_progress])">
            <el-input-number
                class="el-select--block"
                :min="Math.max(1, count_complete + count_progress)"
                :step="1"
                :value="complete_quota"
                @input="$emit('update:complete_quota', $event)"
            ></el-input-number>
        </el-form-item>
        <p class="my-1"><small class="text-gray">{{ $t('surveys.editor.f.quota.cost', [realcost]) }}</small></p>
        <p><small class="text-gray">{{ $t('surveys.editor.f.quota.total', [totalcost]) }}</small></p><br>
        <el-form-item>
            <h5>{{ $t('surveys.editor.f.maxtime.h') }}</h5>
        </el-form-item>
        <el-form-item :label="$t('surveys.editor.f.maxtime.l')">
            <el-input-number
                class="el-select--block"
                :min="1"
                :step="1"
                :value="hours"
                @input="$emit('update:complete_max_time', $event*3600)"
            ></el-input-number>
        </el-form-item>
        <p class="my-1"><small class="text-gray">{{ $t('surveys.editor.f.maxtime.txt') }}</small></p>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import config from '@/config';
export default {
    components: {
    },
    props: {
        price: {
            type: Number,
            required: true
        },
        cost: {
            type: Number,
            required: true
        },
        complete_max_time: {
            type: Number,
            required: true
        },
        complete_quota: {
            type: Number,
            required: true
        },
        count_complete: {
            type: Number,
            required: true
        },
        count_progress: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            percent: config.commissions.response
        }
    },
    computed: {
        ...mapState({
        }),
        realprice () {
            return this.price / 100;
        },
        realcost () {
            return this.$n(this.cost / 100, 'currency', 'en-US');
        },
        totalcost () {
            var cost = this.cost + Math.ceil(this.price * (100 +this.percent) / 100) * (this.complete_quota - this.count_complete - this.count_progress);
            return this.$n(cost / 100, 'currency', 'en-US');
        },
        finalprice () {
            return (this.realprice + Math.ceil(this.price * this.percent / 100) / 100).toFixed(2);
        },
        hours () {
            return this.complete_max_time / 3600;
        }
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ])
    }
}
</script>

<style lang="scss">
</style>
