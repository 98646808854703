<template>
    <div v-if="survey">
        <h1 class="text-center text-primary">{{ $t('surveys.payments.h') }}</h1>
        <h4 class="text-center">{{ survey.name }}</h4>
        <br>
        <br>
        <el-collapse accordion v-model="toggle" @change="toggleClear">
          <el-collapse-item :title="$t('surveys.payout.h')" name="form">
            <div class="col-6 col-lg-8 col-md-10 col-sm-12 col-mx-auto">
              <el-form @submit.native.prevent="pay">
                <el-form-item :label="$t('surveys.payout.uids.l')">
                    <el-input
                        type="textarea"
                        size="mini"
                        :autosize="{ minRows: 3, maxRows: 10 }"
                        :disabled="!!loading"
                        v-model="uids"
                        @input="checked = false"
                    ></el-input>
                </el-form-item>
                <p class="my-1"><small class="text-gray">{{ $t('surveys.payout.uids.text1') }}</small></p>
                <p class="my-1"><small class="text-gray">{{ $t('surveys.payout.uids.text2') }}</small></p>
                <br>
                <el-form-item>
                    <el-checkbox
                      border
                      class="el-select--block"
                      :label="$t('surveys.payout.first')"
                      v-model="first"
                      @change="checked = false"
                    ></el-checkbox>
                </el-form-item>
                <el-form-item class="text-right">
                  <el-button
                      v-if="!checked"
                      @click="clear"
                  >{{ $t('surveys.payout.btn_cancel') }}</el-button>
                  <el-button
                      :type="checked ? '' : 'primary'"
                      :disabled="!uids || loading && loading != 'check'"
                      :loading="loading == 'check'"
                      @click="check"
                  >{{ $t('surveys.payout.btn_check') }}</el-button>
                </el-form-item>

                <template v-if="checked">
                <el-form-item :label="$t('surveys.payout.price.l')">
                    <el-input-number
                        class="el-select--block"
                        :min="0.1"
                        :step="0.1"
                        :precision="2"
                        v-model.number="price"
                    ></el-input-number>
                </el-form-item>
                <p v-if="percent" class="my-1"><small class="text-gray">{{ $t('surveys.payout.price.text1', [percent]) }}</small></p>
                <p class="my-1"><small class="text-gray">{{ $t('surveys.payout.price.text2') }}</small></p>
                <br>
                <div class="text-right mb-2">
                  <div class="text-left flex-inline flex-column flext-items-stretch">
                    <div class="flex flex-justify-between">
                      <h6>{{ $t('surveys.payout.summary.count') }}:</h6>
                      <h6>{{ paymentCount }}</h6>
                    </div>
                    <template v-if="percent">
                      <div class="flex flex-justify-between">
                        <h6>{{ $t('surveys.payout.summary.value') }}:</h6>
                        <h6>{{ $n(paymentValue, 'currency', 'en-US') }}</h6>
                      </div>
                      <div class="flex flex-justify-between">
                        <h6>{{ $t('surveys.payout.summary.commission') }}:</h6>
                        <h6>{{ $n(paymentCommission, 'currency', 'en-US') }}</h6>
                      </div>
                    </template>
                    <div class="flex flex-justify-between flex-items-end">
                      <h4 class="mr-2 pr-2">{{ $t('surveys.payout.summary.total') }}:</h4>
                      <h4><strong>{{ $n(paymentTotal, 'currency', 'en-US') }}</strong></h4>
                    </div>
                  </div>
                </div>
                <el-form-item class="text-right">
                  <el-button @click="clear">{{ $t('surveys.payout.btn_cancel') }}</el-button>
                  <el-button
                      type="danger"
                      native-type="submit"
                      :disabled="loading && loading != 'payment'"
                      :loading="loading == 'payment'"
                  >{{ $t('surveys.payout.btn_pay') }}</el-button>
                </el-form-item>
                </template>
              </el-form>
            </div>
          </el-collapse-item>
        </el-collapse>
        <br>
        <br>
        <h5 class="text-gray text-center">{{ $t('surveys.payments.hist.h') }}</h5>
        <br>
        <div class="text-center">
            <transition
                mode="out-in"
                name="el-fade-in"
            >
                <div v-if="historyLoading == 'newer'" v-loading="historyLoading"></div>
                <el-button v-else size="mini" icon="el-icon-refresh" circle @click="historyLoadNewer"></el-button>
            </transition>
        </div>
        <br>
        <template v-if="history.length">
        <el-table
            :data="history"
            style="width: 100%"
            size="mini"
            :row-class-name="rowClassName"
        >
          <el-table-column
              prop="modify_time"
              :formatter="dateFormat"
              align="center"
              :label="$t('surveys.payments.hist.date')"
              width="115"
          ></el-table-column>
          <el-table-column
              align="right"
              class-name="text-nowrap"
              label=""
              width="30"
          >
              <template slot-scope="scope">
                <i v-if="scope.row.status == 'pending'" class="icon ion-ios-lock text-large"></i>
                <i v-else-if="scope.row.payout" class="icon ion-ios-star-outline text-large"></i>
              </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="[SID]"
              width="100"
          >
            <samp slot-scope="scope">{{ scope.row.id }}</samp>
          </el-table-column>
          <el-table-column
              align="center"
              label="[UID]"
              width="290"
          >
            <samp slot-scope="scope">{{ scope.row.uuid }}</samp>
          </el-table-column>
          <el-table-column />
          <el-table-column
              align="right"
              :label="$t('surveys.payments.hist.payment')"
              fixed="right"
              width="90"
          >
              <strong
                  slot-scope="scope"
                  class="text-nowrap"
              >{{ $n( (parseInt(scope.row.price) + parseInt(scope.row.commission)) / 100, 'currency', 'en-US') }}</strong>
          </el-table-column>
          <!-- <el-table-column
              align="right"
              :label="$t('surveys.payments.hist.price')"
              fixed="right"
              width="90"
          >
              <strong
                  slot-scope="scope"
                  class="text-nowrap"
              >{{ $n(parseInt(scope.row.price)/100, 'currency', 'en-US') }}</strong>
          </el-table-column>
          <el-table-column
              align="right"
              label="Answeo"
              fixed="right"
              width="90"
          >
              <span
                  slot-scope="scope"
                  class="text-nowrap"
              >{{ $n(parseInt(scope.row.commission)/100, 'currency', 'en-US') }}</span>
          </el-table-column> -->
        </el-table>
        <br>
        <div v-if="!historyBottom" class="text-center">
            <div v-if="historyLoading == 'older'" v-loading="historyLoading"></div>
            <el-button v-else size="mini" icon="el-icon-more" circle @click="historyLoadOlder"></el-button>
        </div>
        </template>
    </div>
</template>

<script>
import parseDate from 'date-fns/parse';
import differenceInYears from 'date-fns/difference_in_years';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import config from '@/config';
import Api from '../api';
export default {
    components: {
    },
    data () {
        return {
            percent: config.commissions.response,
            loading: null,
            toggle: '',
            uids: '',
            first: true,
            price: 1.0,
            checked: false,
            history: [],
            historyBottom: false,
            historyLoading: null
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
        ...mapGetters([
            'SURVEYS_FIND'
        ]),
        survey () {
            return this.SURVEYS_FIND(this.$route.params.id);
        },
        paymentCount () {
          return this.uids.split('\n').map(x => x.trim()).filter(x => !!x).length;
        },
        paymentValue () {
          return this.paymentCount * this.price;
        },
        paymentCommission () {
          return this.paymentCount * Math.ceil(this.price * this.percent) / 100;
        },
        paymentTotal () {
          return this.paymentValue + this.paymentCommission;
        }
    },
    created () {
        if (!this.survey) {
          this.$router.push('/surveys');
        } else {
          this.historyLoadNewer();
        }
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
          'CREDITS_ACCOUNT_REFRESH'
        ]),
        differenceInYears,
        clear () {
          this.uids = '';
          this.first = true;
          this.price = 1.0;
          this.checked = false;
          this.toggle = '';
        },
        toggleClear () {
          if (!this.toggle) {
            this.clear();
          }
        },
        check () {
          this.loading = 'check';
          Api.pay(this.survey.id, {
            uids: this.uids,
            first: this.first,
            price: this.price * 100,
            action: 'check'
          }).then((response) => {
            this.uids = response.data.join('\n');
            if (this.uids.length) {
              this.checked = true;
            }
          }).finally(() => {
            this.loading = null;
          });
        },
        pay () {
          this.$confirm(this.$t('surveys.payout.dialog.text'), this.$t('surveys.payout.dialog.h'), {
              confirmButtonText: this.$t('surveys.payout.btn_pay'),
              cancelButtonText: this.$t('surveys.payout.btn_cancel'),
              showClose: false
          }).then(() => {
            this.loading = 'payment';
            Api.pay(this.survey.id, {
              uids: this.uids,
              first: this.first,
              price: this.price * 100,
              action: 'pay'
            }).then((response) => {
              this.CREDITS_ACCOUNT_REFRESH();
              this.history = [];
              this.historyBottom = false;
              this.historyLoadNewer();
              if (response.data) {
                this.clear();
                this.history = [];
                this.historyBottom = false;
                this.historyLoadOlder();
                let resultCount = response.data.count;
                let resultCost = this.$n(response.data.cost/100, 'currency', 'en-US');
                this.$notify.success({
                  title: this.$t('surveys.payout.info.h', [resultCount]),
                  message: this.$t('surveys.payout.info.text', [resultCost])
                });
              } else {
                this.$notify.error({
                  title: this.$t('surveys.payout.fail.h'),
                  message: this.$t('surveys.payout.fail.text')
                });
              }
            }).finally(() => {
              this.loading = null;
            });
          }).catch(() => {});
        },
        historyLoadNewer () {
          this.historyLoading = 'newer';
          if (this.history.length) {
            var first = this.history[0];
            var params = {
                more: 'newer',
                modify_time: first.modify_time,
                id: first.id
            };
          } else {
            return this.historyLoadOlder();
          }
          Api.payments(this.survey.id, params).then((response) => {
            for (var item of response.data) {
                var index = this.history.findIndex(x => x.id === item.id);
                if (index >= 0) {
                    this.history.splice(index, 1);
                }
            }
            this.history.splice(0, 0, ...response.data);
            this.historyLoading = null;
          });
        },
        historyLoadOlder () {
          this.historyLoading = 'older';
          if (this.history.length) {
            var last = this.history[this.history.length - 1];
            var params = {
                more: 'older',
                modify_time: last.modify_time,
                id: last.id
            };
          } else {
            var params = {
                more: 'older',
                modify_time: new Date(),
                id: 2147483647
            };
          }
          Api.payments(this.survey.id, params).then((response) => {
            for (var item of response.data) {
                var index = this.history.findIndex(x => x.id === item.id);
                if (index >= 0) {
                    this.history.splice(index, 1);
                }
            }
            this.history.push(...response.data);
            if (response.data.length == 0) {
                this.historyBottom = true;
            }
            this.historyLoading = null;
          });
        },
        rowClassName ({row}) {
            return row.status !== 'complete' ? 'text-gray' : '';
        },
        dateFormat (row, column, cellValue, index) {
            return this.$d(parseDate(row.modify_time), 'short');
        }
    }
}
</script>

<style lang="scss">
</style>
