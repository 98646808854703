import Api from '@/Auth/api';
const AUTH_ACCOUNT_UPDATE = 'AUTH_ACCOUNT_UPDATE';
const AUTH_ACCOUNT_PASSWORD = 'AUTH_ACCOUNT_PASSWORD';
const AUTH_ACCOUNT_EMAIL = 'AUTH_ACCOUNT_EMAIL';
// const AUTH_ACCOUNT_EMAIL_CANCEL = 'AUTH_ACCOUNT_EMAIL_CANCEL';
const AUTH_ACCOUNT_DELETE = 'AUTH_ACCOUNT_DELETE';

export default {
    [AUTH_ACCOUNT_UPDATE] ({ commit }, payload) {
        return Api.userSetProps(payload).then((response) => {
            commit('APP_USER_SET', response.data);
        });
    },
    [AUTH_ACCOUNT_PASSWORD] ({ commit }, payload) {
        return Api.userPasswordChange(payload);
    },
    [AUTH_ACCOUNT_EMAIL] ({ commit }, payload) {
        return Api.userEmailChange(payload).then((response) => {
            commit('APP_USER_SET', response.data);
        });
    },
    // [AUTH_ACCOUNT_EMAIL_CANCEL] ({ commit }, payload) {
    //     return Api.userEmailChangeCancel().then((response) => {
    //         commit('APP_USER_SET', response.data);
    //     });
    // },
    [AUTH_ACCOUNT_DELETE] ({ commit, state, rootState }, payload) {
        return Api.delete().then((response) => {
            commit('APP_USER_SET', response.data);
        });
    }
}
