<template>
    <div>
        <p class="mt-2 mb-1">{{ $t('surveys.editor.f.out.l') }}</p>
        <p><span class="text-gray text-smaller">{{ $t('surveys.editor.f.out.txt') }}</span></p>
        <br>
        <el-card
            v-for="(q, i) in editor"
            :key="q.id"
            class="el-card--screen-out"
        >
            <div class="d-flex d-flex-space-between">
                <h5 class="text-center text-gray">{{ $t('surveys.editor.f.out.qh', [i+1]) }}</h5>
                <el-button
                    size="small"
                    type="danger"
                    @click="questionDel(q)"
                >
                    <i class="el-icon-delete"></i>
                    {{ $t('surveys.editor.f.out.btn_del') }}
                </el-button>
            </div>
            <el-form-item :label="$t('surveys.editor.f.out.type')">
                <el-radio-group
                    class="el-radio-group--block"
                    v-model="q.type"
                >
                    <el-radio-button label="single">
                        <i class="el-icon-circle-close-outline"></i>
                        <i class="el-icon-circle-check"></i>
                        <span class="hide-xs">{{ $t('surveys.editor.f.out.one.btn') }}</span>
                    </el-radio-button>
                    <el-radio-button label="multiyes">
                        <i class="el-icon-circle-check-outline"></i>
                        <i class="el-icon-circle-check"></i>
                        <span class="hide-xs">{{ $t('surveys.editor.f.out.myes.btn') }}</span>
                    </el-radio-button>
                    <el-radio-button label="multinot">
                        <i class="el-icon-circle-close-outline"></i>
                        <i class="el-icon-circle-close"></i>
                        <span class="hide-xs">{{ $t('surveys.editor.f.out.mnot.btn') }}</span>
                    </el-radio-button>
                </el-radio-group>
                <div class="mt-1" style="line-height: 1.5em;">
                    <span v-show="q.type == 'single'" class="text-gray">{{ $t('surveys.editor.f.out.one.txt') }}</span>
                    <span v-show="q.type == 'multiyes'" class="text-gray">{{ $t('surveys.editor.f.out.myes.txt') }}</span>
                    <span v-show="q.type == 'multinot'" class="text-gray">{{ $t('surveys.editor.f.out.mnot.txt') }}</span>
                </div>
            </el-form-item>
            <el-form-item>
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 4 }"
                    v-model="q.value"
                    :placeholder="$t('surveys.editor.f.out.q.p')"
                ></el-input>
            </el-form-item>
            <el-form-item >
                <el-input
                    v-for="(a, idx) in q.answers" :key="a.id"
                    class="mb-2"
                    v-model="a.value"
                    :placeholder="$t('surveys.editor.f.out.q.a.p',[idx+1])"
                >
                    <el-tooltip
                        slot="prepend"
                        effect="light"
                        placement="right"
                        :content="a.out ? $t('surveys.editor.f.out.q.a.no') : $t('surveys.editor.f.out.q.a.yes')"
                    >
                        <el-button
                            :icon="a.out ? 'el-icon-circle-close-outline' : 'el-icon-circle-check'"
                            @click="a.out = !a.out"
                        ></el-button>
                    </el-tooltip>
                    <i
                        v-show="q.answers.length > 2"
                        slot="suffix"
                        class="el-input__icon el-icon-delete c-hand"
                        @click="answerDel(q, a)"
                    ></i>
                </el-input>
                <div class="d-flex">
                    <el-tooltip
                        content="Add a new answer"
                        placement="bottom-start"
                        effect="light"
                    >
                        <el-button
                            icon="el-icon-plus"
                            @click="answerAdd(q)"
                        ></el-button>
                    </el-tooltip>
                    <div
                        v-if="q.type != 'single'"
                        class="ml-1"
                    >
                        <el-tooltip
                            :content="$t('surveys.editor.f.out.min')"
                            placement="bottom"
                            effect="light"
                        >
                            <el-input-number
                                v-model="q.min"
                                :min="1"
                                :max="Math.max(1, q.answers.length)"
                                class="el-input-number--screen-out"
                                @change="questionMinMaxAdjust(q)"
                            ></el-input-number>
                        </el-tooltip>
                        <el-tooltip
                            :content="$t('surveys.editor.f.out.max')"
                            placement="bottom"
                            effect="light"
                        >
                            <el-input-number
                                v-model="q.max"
                                :min="q.min"
                                :max="Math.max(q.min, q.answers.length)"
                                class="el-input-number--screen-out"
                            ></el-input-number>
                        </el-tooltip>
                    </div>
                </div>
            </el-form-item>
        </el-card>
        <el-button
            v-show="editor.length < 3"
            type="info"
            class="el-button--block"
            @click="questionAdd"
        >{{ $t('surveys.editor.f.out.btn_add') }}</el-button>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
    components: {
    },
    props: {
        screen_out: {
            type: Array,
            default: []
        }
    },
    data () {
        return {
            editor: null
        }
    },
    computed: {
        ...mapState({
        })
    },
    watch: {
        editor: {
            deep: true,
            handler (newval, oldval) {
                this.$emit('update:screen_out', JSON.parse(JSON.stringify(this.editor)));
            }
        }
    },
    created () {
        this.editor = JSON.parse(JSON.stringify(this.screen_out));
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        questionAdd () {
            this.editor.push({
                id: new Date().getTime(),
                type: 'single',
                value: '',
                min: 1,
                max: 2,
                answers: [{
                    id: new Date().getTime()+1,
                    value: '',
                    out: true
                }, {
                    id: new Date().getTime()+2,
                    value: '',
                    out: false
                }]
            });
        },
        questionDel (question) {
            this.editor.splice(this.editor.indexOf(question), 1);
        },
        questionMinMaxAdjust (question) {
            question.min = Math.max(1, Math.min(question.answers.length, question.min));
            question.max = Math.max(question.min, Math.min(question.answers.length, question.max));
        },
        answerAdd (question) {
            question.answers.push({
                id: new Date().getTime(),
                value: '',
                out: true
            });
        },
        answerDel (question, answer) {
            question.answers.splice(question.answers.indexOf(answer), 1);
            this.questionMinMaxAdjust(question);
        }
    }
}
</script>

<style lang="scss">
.el-card--screen-out {
    margin-bottom: 40px;
    .el-form-item:last-child {
        margin-bottom: 0;
    }
}
.el-input-number--screen-out {
    width: 130px;
}
</style>
