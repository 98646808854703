<template>
    <div>
        <el-form-item :label="$t('surveys.editor.f.sex.l')">
            <div class="hide-xs">
                <el-radio-group
                    class="el-radio-group--block"
                    :value="profile_sex"
                    @input="$emit('update:profile_sex', $event)"
                >
                    <el-radio-button :label="null">
                        <i class="icon ion-ios-woman"></i>+<i class="icon ion-ios-man"></i>
                        {{ $t('surveys.editor.f.sex.all') }}
                    </el-radio-button>
                    <el-radio-button label="female">
                        <i class="icon ion-ios-woman"></i>
                        {{ $t('surveys.editor.f.sex.female') }}
                    </el-radio-button>
                    <el-radio-button label="male">
                        <i class="icon ion-ios-man"></i>
                        {{ $t('surveys.editor.f.sex.male') }}
                    </el-radio-button>
                </el-radio-group>
            </div>
            <div class="show-xs">
                <el-select
                    class="el-select--block"
                    :value="profile_sex"
                    @input="$emit('update:profile_sex', $event)"
                >
                    <el-option
                        :label="$t('surveys.editor.f.sex.all')"
                        :value="null"
                    ></el-option>
                    <el-option
                        :label="$t('surveys.editor.f.sex.female')"
                        value="female"
                    ></el-option>
                    <el-option
                        :label="$t('surveys.editor.f.sex.male')"
                        value="male"
                    ></el-option>
                </el-select>
            </div>
        </el-form-item>
        <div class="columns">
            <div class="column">
                <el-form-item :label="$t('surveys.editor.f.age.min')">
                    <el-input-number
                        class="el-select--block"
                        :min="16"
                        :max="99"
                        :value="profile_age_min"
                        @input="ageMinChanged"
                    ></el-input-number>
                </el-form-item>
            </div>
            <div class="column">
                <el-form-item :label="$t('surveys.editor.f.age.max')">
                    <el-input-number
                        class="el-select--block"
                        :min="16"
                        :max="99"
                        :value="profile_age_max"
                        @input="ageMaxChanged"
                    ></el-input-number>
                </el-form-item>
            </div>
        </div>
        <el-form-item :label="$t('surveys.editor.f.lang.l')">
            <el-select
                class="el-select--block"
                filterable
                :value="profile_lang"
                @input="$emit('update:profile_lang', $event || null)"
            >
                <el-option
                    v-for="item in languages"
                    :key="item.code"
                    :label="item.nameEnglish + ' - ' + item.nameNative"
                    :value="item.code"
                >
                    <span class="float-left mr-2 text-gray">{{ item.nameEnglish }}</span>
                    <span class="float-left">{{ item.nameNative }}</span>
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item :label="$t('surveys.editor.f.place.l')">
          <el-autocomplete
              class="el-select--block"
              :placeholder="$t('surveys.editor.f.place.p')+'...'"
              v-model="placeQuery"
              :debounce="500"
              :trigger-on-focus="false"
              :fetch-suggestions="placeSearch"
              @select="placeSelected"
              @blur="placeQuery = null"
          >
            <i
              class="el-icon-search el-input__icon"
              slot="suffix"
            ></i>
            <div slot-scope="{ item }" class="my-2" style="line-height: normal;">
              <geoname-place :place="item" />
            </div>
          </el-autocomplete>
            <!-- <el-select
                class="el-select--block"
                filterable
                clearable
                :value="profile_country_living"
                @input="$emit('update:profile_country_living', $event || null)"
                :placeholder="$t('surveys.editor.f.place.p')+'...'"
            >
                <el-option
                    v-for="item in countries"
                    :key="item.code"
                    :label="item.nameEnglish + ' - ' + item.nameNative"
                    :value="item.code"
                >
                    <span class="float-left mr-2 text-gray">{{ item.nameEnglish }}</span>
                    <span class="float-left">{{ item.nameNative }}</span>
                </el-option>
            </el-select> -->
        </el-form-item>
        <div class="columns" style="align-items: stretch;">
            <div
                v-show="profile_geoname_out.length"
                class="column col-md-12 my-2"
            >
                <el-card class="column col-md-12 mb-2" shadow="never" style="height: 100%;">
                    <h5 class="text-center text-error pb-2"><i class="el-icon-remove"></i> {{ $t('surveys.editor.f.place.btn_out') }}</h5>
                    <div
                        v-for="(id, index) in profile_geoname_out"
                        :key="id"
                    >
                        <div v-if="index" class="divider text-center my-2" data-content="OR"></div>
                        <div class="d-flex d-flex-space-between d-flex-center">
                            <div>
                              <geoname-place v-if="placeUsed[id]" class="mb-0 mt-1 py-2" :place="placeUsed[id]" />
                              <h6 v-else class="mb-0 mt-1 py-2">{{ id }}</h6>
                            </div>
                            <div class="ml-2 hide-xs">
                                <el-button
                                    size="mini"
                                    type="default"
                                    @click="placeOutCancel(id)"
                                >{{ $t('surveys.editor.f.related.btn_cancel') }}</el-button>
                            </div>
                        </div>
                        <div class="pb-2 show-xs">
                            <el-button
                                size="mini"
                                type="default"
                                @click="placeOutCancel(id)"
                            >{{ $t('surveys.editor.f.related.btn_cancel') }}</el-button>
                        </div>
                    </div>
                </el-card>
            </div>
            <div
                v-show="profile_geoname_in.length"
                class="column col-md-12 my-2"
            >
                <el-card class="column col-md-12 mb-2" shadow="never" style="height: 100%;">
                    <h5 class="text-center text-primary pb-2"><i class="el-icon-circle-check"></i> {{ $t('surveys.editor.f.place.btn_in') }}</h5>
                    <div
                        v-for="(id, index) in profile_geoname_in"
                        :key="id"
                    >
                        <div v-if="index" class="divider text-center my-2" data-content="OR"></div>
                        <div class="d-flex d-flex-space-between d-flex-center">
                            <div>
                              <geoname-place v-if="placeUsed[id]" class="mb-0 mt-1 py-2" :place="placeUsed[id]" />
                              <h6 v-else class="mb-0 mt-1 py-2">{{ id }}</h6>
                            </div>
                            <div class="ml-2 hide-xs">
                                <el-button
                                    size="mini"
                                    type="default"
                                    @click="placeInCancel(id)"
                                >{{ $t('surveys.editor.f.related.btn_cancel') }}</el-button>
                            </div>
                        </div>
                        <div class="pb-2 show-xs">
                            <el-button
                                size="mini"
                                type="default"
                                @click="placeInCancel(id)"
                            >{{ $t('surveys.editor.f.related.btn_cancel') }}</el-button>
                        </div>
                    </div>
                </el-card>
            </div>
        </div>
        <el-form-item class="mb-2" :label="$t('surveys.editor.f.related.l')">
            <el-autocomplete
                class="el-select--block"
                :placeholder="$t('surveys.editor.f.related.p')"
                v-model="relatedQuery"
                :debounce="500"
                :trigger-on-focus="false"
                :fetch-suggestions="relatedSearch"
                @select="reletadSelected"
                @blur="relatedQuery = null"
            >
              <i
                class="el-icon-search el-input__icon"
                slot="suffix"
              ></i>
            </el-autocomplete>
        </el-form-item>
        <div class="columns" style="align-items: stretch;">
            <div
                v-show="related_out.length"
                class="column col-md-12 my-2"
            >
                <el-card class="column col-md-12 mb-2" shadow="never" style="height: 100%;">
                    <h5 class="text-center text-error pb-2"><i class="el-icon-remove"></i> {{ $t('surveys.editor.f.related.btn_out') }}</h5>
                    <div
                        v-for="(code, index) in related_out"
                        :key="code"
                    >
                        <div v-if="index" class="divider text-center my-2" data-content="OR"></div>
                        <div class="d-flex d-flex-space-between d-flex-center">
                            <h6 class="mb-0 mt-1 py-2">{{ relatedUsed[code] || code }}</h6>
                            <div class="ml-2 hide-xs">
                                <el-button
                                    size="mini"
                                    type="default"
                                    @click="relatedOutCancel(code)"
                                >{{ $t('surveys.editor.f.related.btn_cancel') }}</el-button>
                            </div>
                        </div>
                        <div class="pb-2 show-xs">
                            <el-button
                                size="mini"
                                type="default"
                                @click="relatedOutCancel(code)"
                            >{{ $t('surveys.editor.f.related.btn_cancel') }}</el-button>
                        </div>
                    </div>
                </el-card>
            </div>
            <div
                v-show="related_in.length"
                class="column col-md-12 my-2"
            >
                <el-card class="column col-md-12 mb-2" shadow="never" style="height: 100%;">
                    <h5 class="text-center text-primary pb-2"><i class="el-icon-circle-check"></i> {{ $t('surveys.editor.f.related.btn_in') }}</h5>
                    <div
                        v-for="(code, index) in related_in"
                        :key="code"
                    >
                        <div v-if="index" class="divider text-center my-2" data-content="OR"></div>
                        <div class="d-flex d-flex-space-between d-flex-center">
                            <h6 class="mb-0 mt-1 py-2">{{ relatedUsed[code] || code }}</h6>
                            <div class="ml-2 hide-xs">
                                <el-button
                                    size="mini"
                                    type="default"
                                    @click="relatedInCancel(code)"
                                >{{ $t('surveys.editor.f.related.btn_cancel') }}</el-button>
                            </div>
                        </div>
                        <div class="pb-2 show-xs">
                            <el-button
                                size="mini"
                                type="default"
                                @click="relatedInCancel(code)"
                            >{{ $t('surveys.editor.f.related.btn_cancel') }}</el-button>
                        </div>
                    </div>
                </el-card>
            </div>
        </div>
        <br>
        <el-form-item :label="$t('surveys.editor.f.size')">
            <div class="clearfix"></div>
            <el-card
                class="text-center"
                shadow="never"
                v-loading="loading"
            >
                <h2 class="m-0 text-primary"><i class="icon ion-ios-contacts mx-2 text-primary"></i><b v-show="!loading">{{ size }}</b></h2>
            </el-card>
        </el-form-item>
    </div>
</template>

<script>
import Api from './../api';
import debounce from 'lodash/debounce';
import countries from 'world-countries';
import languages from 'lang-list/data/languages.json';
import { mapState, mapMutations, mapActions } from 'vuex';
import GeonamePlace from '@/App/components/utils/GeonamePlace';
export default {
    components: {
      GeonamePlace
    },
    props: {
        id: {
            type: Number,
            default: null
        },
        profile_sex: {
            type: String
        },
        profile_age_min: {
            type: Number
        },
        profile_age_max: {
            type: Number
        },
        profile_lang: {
            type: String
        },
        profile_country_living: {
            type: String
        },
        profile_geoname_in: {
            type: Array
        },
        profile_geoname_out: {
            type: Array
        },
        related_in: {
            type: Array
        },
        related_out: {
            type: Array
        }
    },
    data () {
        return {
            loading: true,
            size: null,
            placeQuery: '',
            placeUsed: {},
            relatedQuery: '',
            relatedUsed: {}
        }
    },
    computed: {
        ...mapState({
        }),
        // countries () {
        //     var list = [];
        //     for (var country of countries) {
        //         var nativeLangs = Object.keys(country.name.native);
        //         if (nativeLangs.length) {
        //             list.push({
        //                 code: country.cca2,
        //                 cioc: country.cioc,
        //                 nameEnglish: country.name.common,
        //                 nameNative: country.name.native[nativeLangs[0]].common
        //             })
        //         }
        //     }
        //     return list.sort((a, b) => a.nameEnglish.localeCompare(b.nameEnglish));
        // },
        languages () {
            var list = [];
            for (var code of Object.keys(languages)) {
                list.push({
                    code: code,
                    nameEnglish: languages[code].int,
                    nameNative: languages[code].native
                });
            }
            return list.sort((a, b) => a.nameEnglish.localeCompare(b.nameEnglish));
        }
    },
    watch: {
        profile_sex () {
            this.getPopulationSize();
        },
        profile_age_min () {
            this.getPopulationSize();
        },
        profile_age_max () {
            this.getPopulationSize();
        },
        profile_lang () {
            this.getPopulationSize();
        },
        profile_country_living () {
            this.getPopulationSize();
        },
        profile_geoname_in () {
            this.getPopulationSize();
        },
        profile_geoname_out () {
            this.getPopulationSize();
        },
        related_in () {
            this.getPopulationSize();
        },
        related_out () {
            this.getPopulationSize();
        }
    },
    created () {
        if (this.id) {
            this.relatedLoad();
            this.placeLoad();
        }
        this.getPopulationSize();
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        ageMinChanged($event) {
            this.$emit('update:profile_age_min', $event);
            this.$emit('update:profile_age_max', Math.max(this.profile_age_max, $event));
        },
        ageMaxChanged($event) {
            this.$emit('update:profile_age_max', $event);
            this.$emit('update:profile_age_min', Math.min(this.profile_age_min, $event));
        },
        getPopulationSize () {
            this.loading = true;
            this.size = null;
            this.requestForPopulationSize(this);
        },
        async placeLoad () {
            let ids = [...this.profile_geoname_in, ...this.profile_geoname_out];
            if (ids.length) {
              await Api.placeLoad(ids).then((result) => {
                this.placeUsed = result.data.reduce((out, item) => {
                  out[item.id] = item;
                  return out;
                }, {});
              });
            }
        },
        placeSearch (q, cb) {
            Api.placeSearch({
                q: this.placeQuery
            }).then((result) => cb(result.data));
        },
        placeSelected (item) {
          this.placeQuery = '';
          if (item.place_geoname_id) {
            var placeName = item.place_name;
            if (item.place_name_english && item.place_name_english != item.place_name) {
              placeName += ' - '+item.place_name_english
            }
          } else if (item.state_geoname_id) {
            var placeName = item.state_name;
            if (item.state_name_english && item.state_name_english != item.state_name) {
              placeName += ' - '+item.state_name_english
            }
          } else {
            var placeName = item.country_name;
            if (item.country_name_english && item.country_name_english != item.country_name) {
              placeName += ' - '+item.country_name_english
            }
          }
          this.$confirm(placeName, this.$t('surveys.editor.f.place.msg'), {
              confirmButtonText: this.$t('surveys.editor.f.place.btn_in'),
              cancelButtonText: this.$t('surveys.editor.f.place.btn_out'),
              cancelButtonClass: 'el-button--danger',
              showClose: false,
              distinguishCancelAndClose: true,
              center: true
          }).then(() => {
              this.placeUsed[item.id] = item;
              var profile_geoname_in = [...this.profile_geoname_in];
              profile_geoname_in.push(item.id);
              this.$emit('update:profile_geoname_in', profile_geoname_in);
          }).catch((action) => {
              if (action === 'cancel') {
                this.placeUsed[item.id] = item;
                var profile_geoname_out = [...this.profile_geoname_out];
                profile_geoname_out.push(item.id);
                this.$emit('update:profile_geoname_out', profile_geoname_out);
              }
          });
        },
        placeInCancel (id) {
            delete this.placeUsed[id];
            var profile_geoname_in = [...this.profile_geoname_in];
            profile_geoname_in.splice(profile_geoname_in.findIndex(x => x === id), 1);
            this.$emit('update:profile_geoname_in', profile_geoname_in);
        },
        placeOutCancel (id) {
            delete this.placeUsed[id];
            var profile_geoname_out = [...this.profile_geoname_out];
            profile_geoname_out.splice(profile_geoname_out.findIndex(x => x === id), 1);
            this.$emit('update:profile_geoname_out', profile_geoname_out);
        },
        async relatedLoad () {
            await Api.relatedLoad(this.id).then((result) => {
                this.relatedUsed = result.data.reduce((out, item) => {
                    out[item.code] = item.value;
                    return out;
                }, {});
            });
        },
        relatedSearch (q, cb) {
            Api.relatedSearch({
                survey_id: this.id,
                q: this.relatedQuery
            }).then((result) => cb(result.data.filter(item => this.relatedUsed[item.code] === undefined)));
        },
        reletadSelected (item) {
            this.relatedQuery = '';
            this.$confirm(item.value, this.$t('surveys.editor.f.related.msg'), {
                confirmButtonText: this.$t('surveys.editor.f.related.btn_in'),
                cancelButtonText: this.$t('surveys.editor.f.related.btn_out'),
                cancelButtonClass: 'el-button--danger',
                showClose: false,
                distinguishCancelAndClose: true,
                center: true
            }).then(() => {
                this.relatedUsed[item.code] = item.value;
                var related_in = [...this.related_in];
                related_in.push(item.code);
                this.$emit('update:related_in', related_in);
            }).catch((action) => {
                if (action === 'cancel') {
                  this.relatedUsed[item.code] = item.value;
                  var related_out = [...this.related_out];
                  related_out.push(item.code);
                  this.$emit('update:related_out', related_out);
                }
            });
        },
        relatedInCancel (code) {
            delete this.relatedUsed[code];
            var related_in = [...this.related_in];
            related_in.splice(related_in.findIndex(x => x === code), 1);
            this.$emit('update:related_in', related_in);
        },
        relatedOutCancel (code) {
            delete this.relatedUsed[code];
            var related_out = [...this.related_out];
            related_out.splice(related_out.findIndex(x => x === code), 1);
            this.$emit('update:related_out', related_out);
        },
        requestForPopulationSize: debounce((vm) => {
            Api.size({
                profile_sex: vm.profile_sex,
                profile_age_min: vm.profile_age_min,
                profile_age_max: vm.profile_age_max,
                profile_lang: vm.profile_lang,
                profile_geoname_in: vm.profile_geoname_in,
                profile_geoname_out: vm.profile_geoname_out,
                related_in: vm.related_in,
                related_out: vm.related_out
            }).then((request) => {
                vm.loading = false;
                vm.size = request.data;
            });
        }, 3000)
    }
}
</script>

<style lang="scss">
</style>
