<template>
    <el-card shadow="always" class="surveys-homelist-item">
        <div class="d-flex d-flex-space-between">
            <div>
                <h5 class="mb-1"><strong>{{ survey.name }}</strong></h5>
                <div v-if="survey.description" class="hide-xs">
                    <p class="text-justify text-smaller my-0">{{ survey.description }}</p>
                    <small class="text-gray">
                        <i v-if="survey.user_company" class="icon ion-ios-business"></i>
                        <i v-else class="icon ion-ios-contact"></i>
                        {{ survey.user_name }}
                        ({{ survey.user_score }})
                    </small>
                </div>
                <small v-else class="text-gray">
                    <i v-if="survey.user_company" class="icon ion-ios-business"></i>
                    <i v-else class="icon ion-ios-contact"></i>
                    {{ survey.user_name }}
                    ({{ survey.user_score }})
                </small>
            </div>
            <div class="text-right" style="line-height: 1.33em; border-left: 1px solid #eee; padding-left: 15px; margin-left: 15px;">
                <strong class="text-error text-large">{{ price }}</strong>
                <br>
                <span><small class="text-nowrap text-gray pr-1"><i class="icon ion-ios-time"></i> {{ meanTime }}</small></span>
            </div>
        </div>
        <div v-if="survey.description" class="show-xs">
            <p class="text-justify text-smaller my-0">{{ survey.description }}</p>
            <small class="text-gray"><i class="icon ion-ios-contact"></i> {{ survey.user_name }} ({{ survey.user_score }})</small>
        </div>
        <div class="py-2"></div>
        <div class="d-flex d-flex-space-between" style="flex-wrap: wrap;">
            <div class="text-center col-xs-12">
                <router-link :to="'/respond/'+survey.uuid+'/'+ruuid" target="_blank" class="mr-2 my-2">
                    <el-button
                        :type="survey.expire_time ? 'danger' : 'primary'"
                        size="mini"
                        round
                    >
                        {{ $t('app.home.btn_go', [price]) }}
                        <span v-if="survey.mean_time_count">{{ $tc('app.home.btn_go_min', minutes, [minutes]) }}</span>
                        | {{ survey.expire_time ? $t('app.home.btn_continue') : $t('app.home.btn_go_action') }}
                        <i class="el-icon-arrow-right el-icon-right"></i>
                    </el-button>
                </router-link>
            </div>
            <div class="text-center col-xs-12 py-2">
                <small>
                    <div class="columns text-gray">
                        <div class="column text-nowrap">
                            <i class="icon ion-ios-checkmark-circle"></i> {{ survey.count_complete || '--' }}
                        </div>
                        <div class="column text-nowrap">
                            <i class="icon ion-ios-star"></i> {{ survey.rating_count ? (survey.rating_sum/survey.rating_count).toFixed(1) : '--' }}
                        </div>
                        <div class="column text-nowrap"
                            :class="survey.comments_count?'text-primary c-hand':''"
                            @click="commentsShow = !!survey.comments_count"
                        >
                            <i class="icon ion-ios-chatbubbles"></i> {{ survey.comments_count || '--' }}
                        </div>
                    </div>
                </small>
            </div>
        </div>
        <el-dialog
            :visible.sync="commentsShow"
            :title="$t('surveys.comments')"
        >
            <dialog-survey-comments
                v-if="commentsShow"
                :survey="survey"
            ></dialog-survey-comments>
        </el-dialog>
    </el-card>
</template>

<script>
import DialogSurveyComments from '@/Surveys/components/DialogSurveyComments';
export default {
    components: {
        DialogSurveyComments
    },
    props: {
        survey: {
            type: Object,
            required: true
        },
        ruuid: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            commentsShow: false
        }
    },
    computed: {
        price (survey) {
            return this.$n(this.survey.price/100, 'currency', 'en-US');
        },
        minutes (survey) {
            var minutes = '--';
            if (this.survey.mean_time_count) {
                var mean = Math.ceil(this.survey.mean_time_sum / this.survey.mean_time_count);
                minutes = Math.max(1, Math.floor(mean / 60));
            }
            return minutes;
        },
        meanTime (survey) {
            var seconds = '--';
            var minutes = '--';
            if (this.survey.mean_time_count) {
                var mean = Math.ceil(this.survey.mean_time_sum / this.survey.mean_time_count);
                seconds = mean % 60;
                seconds = seconds < 10 ? '0'+seconds : seconds;
                minutes = Math.floor(mean / 60);
            }
            return minutes + ':' + seconds;
        }
    }
}
</script>

<style lang="scss">
.el-card.surveys-homelist-item {
    margin-bottom: 40px;
    .el-card__body {
        padding-bottom: 10px;
    }
}
</style>
