import PageNotFound from '../components/PageNotFound';
import PageHome from '../components/PageHome';
import PageAdmin from '../components/PageAdmin';
import PageAdminWithdrawals from '../components/PageAdminWithdrawals';
import PageAdminRecharges from '../components/PageAdminRecharges';
import PageAdminVouchers from '../components/PageAdminVouchers';
import PageSettings from '../components/PageSettings';
import PageAffiliate from '../components/PageAffiliate';

export default [
    {
        path: '/',
        component: PageHome
    }, {
        path: '/admin',
        component: PageAdmin,
        children: [{
          path: 'withdrawals',
          component: PageAdminWithdrawals
        }, {
          path: 'recharges',
          component: PageAdminRecharges
        }, {
          path: 'vouchers',
          component: PageAdminVouchers
        }]
    }, {
        path: '/settings',
        component: PageSettings
    }, {
        path: '/affiliate',
        component: PageAffiliate
    }, {
        path: '*',
        component: PageNotFound
    }
]
