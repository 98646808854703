import axios from 'axios';

export default {
    init () {
        return axios.get('/init');
    },
    signOut () {
        return axios.post('/auth/sign-out');
    },
    setup (data) {
        return axios.patch('/setup', data);
    },
    invoiceSave (data) {
        return axios.patch('/invoice', data);
    },
    profileSave (data) {
        return axios.post('/profiles', data);
    },
    researcherSave (data) {
        return axios.patch('/researcher', data);
    },
    surveys (params) {
        return axios.get('/surveys', { params });
    },
    geonameGetCity(id) {
      return axios.get('/geonames/'+id);
    },
    geonameSearchCities (params) {
      return axios.get('/geonames/search', { params });
    },
    withdrawals (data) {
      return axios.get('/admin/withdrawals', { params: data });
    },
    withdrawalComplete (id) {
      return axios.patch('/admin/withdrawal/'+id+'/complete');
    },
    recharges (data) {
      return axios.get('/admin/recharges', { params: data });
    },
    rechargeAccount (data) {
      return axios.post('/admin/recharges', data);
    },
    vouchers (data) {
      return axios.get('/admin/vouchers', { params: data });
    },
    vouchersAdd (data) {
      return axios.post('/admin/vouchers', data);
    }
}
