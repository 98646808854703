<template>
    <div>
        <h1 class="text-center text-primary">{{ $t('auth.home.header') }}</h1>
        <br>
        <div class="columns">
            <div class="column col-6 col-md-12">
                <router-link
                    tag="div"
                    class="c-hand mb-2"
                    to="/auth/account/name"
                >
                    <el-card class="box-card mb-2" shadow="hover">
                        <small class="text-gray">{{ $t('auth.home.name') }}</small>
                        <i class="el-icon-edit float-right p-1 text-primary"></i>
                        <h5 class="mb-0 mt-1">{{ user.name }}</h5>
                    </el-card>
                </router-link>
                <router-link
                    tag="div"
                    class="c-hand mb-2"
                    to="/auth/account/email"
                >
                    <el-card class="box-card mb-2" shadow="hover">
                        <small class="text-gray">{{ $t('auth.home.email') }}</small>
                        <i class="el-icon-edit float-right p-1 text-primary"></i>
                        <h5 class="mb-0 mt-1">{{ user.email }}</h5>
                    </el-card>
                </router-link>
                <router-link
                    tag="div"
                    class="c-hand mb-2"
                    to="/auth/account/password"
                >
                    <el-card class="box-card mb-2" shadow="hover">
                        <small class="text-gray">{{ $t('auth.home.password') }}</small>
                        <i class="el-icon-edit float-right p-1 text-primary"></i>
                        <h5 class="mb-0 mt-1">* * * * *</h5>
                    </el-card>
                </router-link>
            </div>
            <div class="column col-6 col-md-12">
                <router-link
                    tag="div"
                    class="c-hand mb-2"
                    to="/auth/account/language"
                >
                    <el-card class="box-card mb-2" shadow="hover">
                        <small class="text-gray">{{ $t('auth.home.language') }}</small>
                        <i class="el-icon-edit float-right p-1 text-primary"></i>
                        <h5 class="mb-0 mt-1">{{ language.name }}</h5>
                    </el-card>
                </router-link>
                <router-link
                    tag="div"
                    class="c-hand mb-2"
                    to="/auth/account/timezone"
                >
                    <el-card class="box-card mb-2" shadow="hover">
                        <small class="text-gray">{{ $t('auth.home.timezone') }}</small>
                        <i class="el-icon-edit float-right p-1 text-primary"></i>
                        <h5 class="mb-0 mt-1">{{ timezone.name }}</h5>
                    </el-card>
                </router-link>
                <div
                    v-if="!user.delete_time"
                    class="mb-2"
                >
                    <el-card class="box-card mb-2" shadow="never">
                        <div class="text-center pt-1 pb-2">
                            <el-button @click="del">{{ $t('auth.home.btn_delete_account') }}</el-button>
                        </div>
                    </el-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import countriesAndTimezones from 'countries-and-timezones';
import { mapState, mapMutations, mapActions } from 'vuex';
import languages from '@/locale/languages';
export default {
    components: {
    },
    data () {
        return {
            languages: []
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
        timezones () {
            var list = [{
                code: 'UTC',
                name: 'UTC'
            }];
            var timezones = countriesAndTimezones.getAllTimezones();
            for (var code in timezones) {
                list.push({
                    code: code,
                    name: code + ' ('+timezones[code].offsetStr+')'
                });
            }
            return list.sort((a, b) => a.name.localeCompare(b.name));
        },
        timezone () {
            return this.timezones.find(x => x.code === this.user.timezone);
        },
        language () {
            return this.languages.find(x => x.code === this.user.locale);
        }
    },
    created () {
        this.languages = languages;
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
            'AUTH_ACCOUNT_DELETE'
        ]),
        del () {
            this.$confirm(this.$t('auth.home.del.txt'), this.$t('auth.home.del.h'), {
                confirmButtonText: this.$t('auth.home.del.btn_ok'),
                cancelButtonText: this.$t('auth.home.del.btn_cl'),
                type: 'warning',
                center: true
            }).then(() => {
                this.AUTH_ACCOUNT_DELETE();
            }).catch(() => {});
        }
    }
}
</script>

<style lang="scss">
</style>
