<template>
    <div>
        <h1 class="text-center text-primary">{{ $t('app.settings') }}</h1>
        <el-tabs
            :stretch="true"
            v-model="tab"
        >
        <template v-if="user.company">
            <el-tab-pane
                class="col-6 col-md-8 col-sm-12 col-mx-auto pt-2"
                :label="$t('app.invoice.h')"
                name="invoice"
            >
                <invoice-settings />
            </el-tab-pane>
        </template>
        <template v-else >
            <el-tab-pane
                class="col-6 col-md-8 col-sm-12 col-mx-auto pt-2"
                :label="$t('app.profile.h')"
                name="profile"
            >
                <respondent-profile />
            </el-tab-pane>
            <el-tab-pane
                class="col-6 col-md-8 col-sm-12 col-mx-auto pt-2"
                :label="$t('app.researcher.h')"
                name="researcher"
            >
                <researcher-settings class="pt-2"/>
            </el-tab-pane>
            <el-tab-pane
                class="col-6 col-md-8 col-sm-12 col-mx-auto pt-2"
                :label="$t('app.affiliate.h')"
                name="affiliate"
            >
                <affiliate-link />
            </el-tab-pane>
        </template>
        </el-tabs>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import RespondentProfile from '@/App/components/RespondentProfile';
import AffiliateLink from '@/App/components/AffiliateLink';
import ResearcherSettings from '@/App/components/ResearcherSettings';
import InvoiceSettings from '@/App/components/InvoiceSettings';
export default {
    components: {
        RespondentProfile,
        AffiliateLink,
        ResearcherSettings,
        InvoiceSettings
    },
    data () {
        return {
            tab: 'profile'
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        })
    },
    created () {
      if (this.user.company) {
        this.tab = 'invoice';
      }
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ])
    }
}
</script>

<style lang="scss">
</style>
