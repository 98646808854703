<template>
    <div id="app">
        <div v-loading.fullscreen.lock="initializing"></div>
        <div v-if="!initializing">
            <transition
                v-if="['/respond', '/confirm'].includes($route.name)"
                appear
                mode="out-in"
                name="el-fade-in"
            >
                <router-view />
            </transition>
            <main-theme
                v-else
                :user-after-setup="userAfterSetup"
            >
                <transition
                    appear
                    mode="out-in"
                    name="el-fade-in"
                >
                    <router-view v-if="!user.id" />
                    <div
                      v-else-if="profileUpdateRequired"
                      class="col-6 col-md-8 col-sm-12 col-mx-auto pt-2"
                    >
                      <h3 class="text-center">{{ $t('app.profile.update') }}</h3>
                      <respondent-profile />
                    </div>
                    <router-view v-else-if="userAfterSetup" />
                    <set-up v-else />
                </transition>
            </main-theme>
        </div>
    </div>
</template>

<script>
import { differenceInYears } from 'date-fns';
import { mapState, mapMutations, mapActions } from 'vuex';
import MainTheme from '@/App/components/MainTheme';
import SetUp from '@/App/components/SetUp';
import RespondentProfile from '@/App/components/RespondentProfile';
export default {
    components: {
        MainTheme,
        SetUp,
        RespondentProfile
    },
    props: {
        initializing: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
        userAfterSetup () {
            return this.user.profile_id || this.user.company_invoice;
        },
        profileUpdateRequired () {
          return this.user.profile_id &&
            ! (
              differenceInYears(new Date(), this.user.profile_update_time) < 1 &&
              this.user.profile_sex &&
              this.user.profile_birth_date &&
              this.user.profile_lang_first &&
              this.user.profile_country_living &&
              this.user.profile_geoname_id_country &&
              this.user.profile_geoname_id_state &&
              this.user.profile_geoname_id_place
            );
        }
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ])
    }
}
</script>
