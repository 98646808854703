<template>
    <div>
        <el-form label-position="left" @submit.native.prevent="submit">
            <el-form-item
                :label="$t('app.invoice.f.company.l')"
                :error="errors.first('company')"
            >
                <el-input
                    v-model="form.company"
                    name="company"
                    auto-complete="organization"
                    placeholder="..."
                    v-validate="'required'"
                    :data-vv-as="$t('app.invoice.f.company.e')"
                ></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('app.invoice.f.addressLine1.l')"
                :error="errors.first('addressLine1')"
            >
                <el-input
                    v-model="form.addressLine1"
                    name="addressLine1"
                    auto-complete="address-line1"
                    :placeholder="$t('app.invoice.f.addressLine1.p')"
                    v-validate="'required'"
                    :data-vv-as="$t('app.invoice.f.addressLine1.e')"
                ></el-input>
            </el-form-item>
            <el-form-item

            >
                <el-input
                    v-model="form.addressLine2"
                    name="addressLine2"
                    auto-complete="address-line2"
                    :placeholder="$t('app.invoice.f.addressLine2.p')"
                ></el-input>
            </el-form-item>
            <div class="columns">
                <div class="column col-4 col-xs-5">
                    <el-form-item
                        :label="$t('app.invoice.f.zipcode.l')"
                        :error="errors.first('zipcode')"
                    >
                        <el-input
                            v-model="form.zipcode"
                            name="zipcode"
                            auto-complete="postal-code"
                            placeholder="..."
                            v-validate="'required'"
                            :data-vv-as="$t('app.invoice.f.zipcode.e')"
                        ></el-input>
                    </el-form-item>
                </div>
                <div class="column col-8 col-xs-7">
                    <el-form-item
                        :label="$t('app.invoice.f.city.l')"
                        :error="errors.first('city')"
                    >
                        <el-input
                            v-model="form.city"
                            name="city"
                            auto-complete="locality"
                            placeholder="..."
                            v-validate="'required'"
                            :data-vv-as="$t('app.invoice.f.city.e')"
                        ></el-input>
                    </el-form-item>
                </div>
            </div>
            <el-form-item
                :label="$t('app.invoice.f.country.l')"
                :error="errors.first('country')"
            >
                <el-select
                    v-model="form.country"
                    name="country"
                    auto-complete="country"
                    class="el-select--block"
                    filterable
                    placeholder="..."
                    v-validate="'required'"
                    :data-vv-as="$t('app.invoice.f.country.e')"
                >
                    <el-option
                        v-for="item in countries"
                        :key="item.code"
                        :label="item.nameEnglish + ' - ' + item.nameNative"
                        :value="item.code"
                    >
                        <span class="float-left mr-2 text-gray">{{ item.nameEnglish }}</span>
                        <span class="float-left">{{ item.nameNative }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                :label="$t('app.invoice.f.taxid.l')"
                :error="errors.first('taxid')"
            >
                <el-input
                    v-model="form.taxid"
                    name="taxid"
                    placeholder="..."
                    v-validate="'required'"
                    :data-vv-as="$t('app.invoice.f.taxid.e')"
                ></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('app.invoice.f.more.l')"
            >
                <el-input
                    v-model="form.more"
                    name="more"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 5}"
                ></el-input>
            </el-form-item>
            <el-form-item class="text-right">
                <el-button
                    native-type="submit"
                    type="primary"
                    :loading="loading"
                    :disabled="errors.any()"
                >{{ $t('app.invoice.f.btn_ok') }}</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import countries from 'world-countries';
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
    components: {
    },
    $_veeValidate: {
        validator: 'new'
    },
    data () {
        return {
            form: {
                company: null,
                addressLine1: null,
                addressLine2: null,
                zipcode: null,
                city: null,
                country: null,
                taxid: null,
                more: null
            },
            loading: false
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
        countries () {
            var list = [];
            for (var country of countries) {
                var nativeLangs = Object.keys(country.name.native);
                if (nativeLangs.length) {
                    list.push({
                        code: country.cca2,
                        cioc: country.cioc,
                        nameEnglish: country.name.common,
                        nameNative: country.name.native[nativeLangs[0]].common
                    })
                }
            }
            return list.sort((a, b) => a.nameEnglish.localeCompare(b.nameEnglish));
        }
    },
    created () {
        if (this.user.company_invoice) {
            var invoice = JSON.parse(this.user.company_invoice);
            this.form.company = invoice.company;
            this.form.addressLine1 = invoice.addressLine1;
            this.form.addressLine2 = invoice.addressLine2;
            this.form.zipcode = invoice.zipcode;
            this.form.city = invoice.city;
            this.form.country = invoice.country;
            this.form.taxid = invoice.taxid;
            this.form.more = invoice.more;
        }
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
            'APP_INVOICE_SAVE'
        ]),
        submit () {
            this.$validator.validate().then((result) => {
                if (result) {
                    this.loading = true;
                    var notify = this.user.company_invoice;
                    this.APP_INVOICE_SAVE(this.form).then((response) => {
                        this.loading = false;
                        if (notify) this.$notify.success({
                            title: this.$t('app.invoice.f.done'),
                            message: this.$t('app.invoice.f.donemsg'),
                            showClose: true
                        });
                    });
                }
            });
        }
    }
}
</script>

<style lang="scss">
</style>
