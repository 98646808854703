<template>
    <div>
        <div class="d-flex d-flex-space-between">
            <div class="text-justify pr-2 mr-2">
                <span class="mb-1">{{ $t('app.researcher.opt') }}</span>
                <br>
                <small class="text-gray">{{ $t('app.researcher.txt') }}</small>
            </div>
            <el-switch :disabled="loading" :value="user.researcher" @change="save"></el-switch>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
    components: {
    },
    data () {
        return {
            loading: false
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        })
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
            'APP_USER_RESEARCHER_SAVE'
        ]),
        save ($event) {
            if (!this.loading) {
                this.loading = true;
                this.APP_USER_RESEARCHER_SAVE({ researcher: $event }).then((response) => {
                    this.loading = false;
                    this.$notify.success({
                        title: this.$t('app.researcher.done'),
                        message: this.$t('app.researcher.donemsg'),
                        showClose: true
                    });
                });
            }
        }
    }
}
</script>

<style lang="scss">
</style>
