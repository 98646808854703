<template>
    <div>
        <br class="hide-xs">
        <div class="el-alert--info is-light">
          <br>
          <div class="container grid-xs">
            <router-link
                class="c-hand"
                to="/affiliate"
                tag="div"
            >
              <el-alert
                :title="$t('app.affiliate.adhome.title')"
                type="info"
                show-icon
                :closable="false"
                :style="{ 'align-items': 'flex-start' }"
                >
                  <div class="mb-2"><span>{{ $t('app.affiliate.adhome.info') }}</span></div>
                  <el-button plain size="mini">{{ $t('app.affiliate.adhome.btn') }} ...</el-button>
              </el-alert>
            </router-link>
          </div>
          <br>
        </div>
        <br class="hide-xs">
        <br>
        <!-- <el-card>
            <h1 class="text-center">Miejsce na reklamę</h1>
        </el-card>
        <br> -->
        <h1 v-if="homelist.length" class="text-center text-primary">{{ $t('app.home.h_respond') }}</h1>
        <h1 v-else class="text-center text-primary">{{ $t('app.home.comeback') }}</h1>
        <div class="text-center">
            <transition
                mode="out-in"
                name="el-fade-in"
            >
                <div v-if="loading.newer" v-loading="loading.newer"></div>
                <el-button v-else size="mini" icon="el-icon-refresh" circle @click="loadNewer"></el-button>
            </transition>
        </div>
        <br>
        <template v-if="homelist.length">
            <page-search-list-item
                v-for="survey in homelist"
                :key="survey.id"
                :survey="survey"
                :ruuid="user.uuid"
            />
            <br>
            <div v-if="!bottom" class="text-center">
                <div v-if="loading.older" v-loading="loading.older"></div>
                <el-button v-else size="mini" icon="el-icon-more" circle @click="loadOlder"></el-button>
            </div>
        </template>
        <div v-else class="text-center">
            <small class="text-gray">{{ $t('app.home.nodata') }}</small>
            <br><br>
            <!-- <el-card>
                <h1 class="text-center">Miejsce na reklamę</h1>
            </el-card> -->
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import PageSearchListItem from '@/App/components/PageSearchListItem';
export default {
    components: {
        PageSearchListItem
    },
    data () {
        return {
            loading: {
                newer: false,
                older: false
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user,
            homelist: state => state.app.homelist,
            bottom: state => state.app.homelistBottom
        }),
        ...mapGetters([
            'APP_HOMELIST_CONTINUE',
            'APP_HOMELIST_RESPOND'
        ])
    },
    created () {
        this.loadNewer();
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
            'APP_HOMELIST_LOAD_NEWER',
            'APP_HOMELIST_LOAD_OLDER'
        ]),
        loadNewer () {
            this.loading.newer = true;
            this.APP_HOMELIST_LOAD_NEWER().then(() => {
                this.loading.newer = false;
            });
        },
        loadOlder () {
            this.loading.older = true;
            this.APP_HOMELIST_LOAD_OLDER().then(() => {
                this.loading.older = false;
            });
        }
    }
}
</script>

<style lang="scss">
</style>
