import PageHome from '../components/PageHome';
import PageAdd from '../components/PageAdd';
import PageArchive from '../components/PageArchive';
import PageEditor from '../components/PageEditor';
import PagePublish from '../components/PagePublish';
import PagePayments from '../components/PagePayments';
import PageResponses from '../components/PageResponses';

export default [
    {
        path: '/surveys',
        component: PageHome
    }, {
        path: '/surveys/add',
        component: PageAdd
    }, {
        path: '/surveys/archive',
        component: PageArchive
    }, {
        path: '/surveys/:id/edit',
        component: PageEditor
    }, {
        path: '/surveys/:id/publish',
        component: PagePublish
    }, {
        path: '/surveys/:id/payments',
        component: PagePayments
    }, {
        path: '/surveys/:id/responses/:status',
        component: PageResponses
    }
]
