import Vue from 'vue';

import Vuex from 'vuex';
Vue.use(Vuex);

import app from '@/App/store';
import auth from '@/Auth/store';
import responds from '@/Responds/store';
import surveys from '@/Surveys/store';
import credits from '@/Credits/store';

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        app,
        auth,
        responds,
        surveys,
        credits
    }
})
