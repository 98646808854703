<template>
    <page-surveys v-if="user.company" />
    <page-search v-else />
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import PageSearch from '@/App/components/PageSearch';
import PageSurveys from '@/Surveys/components/PageHome';
export default {
    components: {
        PageSearch,
        PageSurveys
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
    }
}
</script>
