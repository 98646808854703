<template>
    <div>
        <div v-loading.fullscreen.lock="initializing"></div>
        <!-- <br>
        <el-card>
            <h1 class="text-center">Miejsce na reklamę</h1>
        </el-card> -->
        <br><br>
        <div class="text-center">
            <h3 class="text-primary">{{ $t('responds.complete.h') }} <i class="el-icon-circle-check-outline"></i></h3>
            <span class="text-gray">{{ $t('responds.complete.amount') }}</span>
            <h1><strong class="text-large">{{ $n(response.survey_price/100, 'currency', 'en-US') }}</strong></h1>
        </div>
        <div class="columns text-center">
            <div class="column col-6 col-lg-8 col-md-10 col-sm-12 col-mx-auto">
                <template v-if="null === response.charity_percent">
                    <br><br class="hide-sm">
                    <h5>{{ $t('responds.complete.charity.h') }}</h5>
                    <p class="text-gray">{{ $t('responds.complete.charity.txt') }}</p>
                    <div v-loading="charity_loading">
                        <div class="px-2 pb-2">
                            <div class="d-flex d-flex-space-between ml-2">
                                <el-slider
                                    style="width: 100%"
                                    :format-tooltip="(x) => x+' %'"
                                    v-model="charity_percent"
                                ></el-slider>
                                <div
                                    class="text-primary text-right"
                                    style="width: 70px; padding-top: 6px;"
                                >
                                    {{ charity_percent }}%
                                </div>
                            </div>
                            <div style="padding-right: 85px;">
                                <div class="text-left" :style="{'margin-left': (charity_percent)+'%'}">
                                    <strong style="margin-left: -6px;">{{ $n(charityAmount, 'currency', 'en-US') }}</strong>
                                </div>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column col-6 col-xs-12 col-mx-auto hide-xs">
                                <el-button
                                    type="default"
                                    class="el-button--block my-2"
                                    @click="donate(0)"
                                >{{ $t('responds.complete.charity.btn_no') }}</el-button>
                            </div>
                            <div class="column col-6 col-xs-12 col-mx-auto">
                                <el-button
                                    type="primary"
                                    class="el-button--block my-2"
                                    icon="el-icon-success"
                                    :disabled="!charityAmount"
                                    @click="donate(charity_percent)"
                                >{{ $t('responds.complete.charity.btn_yes', [$n(charityAmount, 'currency', 'en-US')]) }}</el-button>
                            </div>
                            <div class="column col-6 col-xs-12 col-mx-auto show-xs">
                                <el-button
                                    type="default"
                                    class="el-button--block my-2"
                                    @click="donate(0)"
                                >{{ $t('responds.complete.charity.btn_no') }}</el-button>
                            </div>
                        </div>
                    </div>
                    <br>
                </template>
                <template v-else-if="response.charity_percent > 0">
                    <br><br class="hide-sm">
                    <small class="text-gray">{{ $t('responds.complete.charity.done') }}</small>
                    <h2 class="mt-1">{{ $n(charityAmount, 'currency', 'en-US') }}</h2>
                </template>
            </div>
        </div>
        <template v-if="response.survey_complete_msg">
        <br><br>
        <blockquote class="text-left">
            <p class="mb-2">{{ response.survey_complete_msg }}</p>
            <cite class="text-gray"><small>- {{ response.owner_name }}</small></cite>
        </blockquote>
        </template>
        <br><br>
        <el-card class="px-0">
            <div class="columns">
                <div class="column col-3 col-md-4 col-sm-12 text-center">
                    <div class="mb-2 pr-1">
                        <small class="text-gray">{{ $t('responds.complete.rate') }}</small>
                    </div>
                    <el-rate
                        class="response-rating"
                        v-model="rating"
                        @change="ratingChanged"
                    ></el-rate>
                    <template v-if="ratingsCount">
                    <br>
                    <div
                      v-for="(rating, index) in stats.ratings"
                      class="flex flex-items-center flex-justify-center">
                      <small class="mr-2"><strong class="text-success">{{ 5-index }}</strong></small>
                      <el-progress
                        :percentage="rating * 100 / ratingsCount"
                        :show-text="false"
                        status="success"
                        :style="{ width: '140px' }"
                        ></el-progress>
                    </div>
                    </template>
                    <br class="show-sm">
                </div>
                <div class="column col-9 col-md-8 col-sm-12">
                    <el-input
                        type="textarea"
                        class="response-comment"
                        :autosize="{ minRows: 1 }"
                        :placeholder="$t('responds.complete.comment.p')+'...'"
                        maxlength="1024"
                        v-model="comment"
                        @input="commentChanged"
                    ></el-input>
                    <p class="text-gray pl-2 ml-2" style="margin-bottom: -10px;">
                        <small v-if="true === commentSaving">{{ $t('responds.complete.comment.write') }} <i class="el-icon-edit"></i></small>
                        <small v-else-if="false === commentSaving">{{ $t('responds.complete.comment.save') }} <i class="el-icon-check"></i></small>
                        <small v-else >&nbsp;</small>
                    </p>
                    <template v-if="stats.comments.length">
                    <div class="p-2 hide-sm"></div>
                    <blockquote v-for="c in stats.comments">
                        <p class="mb-1">{{ c.comment }}</p>
                        <cite class="text-gray"><small>{{ $d(parseDate(c.complete_time), 'normal') }} - {{ c.name }}</small></cite>
                    </blockquote>
                    </template>
                </div>
            </div>
        </el-card>
        <br><br><br class="hide-sm">
        <div class="text-center">
            <el-button
                type="danger"
                @click="next"
            >
                {{ $t('responds.complete.btn_next') }}
                <i class="el-icon-arrow-right el-icon-right"></i>
            </el-button>
        </div>
        <br><br>
        <!-- <el-card>
            <h1 class="text-center">Miejsce na reklamę</h1>
        </el-card>
        <br> -->
    </div>
</template>

<script>
import parseDate from 'date-fns/parse';
import debounce from 'lodash/debounce';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import Api from '@/Responds/api';
export default {
    components: {
    },
    props: {
        response: {
            type: Object,
            required: true
        },
        suuid: {
            type: String,
            required: true
        },
        ruuid: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            initializing: true,
            stats: {
              ratings: [0,0,0,0,0],
              comments: []
            },
            charity_percent: 0,
            charity_loading: false,
            rating: null,
            comment: null,
            commentSaving: null
        }
    },
    computed: {
        ...mapState({
        }),
        ...mapGetters([
        ]),
        charityAmount () {
            return Math.round(this.response.survey_price * (this.charity_percent || 0) / 100) / 100;
        },
        ratingsCount () {
          return this.stats.ratings.reduce((prev, curr) => prev + curr, 0);
        }
    },
    created () {
        this.charity_percent = this.response.charity_percent || 0;
        this.rating = this.response.rating;
        this.comment = this.response.comment;
        this.initializing = false;
        Api.stats({ suuid: this.suuid, ruuid: this.ruuid }).then((response) => {
          if (response.data) {
            this.stats.ratings = JSON.parse(response.data.ratings).reverse();
            this.stats.comments = JSON.parse(response.data.comments);
          }
        });
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        parseDate,
        next () {
            document.location = process.env.APP_BASE_URL+'/';
        },
        donate (percent) {
            if (!this.charity_loading) {
                this.charity_loading = true;
                Api.charity({
                    suuid: this.suuid,
                    ruuid: this.ruuid,
                    percent: percent
                }).then((response) => {
                    this.$emit('update:charity_percent', response.data.charity_percent);
                    this.charity_loading = false;
                }).catch((error) => {
                    console.log(error);
                    this.charity_loading = false;
                });
            }
        },
        commentChanged () {
            this.commentSaving = true;
            this.commentSave(this);
        },
        commentSave: debounce((vm) => {
            Api.comment({
                suuid: vm.suuid,
                ruuid: vm.ruuid,
                comment: vm.comment || null
            }).then((response) => {
                vm.commentSaving = false;
            });
        }, 2000),
        ratingChanged () {
            this.ratingSave(this);
        },
        ratingSave: debounce((vm) => {
            Api.rating({
                suuid: vm.suuid,
                ruuid: vm.ruuid,
                rating: vm.rating
            });
        }, 1500)
    }
}
</script>

<style lang="scss">
.response-rating {
    .el-rate__item {
        padding: 0 5px;
        .el-rate__icon {
            margin: 0;
            &:before {
                font-size: 1.4em;
            }
        }
    }
}
.response-comment {
    .el-textarea__inner {
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0;
    }
}
</style>
