export default {
    app: {
        account: 'Account',
        signOut: 'Sign out',
        settings: 'Settings',
        menu: {
            surveys: 'Start',
            responses: 'My responses',
            add: 'Add survey',
            my: 'My surveys',
            credits: 'Balance'
        },
        setup: {
            account: {
                type: 'Choose account type',
                txt: 'This choice is irreversible',
                private: {
                    h: 'private',
                    txt1: 'make money on surveys',
                    txt2: 'carry out private research',
                    btn: 'Choose'
                },
                business: {
                    h: 'business',
                    txt1: 'carry out research',
                    txt2: 'receive invoices',
                    btn: 'Choose'
                }
            },
            private: {
                h: 'Private account',
                txt: {
                    affiliate: 'You can recommend answeo to your friends and make money together. Simply send them the link below. You can access this link at any time through your account settings.',
                    research: 'If you want to carry out your own research and buy responses, you can activate the researcher zone. You can either do this now or later, through your account settings.',
                    profile: 'Complete your profile to get started with answeo.'
                }
            },
            business: {
                h: 'Business account',
                txt: {
                    invoice: 'Fill in your invoice data. You can make changes at any time in your settings. Invoices are sent at the end of each month.'
                }
            }
        },
        home: {
            h_respond: 'Start responding to surveys',
            nodata: 'No surveys matching your profile',
            comeback: 'Check again later',
            btn_go: 'Earn {0}',
            btn_go_min: 'in ~{0} minute | in ~{0} minutes',
            btn_go_action: 'Respond',
            btn_continue: 'Continue'
        },
        profile: {
            h: 'Respondent profile',
            update: 'Confirm your profile is up to date',
            f: {
                sex: {
                    l: 'Sex',
                    e: 'Sex',
                    female: 'Female',
                    male: 'Male'
                },
                birth: {
                    l: 'Birth date',
                    e: 'Date',
                    y: 'Year',
                    m: 'Month',
                    d: 'Day'
                },
                live: {
                    l: 'Place of residence',
                    e: 'Place'
                },
                city: {
                    e: 'Place',
                    p: 'City, town or village',
                    clear: 'Change'
                },
                first: {
                    l: 'First language (primary/native)',
                    e: 'First language'
                },
                second: {
                    l: 'Second language (fluent)',
                    e: 'Second language'
                },
                third: {
                    l: 'Third language (fluent)',
                    e: 'Third language'
                },
                btn_ok: 'Save',
                done: 'Saved',
                donemsg: 'Your profile has been updated'
            }
        },
        affiliate: {
            h: 'Affiliation link',
            adhome: {
              title: 'Recommend answeo and earn money',
              info: 'For each survey completed by a person registered from your recommendation, we will pay you a commission on their payment!',
              btn: 'Learn more'
            },
            txt: {
                useit: 'Invite your friends to answeo using this link',
                useinfo: "If they register through it you will get a share of their earnings for each response they complete. It's as simple as that!",
                recommend: 'Recommend answeo and earn money',
                info: {
                    count: 'Number of invited users:',
                    name: 'Answeo was recommended to you by:'
                }
            },
            btn: 'Invite friends',
            subject: "Are you on Answeo?",
            body: "Hi, check out this awesome survey service:\n\nA N S W E O . c o m\n\nUse this link to register:\nhttps://answeo.com/aff/{0}\n\nHope to see you there,\n{1}"
        },
        researcher: {
            h: 'Researcher zone',
            opt: 'Activate researcher zone',
            txt: 'Once you activate this zone you will be able to publish your own surveys and collect responses. Respondents will be paid from your account balance.',
            done: 'Saved',
            donemsg: 'Researcher zone settings have been changed'
        },
        invoice: {
            h: 'Invoice settings',
            f: {
                company: {
                    l: 'Company name',
                    e: 'Name'
                },
                addressLine1: {
                    l: 'Address',
                    e: 'Street',
                    p: 'Street, building no, appartment'
                },
                addressLine2: {
                    p: 'second line (optional)'
                },
                zipcode: {
                    l: 'Postal code',
                    e: 'Code'
                },
                city: {
                    l: 'City',
                    e: 'City'
                },
                country: {
                    l: 'Country',
                    e: 'Country'
                },
                taxid: {
                    l: 'VAT ID',
                    e: 'VAT ID'
                },
                more: {
                    l: 'Additional info (optional)'
                },
                btn_ok: 'Save',
                done: 'Saved',
                donemsg: 'Invoice settings have been updated'
            }
        }
    }
}
