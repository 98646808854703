import axios from 'axios';

export default {
    respond (data) {
        return axios.post('/respond-start', data);
    },
    report (data) {
        return axios.post('/respond-report', data);
    },
    confirm (data) {
        return axios.patch('/respond-confirm', data);
    },
    charity (data) {
        return axios.patch('/respond-charity', data);
    },
    rating (data) {
        return axios.patch('/respond-rating', data);
    },
    comment (data) {
        return axios.patch('/respond-comment', data);
    },
    stats (data) {
      return axios.get('/respond-survey-stats', { params: data });
    },
    responses (data) {
      return axios.get('/responses/my', { params: data });
    }
}
