export default {
    surveys: {
        home: {
            h: 'My surveys',
            h_new: 'Newly added surveys',
            h_promo: 'Promoted surveys',
            h_active: 'Active surveys',
            h_stopped: 'Paused surveys',
            btn_new: 'New',
            btn_publish: 'Publish',
            btn_promote: 'Promote',
            btn_price: 'Price: ${0}',
            btn_age: 'Age: {0}-{1}',
            btn_stop: 'Pause',
            btn_resume: 'Resume',
            btn_delete: 'Delete',
            btn_delete_msg: 'The survey will be permanently deleted!',
            btn_delete_ok: 'Delete',
            btn_delete_cancel: 'Cancel',
            btn_archive: 'Archive',
            btn_full: 'Response limit reached',
            i_complete: 'Complete',
            i_out: 'Screen out',
            i_progress: 'Ongoing',
            i_time: 'Res. time',
            i_cost: 'Cost',
            nodata: 'No surveys',
            t_create: 'Created',
            t_modify: 'Modified',
            t_publish: 'Published',
            t_promote: 'Promoting',
            t_pause: 'Paused',
            t_archive: 'Archived'
        },
        archive: {
            h: 'Archived surveys',
            btn: 'Archive',
            nodata: 'No surveys'
        },
        responses: {
          h: 'Survey responses',
          status: {
            complete: 'Complete',
            progress: 'Ongoing',
            screenout: 'Screen out',
            all: 'All'
          },
          table: {
            date: 'Date',
            time: 'Resp. time',
            sex: 'Sex',
            age: 'Age',
            country: 'Country'
          }
        },
        payments: {
          h: 'Survey Payments',
          hist: {
            h: 'Payment history',
            date: 'Date',
            price: 'Price',
            payment: 'Payment'
          }
        },
        payout: {
          h: 'Payout of extra earnings',
          uids: {
            l: '[UID] or [SID] identifiers - one per line',
            text1: 'Up to 100 identifiers can be entered at a maximum.',
            text2: 'Duplicates, incorrect values, repeated values and corresponding ongoing replies will be omitted.'
          },
          first: 'Skip already paid with extra credits',
          price: {
            l: 'Extra credits per response',
            text1: 'Commission of {0}% will be added to the given value.',
            text2: 'If the account balance is insufficient, some payments will be made in the order of the identifiers entered.'
          },
          summary: {
            count: 'Payouts count',
            value: 'Payouts value',
            commission: 'Commission',
            total: 'Total payment'
          },
          dialog: {
            h: 'Confirm payouts',
            text: 'WARNING: You will not be able to undo your payouts.'
          },
          info: {
            h: 'Payouts made: {0}',
            text: 'Poniesiony koszt: {0}'
          },
          fail: {
            h: 'Lack of credits',
            text: 'No payout has been made'
          },
          btn_check: 'Validate',
          btn_pay: 'Pay',
          btn_cancel: 'Cancel'
        },
        add: 'Add a new survey',
        edit: 'Survey editor',
        comments: 'Comments',
        publish: {
            h: 'Publish your survey',
            warning: 'Important',
            info: 'You must place the <strong>End-Link</strong> on the last (thank you) page of your survey.<br>Only by using it can the respondents confirm that they completed the survey and collect their payment.',
            h_link: 'Survey End-Link',
            h_link_aff: 'Share your survey outside of answeo using this affiliation link',
            h_msg: 'You can copy this examaple message and paste it on the last (thank you) page of your survey:',
            msg: '<b>Thank you for taking the time to complete this survey.</b><br><br>To confirm your response in <a href="{0}" target="_blank"><span style="color: #5755d9;">ans</span><strong><span style="color: #e85600;">we</span></strong><span style="color: #5755d9;">o</span></a>, use the following link:<br><br><a :href="{1}" target="_blank" style="color: #5755d9;">{1}</a><br><br><b>Best regards</b>',
            btn_ok: 'I added the End-Link'
        },
        editor: {
            menu: {
                url: 'Survey',
                target: 'Audience',
                out: 'Screen out',
                price: 'Price & Limits'
            },
            f: {
                url: {
                    l: 'Survey link (SSL secured only i.e. https)',
                    e: 'Link',
                    tip1: 'Link preview',
                    tip2: 'Add a variant of the survey',
                    tip3: 'Remove this variant',
                    info: `
                        <h5>Link parametrization</h5>
                        <p>You can pass additional parameters to the survey system by adding the following variables to your link:</p>
                        <p class="mb-2">[UID] - Respondent ID, same for all responses</p>
                        <p>[SID] - Session ID, different for each response</p>
                        <p class="mb-0">Example:<br>https://survey.tool/path/[UID]?sess=[SID]</p>
                    `
                },
                title: {
                    l: 'Survey title',
                    e: 'Title',
                    p: 'Title'
                },
                desc: {
                    l: 'Survey short description',
                    e: 'Description',
                    p: '(optional)'
                },
                msg: {
                    l: 'Thank you message',
                    e: 'Message',
                    p: '(optional)',
                    info: 'Do not place the confirmation link here. You should place it in your survey creation tool: GoogleForms, SurveyMonkey, Qualtrics, etc.'
                },
                sex: {
                    l: 'Sex',
                    all: 'Female & Male',
                    male: 'Male',
                    female: 'Female'
                },
                age: {
                    min: 'Minimum age',
                    max: 'Maximum age'
                },
                lang: {
                    l: 'Survey language'
                },
                place: {
                    l: 'Filter respondents by place of living',
                    p: 'Country, region, city',
                    msg: 'Choose filter action',
                    btn_out: 'Reject place',
                    btn_in: 'Require place',
                    btn_cancel: 'Cancel'
                },
                related: {
                    l: 'Filter respondents by participation in other surveys',
                    p: 'Survey name or confirmation link...',
                    msg: 'Choose filter action',
                    btn_out: 'Reject participants',
                    btn_in: 'Require participation',
                    btn_cancel: 'Cancel'
                },
                size: 'Matching respondents',
                out: {
                    l: 'Screen out questions',
                    txt: 'You can ask up to 3 questions to qualify a specific group of people to your survey',
                    qh: 'Question #{0}',
                    btn_add: 'Add a question',
                    btn_del: 'Delete question',
                    type: 'Question type',
                    one: {
                        btn: 'Single',
                        txt: 'Single choice question - selecting qualifing choice accepts the respondent for the survey'
                    },
                    myes: {
                        btn: 'Multiple (qualifing)',
                        txt: 'Multiple choice question - selecting at least one qualifing choice accepts the respondent for the survey'
                    },
                    mnot: {
                        btn: 'Multiple (disqualifing)',
                        txt: 'Multiple choice question - selecting at least one disqualifing choice rejects the respondent from the survey'
                    },
                    min: 'Minimum selection number',
                    max: 'Maximum selection number',
                    q: {
                        p: 'Question',
                        a: {
                            p: 'Answer {0}',
                            yes: 'Qualify respondent for the survey',
                            no: 'Disqualify respondent (screen out)'
                        }
                    }
                },
                price: {
                    h: 'Set how much you want to pay per completed survey',
                    l: 'Price per completed survey',
                    percent: 'A {0}% commission will be charged on this price, so your final cost will be: ${1}.',
                    txt: 'When your account balance drops below the survey response cost, the survey will become unavailable for respondents.'
                },
                quota: {
                    l: 'Survey response limit (collected so far: {0})',
                    cost: 'Incurred costs: {0}',
                    total: 'Estimated total costs: {0}'
                },
                maxtime: {
                    h: 'Set maximum time for survey completion',
                    l: 'Time limit for survey completion (hours)',
                    txt: 'Money on your account is blocked for the duration of each started survey response'
                },
                btn_cancel: 'Cancel',
                btn_back: 'Back',
                btn_next: 'Next',
                btn_finish: 'Add',
                btn_save: 'Save',
                done: 'Saved',
                donemsg: 'Your survey has been updated'
            }
        }
    }
}
