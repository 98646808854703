import PageHome from '../components/PageHome';
import PageConfirm from '../components/PageConfirm';
import PageMy from '../components/PageMy';

export default [
    {
        path: '/respond/:suuid/:ruuid?',
        name: '/respond',
        component: PageHome
    }, {
        path: '/confirm/:code?',
        name: '/confirm',
        component: PageConfirm
    }, {
        path: '/responses/my',
        component: PageMy
    }
]
