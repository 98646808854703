<template>
    <div>
        <br><br>
        <div v-loading.fullscreen.lock="loading"></div>
        <div v-show="!loading" class="col-6 col-lg-8 col-md-10 col-sm-11 col-xs-12 col-mx-auto">
            <el-alert
                v-show="alert"
                class="mb-2"
                :type="alertType"
                show-icon
                :closable="false"
                :title="alert"
            ></el-alert>
            <br><br>
            <div
                v-if="done"
                class="text-center"
            >
                <el-button
                    type="primary"
                    @click="next"
                >{{ $t('auth.emailset.btn_done') }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Api from '@/Auth/api';
export default {
    components: {
    },
    data () {
        return {
            loading: false,
            done: false,
            alert: '',
            alertType: 'info'
        }
    },
    computed: {
        ...mapState({
        })
    },
    created () {
        Api.userEmailChangeConfirm({
            id: this.$route.params.id,
            sec: this.$route.params.sec
        }).then((response) => {
            this.done = true;
            this.alert = this.$t('auth.emailset.done');
            this.loading = false;
        }).catch((e) => {
            if (e.response.status == 404) {
                this.alert = this.$t('auth.AUTH_WRONG_SEC');
            } else {
                this.alert = e.response.data ? this.$t('auth.'+e.response.data.code) : ''+e;
            }
            this.loading = false;
        });
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        next () {
            document.location = process.env.APP_BASE_URL;
        }
    }
}
</script>

<style lang="scss">
</style>
