<template>
    <div>
        <h4>{{ $t('auth.signin.d.miss.h') }}</h4>
        <el-alert
            v-show="successmsg || errormsg"
            class="mb-2"
            type="info"
            show-icon
            :closable="false"
            :title="successmsg || errormsg"
        ></el-alert>
        <el-form
            v-show="!successmsg"
            class="pt-2"
            data-vv-scope="miss"
            @submit.native.prevent="send"
        >
            <el-form-item
                :error="errors.first('miss.email')"
            >
                <el-input
                    v-model="form.email"
                    name="email"
                    type="email"
                    v-validate="'required|email'"
                    :data-vv-delay="1000"
                    :data-vv-as="$t('auth.signin.d.miss.f.email.e')"
                    :placeholder="$t('auth.signin.d.miss.f.email.p')"
                ></el-input>
            </el-form-item>
            <el-form-item class="text-right">
                <el-button
                    native-type="submit"
                    type="primary"
                    :disabled="errors.any('miss')"
                    :loading="loading"
                >{{ $t('auth.signin.d.miss.f.btn_ok') }}</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Api from '@/Auth/api';
export default {
    components: {
    },
    $_veeValidate: {
        validator: 'new'
    },
    props: {
        email: {
            type: String,
            required: false
        }
    },
    data () {
        return {
            form: {
                email: null
            },
            loading: false,
            errormsg: '',
            successmsg: ''
        }
    },
    computed: {
        ...mapState({
        })
    },
    created () {
        this.form.email = this.email
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        send () {
            this.$validator.validate('miss.*').then((result) => {
                if (result) {
                    this.loading = true;
                    Api.sendActivationLink(this.form).then((response) => {
                        this.successmsg = this.$t('auth.signin.d.miss.msg_ok');
                        this.loading = false;
                    }).catch((e) => {
                        this.errormsg = e.response.data ? this.$t('auth.'+e.response.data.code) : ''+e;
                        this.loading = false;
                    });
                }
            });
        }
    }
}
</script>

<style lang="scss">
</style>
