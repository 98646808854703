<template>
    <div class="col-6 col-md-8 col-sm-10 col-xs-12 col-mx-auto">
        <h1 class="text-center text-primary">{{ $t('auth.pass.h') }}</h1>
        <el-alert
            v-show="message"
            class="mb-2"
            type="info"
            show-icon
            :closable="false"
            :title="message"
        ></el-alert>
        <div
            v-if="done"
            class="text-right"
        >
            <el-button
                type="primary"
                @click="cancel"
            >{{ $t('auth.pass.btn_done') }}</el-button>
        </div>
        <el-form
            v-else
            @submit.native.prevent="submit"
        >
            <el-form-item
                :label="$t('auth.pass.f.new.l')"
                :error="errors.first('newpassword')"
            >
                <el-input
                    v-model="form.newpassword"
                    name="newpassword"
                    :type="newpasswordShow ? 'text' : 'password'"
                    v-validate="'required'"
                    :data-vv-as="$t('auth.pass.f.new.e')"
                    :placeholder="$t('auth.pass.f.new.p')"
                >
                    <el-tooltip
                        v-if="newpasswordShow"
                        class="d-block"
                        effect="light"
                        placement="top-end"
                        slot="suffix"
                        :content="$t('auth.passHide')"
                    >
                        <i
                            class="icon ion-ios-eye-off mx-2 c-hand text-error"
                            style="font-size: 1.5em;"
                            @click="newpasswordShow = !newpasswordShow"
                        ></i>
                    </el-tooltip>
                    <el-tooltip
                        v-else
                        class="d-block"
                        effect="light"
                        placement="top-end"
                        slot="suffix"
                        :content="$t('auth.passShow')"
                    >
                        <i
                            class="icon ion-ios-eye mx-2 c-hand"
                            style="font-size: 1.5em;"
                            @click="newpasswordShow = !newpasswordShow"
                        ></i>
                    </el-tooltip>
                </el-input>
            </el-form-item>
            <el-form-item
                :error="errors.first('newpasswordRetype')"
            >
                <el-input
                    v-model="form.newpasswordRetype"
                    name="newpasswordRetype"
                    :type="newpasswordShow ? 'text' : 'password'"
                    v-validate="{ required: true, is: form.newpassword }"
                    :data-vv-delay="1000"
                    :data-vv-as="$t('auth.pass.f.re.e')"
                    :placeholder="$t('auth.pass.f.re.p')"
                ></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('auth.pass.f.pass.l')"
                :error="errors.first('password')"
            >
                <el-input
                    v-model="form.password"
                    name="password"
                    :type="passwordShow ? 'text' : 'password'"
                    v-validate="'required'"
                    :data-vv-as="$t('auth.pass.f.pass.e')"
                    :placeholder="$t('auth.pass.f.pass.p')"
                >
                    <el-tooltip
                        v-if="passwordShow"
                        class="d-block"
                        effect="light"
                        placement="top-end"
                        slot="suffix"
                        :content="$t('auth.passHide')"
                    >
                        <i
                            class="icon ion-ios-eye-off mx-2 c-hand text-error"
                            style="font-size: 1.5em;"
                            @click="passwordShow = !passwordShow"
                        ></i>
                    </el-tooltip>
                    <el-tooltip
                        v-else
                        class="d-block"
                        effect="light"
                        placement="top-end"
                        slot="suffix"
                        :content="$t('auth.passShow')"
                    >
                        <i
                            class="icon ion-ios-eye mx-2 c-hand"
                            style="font-size: 1.5em;"
                            @click="passwordShow = !passwordShow"
                        ></i>
                    </el-tooltip>
                </el-input>
            </el-form-item>
            <el-form-item class="text-right">
                <el-button @click="cancel">{{ $t('auth.pass.f.btn_cl') }}</el-button>
                <el-button
                    native-type="submit"
                    type="primary"
                    :loading="loading"
                    :disabled="errors.any()"
                >{{ $t('auth.pass.f.btn_ok') }}</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
    components: {
    },
    $_veeValidate: {
        validator: 'new'
    },
    data () {
        return {
            form: {
                password: null,
                newpassword: null,
                newpasswordRetype: null
            },
            passwordShow: false,
            newpasswordShow: false,
            loading: false,
            done: false,
            message: ''
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        })
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
            'AUTH_ACCOUNT_PASSWORD'
        ]),
        cancel () {
            this.$router.push('/auth/account/');
        },
        submit () {
            this.$validator.validate().then((result) => {
                if (result) {
                    this.loading = true;
                    this.AUTH_ACCOUNT_PASSWORD(this.form).then(() => {
                        this.done = true;
                        this.message = this.$t('auth.pass.done');
                        this.loading = false;
                    }).catch((e) => {
                        this.message = e.response.data ? this.$t('auth.'+e.response.data.code) : ''+e;
                        this.loading = false;
                    });
                }
            });
        }
    }
}
</script>

<style lang="scss">
</style>
