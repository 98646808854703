<template>
    <span>
        <el-tooltip
            v-if="reverse"
            :placement="tooltip"
            :content="distance"
        >
            <span>{{ $d(date, format) }}</span>
        </el-tooltip>
        <el-tooltip
            v-else
            :placement="tooltip"
            :content="$d(date, format)"
        >
            <span>{{ distance }}</span>
        </el-tooltip>
    </span>
</template>

<script>
import parseDate from 'date-fns/parse';
import { distanceInWords, distanceInWordsStrict } from '@/locale/i18n';
export default {
    props: {
        time: {
            required: true
        },
        strict: {
            default: false
        },
        options: {
            type: Object,
            default () {
                return {};
            }
        },
        tooltip: {
            type: String,
            default: 'top'
        },
        format: {
            type: String,
            default: 'long'
        },
        reverse: {
            default: false
        }
    },
    data () {
        return {
            distance: null,
            timeout: null
        }
    },
    computed: {
        date () {
            return parseDate(this.time);
        },
        locale () {
            return this.$i18n.locale;
        }
    },
    watch: {
        date () {
            this.update();
        },
        locale () {
            this.update();
        }
    },
    created () {
        this.update();
    },
    destroyed () {
        clearTimeout(this.timeout);
    },
    methods: {
        update () {
            if (this.strict) {
                this.distance = distanceInWordsStrict(new Date(), this.date, this.options);
            } else {
                this.distance = distanceInWords(new Date(), this.date, this.options);
            }
            var distanceInSeconds = (new Date().getTime() - this.date.getTime()) / 1000;
            var absDistanceInSeconds = Math.abs(distanceInSeconds);
            var delay = absDistanceInSeconds < 2 ? 300 : (absDistanceInSeconds < 2*60 ? 1000 : (absDistanceInSeconds < 2*3600 ? 60000 : (absDistanceInSeconds < 2*86400 ? 3600000 : 86400000)));
            this.timeout = setTimeout(() => {
                this.update();
            }, delay);
            this.$emit('update', distanceInSeconds);
        }
    }
}
</script>

<style lang="scss">
</style>
