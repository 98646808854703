import axios from 'axios';
import Vue from 'vue';
import { messages, dateTimeFormats, numberFormats, veeValidate, dateFns } from '@/locale/en-US';

const loadedLanguages = ['en-US'];
let i18nValidateLoadedLanguages = {
    'en-US': veeValidate
}
let i18nDateFnsLoadedLanguages = {
    'en-US': dateFns
}

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: 'en-US',
    fallbackLocale: 'en-US',
    messages,
    dateTimeFormats,
    numberFormats
});

import { Validator } from 'vee-validate';
Validator.localize(veeValidate.name, veeValidate);

import { format as _format, distanceInWords as _distanceInWords, distanceInWordsToNow as _distanceInWordsToNow, distanceInWordsStrict as _distanceInWordsStrict } from 'date-fns';
export function format (date, formatStr) {
    return _format(date, formatStr, { locale: i18nDateFnsLoadedLanguages[i18n.locale] });
}
export function distanceInWords (dateToCompare, date, options) {
    options = Object.assign({ locale: i18nDateFnsLoadedLanguages[i18n.locale] }, options || {});
    return _distanceInWords(dateToCompare, date, options);
}
export function distanceInWordsToNow (date, options) {
    options = Object.assign({ locale: i18nDateFnsLoadedLanguages[i18n.locale] }, options || {});
    return _distanceInWordsToNow(date, options);
}
export function distanceInWordsStrict (dateToCompare, date, options) {
    options = Object.assign({ locale: i18nDateFnsLoadedLanguages[i18n.locale] }, options || {});
    return _distanceInWordsStrict(dateToCompare, date, options);
}

export function i18nSetLanguage (lang) {
    if (i18n.locale !== lang) {
        if (!loadedLanguages.includes(lang)) {
            return import(/* webpackChunkName: "locale-[request]" */ `@/locale/${lang}`).then(response => {
                i18n.setLocaleMessage(lang, response.messages[lang]);
                i18n.setDateTimeFormat(lang, response.dateTimeFormats[lang]);
                i18n.setNumberFormat(lang, response.numberFormats[lang]);
                i18nValidateLoadedLanguages[lang] = response.veeValidate[lang];
                i18nDateFnsLoadedLanguages[lang] = response.dateFns[lang];
                loadedLanguages.push(lang);
                return setLanguage(lang);
            })
        }
        return Promise.resolve(setLanguage(lang));
    }
    return Promise.resolve(lang);
}

function setLanguage (lang) {
    i18n.locale = lang;
    Validator.localize(i18nValidateLoadedLanguages[lang].name, i18nValidateLoadedLanguages[lang]);
    document.querySelector('html').setAttribute('lang', lang);
    i18n.vm.$cookies.set('locale', lang, -1, process.env.COOKIE_PATH, process.env.COOKIE_DOMAIN);
    i18n.vm.$forceUpdate();
    // console.log(lang + ' vs. ' + i18n.vm.$cookies.get('locale'))
    return lang;
}
