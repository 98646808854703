<template>
    <div v-if="survey">
        <h1 class="text-center text-primary">{{ $t('surveys.edit') }}</h1>
        <h4>{{ survey.name }}</h4>
        <br>
        <el-form @submit.native.prevent="submit">
            <el-tabs
                :stretch="true"
                v-model="tab"
            >
                <el-tab-pane name="survey">
                    <span slot="label"><i class="el-icon-edit"></i> {{ $t('surveys.editor.menu.url') }}</span>
                    <page-editor-survey
                        scope="survey"
                        v-bind.sync="form"
                    />
                </el-tab-pane>
                <el-tab-pane name="audience">
                    <span slot="label"><i class="icon ion-ios-contact"></i> {{ $t('surveys.editor.menu.target') }}</span>
                    <page-editor-audience v-bind.sync="form" />
                </el-tab-pane>
                <el-tab-pane name="screenout">
                    <span slot="label"><i class="el-icon-share"></i> {{ $t('surveys.editor.menu.out') }}</span>
                    <page-editor-screen-out v-bind.sync="form" />
                </el-tab-pane>
                <el-tab-pane name="credits">
                    <span slot="label"><i class="el-icon-goods"></i> {{ $t('surveys.editor.menu.price') }}</span>
                    <page-editor-credits v-bind.sync="form" />
                </el-tab-pane>
            </el-tabs>
            <br>
            <el-form-item class="text-right">
                <router-link :to="survey.archive_time ? '/surveys/archive' : '/surveys'">
                    <el-button>{{ $t('surveys.editor.f.btn_cancel') }}</el-button>
                </router-link>
                <el-button
                    type="primary"
                    native-type="submit"
                    icon="el-icon-check"
                    :disabled="errors.any()"
                    :loading="loading"
                >{{ $t('surveys.editor.f.btn_save') }}</el-button>
            </el-form-item>
        </el-form>
        <div class="text-center">
            <p class="text-gray mb-1"><small>{{ $t('surveys.publish.h_link') }}</small></p>
            <h6 class="text-primary text-wrap">{{ endLink }}</h6>
            <p class="text-gray mb-1"><small>{{ $t('surveys.publish.h_link_aff') }}</small></p>
            <h6 class="text-primary text-wrap mb-2">{{ affiliateLink }}</h6>
            <a
                :href="'http://www.facebook.com/share.php?u=' + affiliateLink"
                target="_blank"
                class="m-2 p-2"
            >
                <i class="icon icon-3x ion-logo-facebook"></i>
            </a>
            <a
                :href="'http://twitter.com/home?status=' + affiliateLink"
                target="_blank"
                class="m-2 p-2"
            >
                <i class="icon icon-3x ion-logo-twitter"></i>
            </a>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import PageEditorSurvey from '@/Surveys/components/PageEditorSurvey';
import PageEditorAudience from '@/Surveys/components/PageEditorAudience';
import PageEditorScreenOut from '@/Surveys/components/PageEditorScreenOut';
import PageEditorCredits from '@/Surveys/components/PageEditorCredits';
export default {
    components: {
        PageEditorSurvey,
        PageEditorAudience,
        PageEditorScreenOut,
        PageEditorCredits
    },
    $_veeValidate: {
        validator: 'new'
    },
    data () {
        return {
            tab: 'survey',
            form: null,
            loading: false
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
        ...mapGetters([
            'SURVEYS_FIND'
        ]),
        survey () {
            return this.SURVEYS_FIND(this.$route.params.id);
        },
        endLink () {
            return 'https://'+process.env.APP_BASE_DOMAIN+'/end/'+this.survey.complete_code;
        },
        affiliateLink () {
            return 'https://'+process.env.APP_BASE_DOMAIN+'/aff/'+this.user.affiliate_code+'/'+this.survey.uuid;
        }
    },
    created () {
        if (this.survey) {
            this.form = JSON.parse(JSON.stringify(this.survey));
            this.form.urls = JSON.parse(this.form.urls);
            this.form.screen_out = JSON.parse(this.form.screen_out);
            this.form.profile_geoname_in = JSON.parse(this.form.profile_geoname_in);
            this.form.profile_geoname_out = JSON.parse(this.form.profile_geoname_out);
            this.form.related_in = JSON.parse(this.form.related_in);
            this.form.related_out = JSON.parse(this.form.related_out);
        } else {
            this.$router.push('/surveys');
        }
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
            'SURVEYS_SAVE'
        ]),
        submit () {
            this.$validator.validate().then((result) => {
                if (result) {
                    this.loading = true;
                    var data = Object.assign({}, this.form);
                    data.screen_out = JSON.stringify(data.screen_out);
                    this.SURVEYS_SAVE(data).then((response) => {
                        this.loading = false;
                        this.$notify.success({
                            title: this.$t('surveys.editor.f.done'),
                            message: this.$t('surveys.editor.f.donemsg'),
                            showClose: true
                        });
                    });
                }
            });
        }
    }
}
</script>

<style lang="scss">
</style>
