<template>
  <div>
    <h1 class="text-center text-primary">{{ $t('credits.refill.h') }}</h1>
    <br>
    <h5 class="text-center">{{ $t('credits.refill.amount.choose') }} ($1 = 1 USD)</h5>
    <div class="columns">
        <div class="column col-8 col-lg-10 col-md-12 col-mx-auto">
            <div class="columns">
                <div
                    v-for="(p, i) in prices"
                    class="column col-md-4"
                    @click="price = p"
                >
                    <el-card
                        class="text-center my-2 c-hand"
                        :shadow="p === price ? 'always' : 'hover'"
                    >
                        <h5 class="m-0" :class="p === price ? 'text-primary' : 'text-gray'"><strong v-if="p === price">${{ p }}</strong><span v-else >${{ p }}</span></h5>
                    </el-card>
                </div>
            </div>
            <!-- <el-input-number
                class="el-select--block mt-2"
                :min="5"
                :max="1000"
                :step="1"
                :precision="0"
                v-model="price"
            ></el-input-number>
            <br> -->
            <ul class="px-2" style="line-height: 1em; list-style-position: outside; list-style-type: lower-roman;">
                <li class="my-1"><small><strong>{{ $t('credits.refill.disclaimer.commission', [commission]) }}</strong></small></li>
                <li class="my-1 text-gray-"><small>{{ $t('credits.refill.amount.valid') }} {{ $t('credits.refill.disclaimer.termsofuse') }}</small></li>
                <!-- <li class="my-1"><small>$1 = 1 USD</small></li> -->
            </ul>
        </div>
    </div>
    <br>
    <div class="columns">
      <div class="column col-8 col-lg-10 col-md-12 col-mx-auto">
        <el-card shadow="never">
          <p v-if="vat" class="text-center mb-2">{{ $t('credits.refill.vat.info', [vat]) }}</p>
          <h3 class="text-center" v-if="vat && price">{{ $t('credits.refill.vat.total', [total.toFixed(2)]) }}</h3>
          <h3 class="text-center" v-else-if="vat" >&nbsp;</h3>
          <br>
          <el-collapse v-model="paymentMethod" accordion v-loading="readystate < 2">
            <el-collapse-item
              v-if="tpayon"
              name="PLNtransfer"
              title="Płacę w PLN (Karta lub Przelew online)"
            >
              <div class="text-center">
                  <el-button :disabled="!!disabled" :loading="paying == 'tpay'" type="primary" round style="width: 300px; font-size: 1.2em; border-radius: 23px;" @click="tpay">Zapłać z Tpay.com</el-button>
              </div>
              <br>
              <div class="container grid-xs">
                <p v-if="exchange" class="text-gray mb-1"><small>Wartość w PLN zostanie wyliczona na podstawie aktualnego średniego kursu NBP dolara amerykańskiego (opłata za przewalutowanie wynosi {{ exchange }}%)</small></p>
                <p v-if="user.company" class="text-gray"><small>Faktura zostanie wystawiona w PLN</small></p>
              </div>
            </el-collapse-item>
            <!-- <el-collapse-item
              v-if="tpayon"
              name="USDcard"
              :title="$t('credits.refill.payment.card.l')"
            >
              <div class="text-center">
                Not Available
              </div>
            </el-collapse-item> -->
            <el-collapse-item
              title="PayPal"
              name="USDpaypal"
            >
              <div class="text-center">
                <div id="paypal-button" :class="disabled"></div>
                <p v-if="tpayon && user.company" class="text-gray"><small>Faktura zostanie wystawiona w USD</small></p>
              </div>
            </el-collapse-item>
          </el-collapse>
          <br>
          <div class="columns text-grey">
            <div class="column col-4 col-md-12 mb-2">
              <p class="mb-1">{{ $t('credits.refill.payment.company.register') }}</p>
              <small>LABSEE Robert Borowski<br>Zielona 13A; 19-400 Olecko<br>VAT ID: PL8471384694<br>(Poland)</small>
            </div>
            <div class="column col-4 col-md-12 mb-2">
              <p class="mb-1">{{ $t('credits.refill.payment.company.contact') }}</p>
              <small>+48 792 730 660<br>info@answeo.com</small>
            </div>
            <div class="column col-4 col-md-12 mb-2">
              <p class="mb-1">{{ $t('credits.refill.payment.company.links') }}</p>
              <small v-if="user.locale == 'pl_PL'">
                <a href="https://answeo.com/pl/regulamin">{{ $t('credits.refill.payment.company.terms') }}</a><br>
                <a href="https://answeo.com/pl/polityka-prywatnosci">{{ $t('credits.refill.payment.company.privacy') }}</a>
              </small>
              <small v-else >
                <a href="https://answeo.com/en/terms-of-use">{{ $t('credits.refill.payment.company.terms') }}</a><br>
                <a href="https://answeo.com/en/privacy-policy">{{ $t('credits.refill.payment.company.privacy') }}</a>
              </small>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import axios from 'axios';
import paypal from 'paypal-checkout';
import braintree from 'braintree-web';
import config from '@/config';
export default {
    components: {
    },
    data () {
        return {
            prices: [10, 25, 50, 100, 500, 1000],
            price: 10,
            commission: null,
            exchange: null,
            paying: null,
            readystate: 0,
            paymentMethod: null
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
        percent () {
            return config.commissions.refill;
        },
        disabled () {
            return !this.price || this.paying ? 'disabled' : '';
        },
        invoice () {
            if (this.user.company) {
                return JSON.parse(this.user.company_invoice);
            }
            return null;
        },
        vat () {
return config.vat[config.vat.home];
            if (this.invoice) {
                if (this.invoice.country == config.vat.home) {
                    return config.vat[config.vat.home];
                }
            } else {
                if (config.vat[this.user.profile_country_living]) {
                    return config.vat[this.user.profile_country_living];
                }
            }
            return 0;
        },
        total () {
            var tax = Math.round(this.price * this.vat) / 100;
            return this.price + tax;
        },
        tpayon () {
            return (this.invoice && this.invoice.country === 'PL') || this.user.profile_country_living === 'PL';
        }
    },
    created () {
        this.paymentMethod = this.tpayon ? 'PLNtransfer' : 'USDpaypal';
        this.commission = config.commissions.refill;
        this.exchange = config.commissions.exchange;
    },
    mounted () {
      var vm = this;
      var env = process.env.NODE_ENV === 'production' && false === config.payments.sandbox ? 'production' : 'sandbox';
      this.$nextTick(() => {
          paypal.Button.render({
              braintree: braintree,
              // Add your credentials:
              // 1. Call your server to generate Braintree client tokens for each env
              client: {
                  sandbox: axios.get('/credits/refill/paypal-braintree?env=sandbox').then((response) => {
                      this.readystate++;
                      return response.data;
                  }),
                  production: axios.get('/credits/refill/paypal-braintree?env=production').then((response) => {
                      this.readystate++;
                      return response.data;
                  })
              },
              env: env,
              style: {
                  size: 'large',
                  color: 'blue',
                  shape: 'pill',
                  layout: 'vertical'
              },
              // Set up the payment:
              // 1. Add a payment callback
              payment (data, actions) {
                  // 2. Call actions.payment.create
                  return actions.payment.create({
                      payment: {
                          transactions: [{
                              amount: {
                                  total: vm.total,
                                  currency: 'USD'
                              }
                          }]
                      }
                  });
              },
              // Execute the payment:
              // 1. Add an onAuthorize callback
              onAuthorize: function (data, actions) {
                  // 2. Call Braintree to tokenize the payment
                  return actions.payment.tokenize().then(function (data) {
                      // 3. Call your server to execute the payment
                      return axios.post('/credits/refill/paypal-braintree?env='+env, {
                          price: vm.price,
                          gateway: 'paypal',
                          gateway_data: data.nonce
                      }).then((response) => {
                          vm.$router.push('/credits/refill/success');
                      });
                  });
              },
              onError: function (error) {
                  console.log(error);
                  vm.$router.push('/credits/refill/error');
              }
          }, '#paypal-button');
      });
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        tpay () {
            this.paying = 'tpay';
            var baseURL = document.location.protocol + '//' + document.location.host;
            var env = process.env.NODE_ENV === 'production' && false === config.payments.sandbox ? 'production' : baseURL;
            axios.get('/credits/refill/tpay-link?env='+env, {
                params: {
                    price: this.price
                }
            }).then((response) => {
                // axios.post('/credits/refill/tpay-link', response.data.mockupresulttest);
                // console.log(response);
                post('https://secure.tpay.com', response.data);
            });
        }
    }
}

function post(path, params, method) {
    method = method || "post";
    var form = document.createElement("form");
    form.setAttribute("method", method);
    form.setAttribute("action", path);
    for(var key in params) {
        if (params.hasOwnProperty(key)) {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", params[key]);
            form.appendChild(hiddenField);
        }
    }
    document.body.appendChild(form);
    form.submit();
}
</script>

<style lang="scss">
#paypal-button.disabled {
    position: relative;
    &:after {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0.5;
        background-color: #fff;
        z-index: 999999;
    }
}
</style>
