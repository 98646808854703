<template>
    <div class="col-6 col-lg-8 col-md-10 col-sm-11 col-xs-12 col-mx-auto">
        <div class="text-center">
          <small
            v-for="(lang, i) in languages"
            :key="lang.code"
            class="c-hand"
          >
            <span v-if="i">&centerdot;</span>
            <a class="m-1" @click="setLocale(lang.code)">{{ lang.name }}</a>
          </small>
        </div>
        <br>
        <h1 class="text-center text-primary">{{ $t('auth.signin.h') }}</h1>
        <p class="text-center">{{ $t('auth.signin.txt.sub') }}</p>
        <br>
        <!-- <h5 class="pb-2">Sign in with</h5>
        <div class="columns">
            <div class="column col-6 mb-2">
                <el-button
                    type="danger"
                    style="width: 100%"
                    @click="signInGoogle"
                >
                    <i class="icon ion-logo-googleplus"></i>
                    Google
                </el-button>
            </div>
            <div class="column col-6 mb-2">
                <el-button
                    type="primary"
                    style="width: 100%"
                    @click="signInFacebook"
                >
                    <i class="icon ion-logo-facebook"></i>
                    Facebook
                </el-button>
            </div>
            <div class="column col-6 mb-2">
                <el-button
                    type="primary" plain
                    style="width: 100%"
                    @click="signInTwitter"
                >
                    <i class="icon ion-logo-twitter"></i>
                    Twitter
                </el-button>
            </div>
            <div class="column col-6 mb-2">
                <el-button
                    type="success"
                    style="width: 100%"
                    @click="signInVK"
                >
                    <i class="icon ion-logo-vk"></i>
                    VK
                </el-button>
            </div>
        </div>
        <br><br>
        <h5 class="">Sign in with email</h5> -->
        <el-alert
            v-show="errormsg"
            class="mb-2"
            type="info"
            show-icon
            :closable="false"
            :title="errormsg"
        ></el-alert>
        <el-form
            class="pt-2"
            data-vv-scope="signin"
            @submit.native.prevent="signInEmail"
        >
            <el-form-item
                :error="errors.first('signin.email')"
            >
                <el-input
                    v-model="form.email"
                    name="email"
                    type="email"
                    v-validate="'required|email'"
                    :data-vv-delay="1000"
                    :data-vv-as="$t('auth.signin.f.email.e')"
                    :placeholder="$t('auth.signin.f.email.p')"
                ></el-input>
            </el-form-item>
            <el-form-item
                :error="errors.first('signin.password')"
            >
                <el-input
                    v-model="form.password"
                    name="password"
                    :type="passwordShow ? 'text' : 'password'"
                    v-validate="'required'"
                    :data-vv-as="$t('auth.signin.f.pass.e')"
                    :placeholder="$t('auth.signin.f.pass.p')"
                >
                    <el-tooltip
                        effect="light"
                        placement="top-end"
                        slot="append"
                        :content="$t('auth.signin.f.tiplost')"
                    >
                        <el-button
                            icon="el-icon-question"
                            @click="dialogLostPassword = true"
                        ></el-button>
                    </el-tooltip>
                    <el-tooltip
                        v-if="passwordShow"
                        class="d-block"
                        effect="light"
                        placement="top-end"
                        slot="suffix"
                        :content="$t('auth.passHide')"
                    >
                        <i
                            class="icon ion-ios-eye-off m-2 c-hand text-error"
                            style="font-size: 1.5em;"
                            @click="passwordShow = !passwordShow"
                        ></i>
                    </el-tooltip>
                    <el-tooltip
                        v-else
                        class="d-block"
                        effect="light"
                        placement="top-end"
                        slot="suffix"
                        :content="$t('auth.passShow')"
                    >
                        <i
                            class="icon ion-ios-eye m-2 c-hand"
                            style="font-size: 1.5em;"
                            @click="passwordShow = !passwordShow"
                        ></i>
                    </el-tooltip>
                </el-input>
            </el-form-item>
            <el-form-item>
                <div class="columns">
                    <div class="column col-6 col-xs-12">
                        <el-button
                            native-type="submit"
                            type="primary" style="width: 100%;"
                            :disabled="errors.any('signin')"
                            :loading="loading"
                        >{{ $t('auth.signin.f.btn_ok') }}</el-button>
                    </div>
                    <div class="column col-6 col-xs-12">
                        <br class="show-xs">
                        <el-button
                            type="text" style="width: 100%;"
                            @click="dialogMissingLink = true"
                        >{{ $t('auth.signin.f.tipmiss') }}</el-button>
                    </div>
                </div>
            </el-form-item>
        </el-form>
        <br>
        <p class="text-gray">
            <small>
                <i class="el-icon-info"></i>
                {{ $t('auth.signin.txt.accept') }}
            </small>
        </p>
        <el-dialog :visible.sync="dialogLostPassword">
            <dialog-lost-password
                v-if="dialogLostPassword"
                :email="form.email"
            />
        </el-dialog>
        <el-dialog :visible.sync="dialogMissingLink">
            <dialog-missing-link
                v-if="dialogMissingLink"
                :email="form.email"
            />
        </el-dialog>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { i18nSetLanguage } from '@/locale/i18n';
import languages from '@/locale/languages';
import Api from '@/Auth/api';
import DialogLostPassword from '@/Auth/components/DialogLostPassword';
import DialogMissingLink from '@/Auth/components/DialogMissingLink';
export default {
    components: {
        DialogLostPassword,
        DialogMissingLink
    },
    $_veeValidate: {
        validator: 'new'
    },
    data () {
        return {
            languages,
            form: {
                email: null,
                password: null
            },
            passwordShow: false,
            loading: false,
            errormsg: '',
            dialogLostPassword: false,
            dialogMissingLink: false
        }
    },
    computed: {
        ...mapState({
        })
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        setLocale (locale) {
          i18nSetLanguage(locale.replace('_', '-'));
        },
        signInEmail () {
            this.$validator.validate('signin.*').then((result) => {
                if (result) {
                    this.loading = true;
                    Api.signInEmail(this.form).then((response) => {
                        if (response.status == 201) {
                            this.$router.push('/auth/sign-in-email-new-account');
                        } else {
                            document.location = process.env.APP_BASE_URL;
                        }
                        this.loading = false;
                    }).catch((e) => {
                        this.errormsg = e.response && e.response.data ? this.$t('auth.'+e.response.data.code) : ''+e;
                        this.loading = false;
                    });
                }
            });
        },
        signInGoogle () {
            alert('google')
        },
        signInFacebook () {
            alert('facebook')
        },
        signInTwitter () {
            alert('twitter')
        },
        signInVK () {
            alert('vk')
        }
    }
}
</script>

<style lang="scss">
</style>
