<template>
    <div class="col-6 col-md-8 col-sm-10 col-xs-12 col-mx-auto">
        <h1 class="text-center text-primary">{{ $t('auth.lang.h') }}</h1>
        <el-alert
            v-show="message"
            class="mb-2"
            type="info"
            show-icon
            :closable="false"
            :title="message"
        ></el-alert>
        <el-form @submit.native.prevent="submit">
            <el-form-item :label="$t('auth.lang.f.lang.l')" class="mb-0"></el-form-item>
            <el-form-item
                :error="errors.first('tz')"
            >
                <el-select
                    v-model="form.locale"
                    class="d-block"
                    name="tz"
                    v-validate="'required'"
                    :data-vv-as="$t('auth.lang.f.lang.e')"
                    :placeholder="$t('auth.lang.f.lang.p')"
                >
                    <el-option
                        v-for="item in languages"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="text-right">
                <el-button @click="cancel">{{ $t('auth.lang.f.btn_cl') }}</el-button>
                <el-button
                    native-type="submit"
                    type="primary"
                    :loading="loading"
                    :disabled="errors.any()"
                >{{ $t('auth.lang.f.btn_ok') }}</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { i18nSetLanguage } from '@/locale/i18n';
import languages from '@/locale/languages';
export default {
    components: {
    },
    $_veeValidate: {
        validator: 'new'
    },
    data () {
        return {
            form: {
                locale: null
            },
            loading: false,
            message: '',
            languages: []
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        })
    },
    created () {
        this.languages = languages;
        this.form.locale = this.user.locale;
    },
    methods: {
        i18nSetLanguage,
        ...mapMutations([
        ]),
        ...mapActions([
            'AUTH_ACCOUNT_UPDATE'
        ]),
        cancel () {
            this.$router.push('/auth/account/');
        },
        submit () {
            this.$validator.validate().then((result) => {
                if (result) {
                    this.loading = true;
                    this.AUTH_ACCOUNT_UPDATE(this.form).then(() => {
                        this.$router.push('/auth/account/');
                        var lang = this.form.locale;
                        i18nSetLanguage(lang.replace('_', '-'));
                        this.loading = false;
                    }).catch((e) => {
                        this.message = e.response.data ? this.$t('auth.'+e.response.data.code) : ''+e;
                        this.loading = false;
                    });
                }
            });
        }
    }
}
</script>

<style lang="scss">
</style>
