import "babel-polyfill";

import config from '@/config';
const isProd = process.env.NODE_ENV === 'production';

import Vue from 'vue';
Vue.config.productionTip = false;

import * as Sentry from '@sentry/browser'
Sentry.init({
  dsn: config.sentry.dsn,
  integrations: [new Sentry.Integrations.Vue({ Vue })]
});

import VeeValidate from 'vee-validate';
Vue.use(VeeValidate, {
    inject: false
});

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import ElementUI from 'element-ui';
import { MessageBox } from 'element-ui';
import { i18n, i18nSetLanguage } from '@/locale/i18n';
import languages from '@/locale/languages';
Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value)
});

import axios from 'axios';
axios.defaults.baseURL = process.env.API_BASE_URL;
axios.defaults.headers.common['X-User-Agent-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
addRequestInterceptor();
addResponseInterceptor();

import router from '@/router';
import VueAnalytics from 'vue-analytics';
import VueMultianalytics from 'vue-multianalytics';
Vue.use(VueAnalytics, {
    id: process.env.GA_ID,
    router: router,
    debug: {
        enabled: !isProd,
        sendHitTask: isProd
    }
});
if (isProd) Vue.use(VueMultianalytics, {
    modules: {
        facebook: {
            token: process.env.FB_PIXEL,
            debug: false
        }
    },
    routing: {
        vueRouter: router
    }
});

import store from '@/store';
import App from '@/App/components/App';
import "@/style.scss";

new Vue({
    el: '#app',
    router,
    store,
    i18n,
    components: { App },
    data: {
        initializing: true
    },
    created: function () {
        var userAgentLocale = (
          navigator.language ||
          navigator.languages[0] ||
          navigator.browserLanguage ||
          navigator.userLanguage ||
          navigator.systemLanguage
        );
        var cookieLocale = this.$cookies.get('locale');
        var preferredLocale = cookieLocale || userAgentLocale || '';
        var lang = (languages.find(x => x.code === preferredLocale.replace('-', '_')) || languages[0]).code;
        lang = lang.replace('_', '-');
        i18nSetLanguage(lang);
        if (config.routes.unauthorized.includes(this.$route.name)) {
            this.initializing = false;
        } else {
            axios.get('/init').then((response) => {
                this.$store.dispatch('APP_INIT', response.data);
                var lang = response.data.locale.replace('_', '-');
                i18nSetLanguage(lang);
                this.initializing = false;
            }).catch((error) => {
                if (!error.response) {
                    throw error;
                }
                if (error.response.status !== 401) {
                    if (!isProd) console.log(error);
                }
                if (!config.routes.initialized.includes(this.$route.name)) {
                    this.$router.push('/auth/sign-in');
                }
                this.initializing = false;
            });
        }
    },
    render: function (h) {
        return h(App, {
            props: {
                initializing: this.initializing
            }
        });
    }
});

function addRequestInterceptor() {
    axios.interceptors.request.use(function (config) {
        config.headers['Accept-Language'] = (window.$cookies.get('locale') || 'en-US').replace('-', '_');
        if (!isProd) console.log(config);
        return config;
    }, function (error) {
        if (!isProd) {
            console.log(error);
            console.log(error.request);
        }
        return Promise.reject(error);
    });
}

function addResponseInterceptor() {
    axios.interceptors.response.use(function (response) {
        if (!isProd) console.log(response);
        var location = response.headers['x-top-location'] || response.headers['x-location'];
        if (location) {
            if (!isProd) console.log(location);
            if (!location.match(/^http/i)) {
                location = process.env.APP_BASE_URL + location;
            }
            if (response.headers['x-top-location']) {
                window.top.postMessage(JSON.stringify({ XTopLocation: location }), window.location.origin);
            } else {
                window.location = location;
            }
        } else if (response.data && !isProd) {
            console.log(response.data);
        }
        return response;
    }, function (error) {
        if (error.response && error.response.status < 500) {
            if (!isProd) {
                console.log(error.response);
                if (error.response.data) {
                    console.log(error.response.data);
                }
            }
            if (error.response.status == 401 && !config.paths.unauthorized.includes(error.config.url)) {
                document.location = process.env.APP_BASE_URL + '/auth/sign-in';
                return new Promise(function(resolve, reject) {});
            }
            return Promise.reject(error);
        } else {
            if (!isProd) {
                console.log(error.response);
                if (error.response && error.response.data) {
                    console.log(error.response.data);
                }
            }
            MessageBox.alert(''+error, 'Server response error', {
                type: 'warning',
                center: true,
                showConfirmButton: false,
                showClose: false
            });
            return new Promise(function(resolve, reject) {});
        }
    });
}

window.addEventListener("message", function(event) {
    if (event.origin === window.location.origin) {
        try {
            var data = JSON.parse(event.data);
            if (data.XTopLocation) {
                window.location = data.XTopLocation;
            }
        } catch (e) {}
    }
}, false);
