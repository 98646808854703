export default {
    auth: {
        // PageAccount
        home: {
            header: 'Account',
            name: 'Name',
            email: 'Email',
            password: 'Password',
            language: 'Language',
            timezone: 'Timezone',
            btn_delete_account: 'Delete account',
            del: {
                h: 'Warning',
                txt: 'This will permanently delete your account. Continue?',
                btn_ok: 'Delete',
                btn_cl: 'Cancel'
            }
        },
        // PageAccountName
        name: {
            h: 'Change user name',
            f: {
                name: {
                    l: 'New name',
                    p: 'Name',
                    e: 'Name'
                },
                btn_ok: 'Change',
                btn_cl: 'Cancel'
            }
        },
        // PageAccountEmail
        email: {
            h: 'Change email',
            done: {
                h: 'Confirm your new email address',
                txt: 'A confirmation link has been sent to your new email address and will be valid for one hour. Check your inbox to confirm the new email address.'
            },
            btn_done: 'OK',
            f: {
                email: {
                    l: 'Change account email',
                    p: 'Email',
                    e: 'Email'
                },
                pass: {
                    l: 'Use password to confirm',
                    p: 'Password',
                    e: 'Password'
                },
                btn_ok: 'Change',
                btn_cl: 'Cancel'
            }
        },
        // PageAccountEmailChangeConfirm
        emailset: {
            done: 'Your email address has been changed',
            btn_done: 'OK'
        },
        // PageAccountPassword
        pass: {
            h: 'Change password',
            done: 'Your password has been changed',
            btn_done: 'OK',
            f: {
                new: {
                    l: 'New password',
                    p: 'Enter new password',
                    e: 'New password'
                },
                re: {
                    p: 'Retype new password',
                    e: 'Retype password'
                },
                pass: {
                    l: 'Confirm with your current passsword',
                    p: 'Current password',
                    e: 'Current password'
                },
                btn_ok: 'Change',
                btn_cl: 'Cancel'
            }
        },
        // PageAccountLanguage
        lang: {
            h: 'Change language',
            f: {
                lang: {
                    l: 'Display language',
                    p: 'Select',
                    e: 'Language'
                },
                btn_ok: 'Change',
                btn_cl: 'Cancel'
            }
        },
        // PageAccountTimezone
        tz: {
            h: 'Change timezone',
            f: {
                tz: {
                    l: 'Your timezone',
                    p: 'Select',
                    e: 'Timezone'
                },
                btn_ok: 'Change',
                btn_cl: 'Cancel'
            }
        },
        // PageSignIn
        signin: {
            h: 'Sign in or register a new account',
            txt: {
                sub: 'If you do not have an account yet, one will be created.',
                accept: 'By signing in, you agree to the terms of use and privacy policy.'
            },
            d: {
                miss: {
                    h: 'Resend activation link',
                    f: {
                        email: {
                            p: 'Email',
                            e: 'Email'
                        },
                        btn_ok: 'Send'
                    },
                    msg_ok: 'An account activation link was sent to you. Check your inbox to activate your account.'
                },
                lost: {
                    h: 'Send me a password reset link',
                    f: {
                        email: {
                            p: 'Email',
                            e: 'Email'
                        },
                        btn_ok: 'Send'
                    },
                    msg_ok: 'The password reset link was sent to you and will be valid for one hour. Check your inbox to change your password.'
                }
            },
            f: {
                email: {
                    p: 'Email',
                    e: 'Email'
                },
                pass: {
                    p: 'Password',
                    e: 'Password'
                },
                btn_ok: 'Sign in / Register',
                tiplost: 'Forgot password?',
                tipmiss: 'Missing activation link?'
            }
        },
        // PageSignInEmailNewAccount
        emailnew: {
            h: 'Your new account has been created',
            txt: {
                t: 'Activation required',
                d: 'Check your inbox to confirm your email address and activate your account'
            }
        },
        // PageSignInEmailConfirm
        emailok: {
            h: 'Account activation'
        },
        // PageLostPasswordReset
        passchng: {
            h: 'Password reset',
            err: 'Wrong or expired password reset link',
            done: 'Your password has been changed',
            f: {
                pass: {
                    l: 'Set your new password',
                    p: 'Enter new password',
                    e: 'New password'
                },
                repass: {
                    l: 'Retype to confirm',
                    p: 'Confirm new password',
                    e: 'Password confirmation'
                },
                btn_ok: 'Change'
            }
        },
        AUTH_WRONG_SEC: 'Wrong link',
        AUTH_NOT_ACTIVATED: 'Account has not been activated yet',
        AUTH_ALREADY_ACTIVATED: 'Account has already been activated',
        AUTH_EMAIL_NOT_FOUND: 'Email address does not exist',
        AUTH_EMAIL_IN_USE: 'This email is already assigned to a different account',
        AUTH_VALIDATION_ERRORS: 'Wrong form data',
        AUTH_WRONG_PASSWORD: 'Wrong password',
        del: {
            h: 'Your acccount will be deleted',
            sub: 'Estimated time: {time}',
            btn_cl: 'Cancel'
        },
        passShow: 'Show password',
        passHide: 'Hide password'
    }
}
