import Api from '../api';
const SURVEYS_MY_LOAD_NEWER = 'SURVEYS_MY_LOAD_NEWER';
const SURVEYS_MY_LOAD_OLDER = 'SURVEYS_MY_LOAD_OLDER';
const SURVEYS_ARCHIVE_LOAD_NEWER = 'SURVEYS_ARCHIVE_LOAD_NEWER';
const SURVEYS_ARCHIVE_LOAD_OLDER = 'SURVEYS_ARCHIVE_LOAD_OLDER';
const SURVEYS_COMMENTS_LOAD = 'SURVEYS_COMMENTS_LOAD';
const SURVEYS_ADD = 'SURVEYS_ADD';
const SURVEYS_SAVE = 'SURVEYS_SAVE';
const SURVEYS_ACTIVATE = 'SURVEYS_ACTIVATE';
const SURVEYS_STOP = 'SURVEYS_STOP';
const SURVEYS_RESUME = 'SURVEYS_RESUME';
const SURVEYS_ARCHIVE = 'SURVEYS_ARCHIVE';
const SURVEYS_DELETE = 'SURVEYS_DELETE';

export default {
    [SURVEYS_MY_LOAD_NEWER] ({ dispatch, commit, state }, payload) {
        var params = {
            more: 'older',
            mylist_index: '111-99999999999999999999',
            id: 2147483647
        };
        return Api.my(params).then((response) => {
            commit('SURVEYS_CLEAR');
            commit('SURVEYS_PUSH_OLDER', response.data);
        });
    },
    [SURVEYS_MY_LOAD_OLDER] ({ dispatch, commit, state }, payload) {
        if (state.mylist.length) {
            var last = state.mylist[state.mylist.length - 1];
            var params = {
                more: 'older',
                mylist_index: last.mylist_index,
                id: last.id
            };
        } else {
            var params = {
                more: 'older',
                mylist_index: '111-99999999999999999999',
                id: 2147483647
            };
        }
        return Api.my(params).then((response) => {
            commit('SURVEYS_PUSH_OLDER', response.data);
        });
    },
    [SURVEYS_ARCHIVE_LOAD_NEWER] ({ dispatch, commit, state }, payload) {
        var params = {
            more: 'older',
            archive_time: new Date(),
            id: 2147483647
        };
        return Api.myarchive(params).then((response) => {
            commit('SURVEYS_ARCHIVE_CLEAR');
            commit('SURVEYS_ARCHIVE_PUSH_OLDER', response.data);
        });
    },
    [SURVEYS_ARCHIVE_LOAD_OLDER] ({ dispatch, commit, state }, payload) {
        if (state.mylist.length) {
            var last = state.mylist[state.mylist.length - 1];
            var params = {
                more: 'older',
                archive_time: last.archive_time,
                id: last.id
            };
        } else {
            var params = {
                more: 'older',
                archive_time: new Date(),
                id: 2147483647
            };
        }
        return Api.myarchive(params).then((response) => {
            commit('SURVEYS_ARCHIVE_PUSH_OLDER', response.data);
        });
    },
    [SURVEYS_COMMENTS_LOAD] ({ dispatch, commit, state }, payload) {
        if (state.comments.length) {
            var last = state.comments[state.comments.length - 1];
            var params = {
                complete_time: last.complete_time,
                id: last.id
            };
        } else {
            var params = {
                complete_time: new Date(),
                id: 2147483647
            };
        }
        return Api.comments(payload, params).then((response) => {
            commit('SURVEYS_COMMENTS_PUSH', response.data);
        });
    },
    [SURVEYS_ADD] ({ commit, state }, payload) {
        return Api.add(payload).then((response) => {
            commit('SURVEYS_PUSH_NEWER', [response.data]);
            return response;
        });
    },
    [SURVEYS_SAVE] ({ commit }, payload) {
        return Api.save(payload).then((response) => {
            if (response.data.archive_time) {
                commit('SURVEYS_ARCHIVE_PUSH_NEWER', [response.data]);
            } else {
                commit('SURVEYS_PUSH_NEWER', [response.data]);
            }
        });
    },
    [SURVEYS_ACTIVATE] ({ commit }, payload) {
        return Api.activate(payload).then((response) => {
            commit('SURVEYS_PUSH_NEWER', [response.data]);
        });
    },
    [SURVEYS_STOP] ({ commit }, payload) {
        return Api.stop(payload).then((response) => {
            commit('SURVEYS_PUSH_NEWER', [response.data]);
        });
    },
    [SURVEYS_RESUME] ({ commit }, payload) {
        return Api.resume(payload).then((response) => {
            if (payload.archive_time) {
                commit('SURVEYS_ARCHIVE_DELETE', payload);
            }
            commit('SURVEYS_PUSH_NEWER', [response.data]);
        });
    },
    [SURVEYS_ARCHIVE] ({ commit }, payload) {
        return Api.archive(payload).then((response) => {
            if (response.data.archive_time) {
                commit('SURVEYS_DELETE', payload);
                commit('SURVEYS_ARCHIVE_PUSH_NEWER', [response.data]);
            } else {
                commit('SURVEYS_PUSH_NEWER', [response.data]);
            }
        });
    },
    [SURVEYS_DELETE] ({ commit }, payload) {
        return Api.delete(payload).then((response) => {
            if (response.data) {
                commit('SURVEYS_PUSH_NEWER', [response.data]);
            } else {
                commit('SURVEYS_DELETE', payload);
            }
        }).catch((error) => {
            if (error.response.status == 404) {
                commit('SURVEYS_DELETE', payload);
            } else {
                return Promise.reject(error);
            }
        });
    }
}
