export default {
    credits: {
        home: {
            h: 'Account operations',
            l_expire: 'Balance expires in:',
            l_need_more: 'You need {0} more to reach the minimum withdrawal amount',
            btn_buy: 'Recharge account',
            btn_get: 'Withdraw money',
            btn_voucher: 'Redeem voucher',
            nodata: 'No operations',
            list: {
                date: 'Date',
                operation: 'Operation',
                amount: 'Amount',
                balance: 'Balance'
            }
        },
        nomoney: {
            h: 'Insufficient funds',
            txt: 'Your account balance is too low to carry out the active surveys. Once your account balance drops below the response cost, your surveys will become invisible to respondents. Recharge your account in to collect responses without interruptions.'
        },
        refill: {
            h: 'Account recharge',
            amount: {
                choose: 'Define amount',
                valid: 'Account balance validity will be extended by 365 days.',
            },
            disclaimer: {
                commission: 'A {0}% operating fee will be charged on the defined amount.',
                termsofuse: 'After expiration your account balance will be reset.'
            },
            vat: {
                info: 'Tax will be applied to the defined recharge amount (VAT {0}%)',
                total: 'Total cost: {0} USD'
            },
            payment: {
              card: {
                l: 'Card Payment'
              },
              company: {
                register: 'Company',
                contact: 'Contact',
                links: 'Info',
                terms: 'Terms Of Use',
                privacy: 'Privacy Policy'
              }
            },
            success: {
                h: 'Payment completed successfully',
                info: 'The funds on your account should be available within a few minutes.',
                btn: 'Sign in'
            },
            err: {
                h: 'Payment error',
                info: 'Try again later'
            }
        },
        withdraw: {
            h: 'Withdrawal',
            f: {
                email: {
                    l: 'Email address you use to sign in into your PayPal account',
                    p: 'PayPal Account Email',
                    e: 'Email'
                },
                pass: {
                    l: 'Confirm with your answeo password',
                    p: 'Password',
                    e: 'Password'
                },
                btn_ok: 'Withdraw',
                btn_cancel: 'Cancel'
            },
            CREDITS_WRONG_AMOUNT: 'Insufficient funds',
            CREDITS_WRONG_PASSWORD: 'Wrong password'
        },
        voucher: {
          h: 'Redeem voucher',
          err: 'Wrong or invalid voucher code',
          f: {
            code: {
              l: 'Enter voucher code',
              p: 'Code',
              e: 'Code'
            },
            btn_ok: 'Redeem',
            btn_cancel: 'Cancel'
          }
        }
    }
}
