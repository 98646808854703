<template>
    <el-row style="height: 100%;">
        <el-col class="text-center p-2 m-2">
            <h1>Page not found</h1>
            <a :href="home">Continue to home page &gt;&gt;&gt;</a>
        </el-col>
    </el-row>
</template>

<script>
export default {
    computed: {
        home () {
            return process.env.APP_BASE_URL;
        }
    }
}
</script>
