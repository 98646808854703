<template>
    <div>
        <h1 class="text-center text-primary">{{ $t('app.affiliate.txt.recommend') }}</h1>
        <div class="columns">
            <div class="column col-8 col-md-12 col-mx-auto">
                <affiliate-link />
            </div>
        </div>
    </div>
</template>

<script>
import AffiliateLink from '@/App/components/AffiliateLink';
export default {
    components: {
        AffiliateLink
    }
}
</script>

<style lang="scss">
</style>
