const SURVEYS_NEW = 'SURVEYS_NEW';
const SURVEYS_PROMO = 'SURVEYS_PROMO';
const SURVEYS_ACTIVE = 'SURVEYS_ACTIVE';
const SURVEYS_STOPPED = 'SURVEYS_STOPPED';
const SURVEYS_FIND = 'SURVEYS_FIND';

import parseDate from 'date-fns/parse';

export default {
    [SURVEYS_NEW] (state, getters, rootState) {
        return state.mylist.filter(x => !x.active_time);
    },
    [SURVEYS_PROMO] (state, getters, rootState) {
        return state.mylist.filter(x => x.active_time && !x.stop_time && x.promo_time && parseDate(x.promo_time) > new Date());
    },
    [SURVEYS_ACTIVE] (state, getters, rootState) {
        return state.mylist.filter(x => x.active_time && !x.stop_time && (!x.promo_time || parseDate(x.promo_time) <= new Date()));
    },
    [SURVEYS_STOPPED] (state, getters, rootState) {
        return state.mylist.filter(x => x.active_time && x.stop_time);
    },
    SURVEYS_FIND: (state, getters, rootState) => (id) => {
        return state.mylist.find(x => x.id == id) || state.archive.find(x => x.id == id);
    }
}
