<template>
  <div>
    <div class="container grid-xs">
      <el-input-number class="el-select--block" v-model="rates.PL" :precision="4" :step="0.0001"></el-input-number>
    </div>
    <br>
    <div class="text-center">
        <transition
            mode="out-in"
            name="el-fade-in"
        >
            <div v-if="historyLoading == 'newer'" v-loading="historyLoading"></div>
            <el-button v-else size="mini" icon="el-icon-refresh" circle @click="historyLoadNewer"></el-button>
        </transition>
    </div>
    <br>
    <template v-if="history.length">
    <el-table
        :data="history"
        size="mini"
    >
      <el-table-column
          prop="id"
          align="left"
          label="TransID"
      ></el-table-column>
      <el-table-column
          align="left"
          label="User"
      >
        <div slot-scope="scope">{{ scope.row.email }}</div>
      </el-table-column>
      <el-table-column
          align="center"
          label="Open Time"
          width="150"
      >
        <div slot-scope="scope" class="text-nowrap">{{ scope.row.open_time.substr(0, 16) }}</div>
      </el-table-column>
      <el-table-column
          prop="close_time"
          align="center"
          label="Close Time"
          width="150"
      >
        <div slot-scope="scope" class="text-nowrap">{{ (scope.row.close_time || scope.row.expire_time).substr(0, 16) }}</div>
      </el-table-column>
      <el-table-column
          align="center"
          label="Wallet"
      >
        <div slot-scope="scope">{{ scope.row.data.wallet }}</div>
      </el-table-column>
      <el-table-column
          align="left"
          label="WalletID"
      >
        <div slot-scope="scope">{{ scope.row.data.email }}</div>
      </el-table-column>
      <el-table-column
          align="right"
          label="Amount"
          width="90"
      >
        <div slot-scope="scope">$ {{ (-scope.row.amount/100.0).toFixed(2) }}</div>
      </el-table-column>

      <el-table-column
          align="right"
          :label="'-'+commission+'%'"
          width="120"
      >
        <div v-if="scope.row.profile_country_living == 'PL'" slot-scope="scope">PLN {{ (-scope.row.amount * rates.PL * (100 - commission) / 100.0 / 100.0).toFixed(2) }}</div>
      </el-table-column>
      <el-table-column
          align="center"
          label="Status"
          fixed="right"
          width="100"
      >
        <div slot-scope="scope">
          <el-button
            v-if="scope.row.status === 'pending'"
            type="danger"
            size="mini"
            @click="complete(scope.row)"
          >Complete</el-button>
          <span v-else>{{ scope.row.status }}</span>
        </div>
      </el-table-column>
    </el-table>
    <br>
    <div v-if="!historyBottom" class="text-center">
        <div v-if="historyLoading == 'older'" v-loading="historyLoading"></div>
        <el-button v-else size="mini" icon="el-icon-more" circle @click="historyLoadOlder"></el-button>
    </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import Api from '../api';
import axios from 'axios';
import config from '@/config';

export default {
    components: {
    },
    data () {
        return {
            rates: {
              'PL': 0
            },
            history: [],
            historyBottom: false,
            historyLoading: null
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
        commission () {
            return config.commissions.withdrawal;
        }
    },
    created () {
        this.historyLoadNewer();
        this.getRates();
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        getRates () {
          axios.get('/nbp-mid-rate-usd.txt',  { crossdomain: true }).then((response) => {
            this.rates.PL = parseFloat(response.data);
          });
        },
        historyLoadNewer () {
          this.historyLoading = 'newer';
          this.history = [];
          this.historyLoadOlder();
        },
        historyLoadOlder () {
          this.historyLoading = this.historyLoading || 'older';
          if (this.history.length) {
            var last = this.history[this.history.length - 1];
            var params = {
                id: last.id
            };
          } else {
            var params = {
                id: 2147483647
            };
          }
          Api.withdrawals(params).then((response) => {
            for (var item of response.data) {
              item.data = JSON.parse(item.data);
              this.history.push(item);
            }
            if (response.data.length == 0) {
                this.historyBottom = true;
            }
            this.historyLoading = null;
          });
        },
        complete (transaction) {
          this.$confirm(`${transaction.data.wallet} <${transaction.data.email}> $ ${(-transaction.amount/100.0).toFixed(2)}`, 'Withdrawal confirmation', {
            showClose: false,
            type: 'warning'
          }).then(() => {
            Api.withdrawalComplete(transaction.id).then((response) => {
              response.data.data = JSON.parse(response.data.data);
              Object.assign(transaction, response.data);
            });
          }).catch(() => {});
        }
    }
}
</script>
