<template>
    <div>
        <h1 class="text-primary text-center">{{ $t('surveys.archive.h') }}</h1>
        <br>
        <div class="text-center">
            <transition
                mode="out-in"
                name="el-fade-in"
            >
                <div v-if="loading.newer" v-loading="loading.newer"></div>
                <el-button v-else size="mini" icon="el-icon-refresh" circle @click="loadNewer"></el-button>
            </transition>
        </div>
        <br>
        <template v-if="archive.length">
            <survey-list-item
                v-for="survey in archive"
                :key="survey.id"
                :survey="survey"
                list="stopped"
            />
            <div v-if="!bottom" class="text-center">
                <div v-if="loading.older" v-loading="loading.older"></div>
                <el-button v-else size="mini" icon="el-icon-more" circle @click="loadOlder"></el-button>
            </div>
        </template>
        <div v-else class="text-center m-2 p-2 text-gray">{{ $t('surveys.archive.nodata') }}</div>

        <template v-if="user.profile_lang_first == 'pl'">
            <br>
            <h3 class="text-center text-primary"><strong>answeo</strong> <small>poleca <strong>ProfiTest.pl</strong></small></h3>
            <h5 class="text-center text-primary">profesjonalne narzędzie do tworzenia ankiet online</h5>
            <br>
            <el-card class="centered" style="max-width: 1000px;">
                <div class="px-1">
                    <a href="https://profitest.pl?utm_source=answeo&utm_campaign=my-surveys-archive&utm_content=banner-bottom" target="_blank">
                        <img class="img-responsive centered py-2" src="@/assets/banner-profitest-pl.png" />
                    </a>
                </div>
            </el-card>
        </template>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import SurveyListItem from '@/Surveys/components/SurveyListItem';
export default {
    components: {
        SurveyListItem
    },
    data () {
        return {
            loading: {
                newer: false,
                older: false
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user,
            bottom: state => state.surveys.archiveBottom,
            archive: state => state.surveys.archive
        })
    },
    created () {
        this.loadNewer();
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
            'SURVEYS_ARCHIVE_LOAD_NEWER',
            'SURVEYS_ARCHIVE_LOAD_OLDER'
        ]),
        loadNewer () {
            this.loading.newer = true;
            this.SURVEYS_ARCHIVE_LOAD_NEWER().then(() => {
                this.loading.newer = false;
            });
        },
        loadOlder () {
            this.loading.older = true;
            this.SURVEYS_ARCHIVE_LOAD_OLDER().then(() => {
                this.loading.older = false;
            });
        }
    }
}
</script>

<style lang="scss">
</style>
