<template>
    <el-dialog
        :visible="visible"
        @update:visible="$emit($event)"
        :show-close="false"
        :before-close="(done) => { loading || done(); }"
    >
        <h3 slot="title" class="m-0">{{ $t('credits.voucher.h') }}</h3>
        <el-alert
            v-show="error"
            class="mb-2"
            type="info"
            show-icon
            :closable="false"
            :title="$t('credits.voucher.err')"
        ></el-alert>
        <el-form
            class="pt-2"
            @submit.native.prevent="submit"
        >
            <el-form-item
                :label="$t('credits.voucher.f.code.l')"
                :error="errors.first('code')"
            >
                <el-input
                    :disabled="loading"
                    v-model="form.code"
                    name="code"
                    type="text"
                    v-validate="'required'"
                    :data-vv-delay="1000"
                    :data-vv-as="$t('credits.voucher.f.code.e')"
                    :placeholder="$t('credits.voucher.f.code.p')"
                ></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button v-if="!loading" @click="$emit('update:visible', false)">{{ $t('credits.voucher.f.btn_cancel') }}</el-button>
            <el-button type="primary" :loading="loading" :disabled="!form.code" @click="submit">{{ $t('credits.voucher.f.btn_ok') }}</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Api from '../api';
export default {
    components: {
    },
    $_veeValidate: {
        validator: 'new'
    },
    props: {
        visible: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            form: {
                code: null
            },
            loading: false,
            error: false
        }
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        submit () {
            this.$validator.validate().then((result) => {
                if (result) {
                    this.loading = true;
                    Api.voucher(this.form).then((response) => {
                        this.loading = false;
                        this.$emit('update:visible', false);
                        this.$emit('done');
                    }).catch((e) => {
                        this.loading = false;
                        this.error = true;
                    });
                }
            });
        }
    }
}
</script>

<style lang="scss">
</style>
