export default {
    responds: {
        my: {
          h: 'My responses',
          table: {
            name: 'Survey',
            date: 'Date',
            status: 'Response',
            price: 'Price',
            charity: 'Charity'
          }
        },
        start: {
            time: {
                h: 'Important',
                txt1: 'You have',
                txt2: 'to respond - otherwise your response will be cancelled.',
                btn: 'OK'
            },
            recommend: {
                h: 'Sign in',
                txt: 'In order to respond to this survey you must first sign in. After signing in return here and <a href="">refresh</a> the page.',
                info: 'If you do not have answeo account yet, you can create one for free.',
                btn: 'Sign in / Register'
            },
            profile: {
              h: 'Profile update required',
              txt: 'In order to be able to continue to fill out surveys, you must confirm your respondent profile is up to date.',
              btn: 'Profile update'
            },
            out: {
                h: 'In order to qualify to the survey you must first answer a few questions',
                btn: 'Next',
                reject: {
                    h: 'Response discarded',
                    info: 'Unforunately you do not qualify to respond to this survey.',
                    btn: 'Try another survey'
                }
            }
        },
        report: {
            btn: 'Report',
            WRONG_SURVEY_LINK: 'Missing survey / Survey is not working',
            NOT_A_SURVEY: 'This is not a survey',
            NO_END_LINK: 'Missing End-Link',
            WRONG_END_LINK: "The End-Link doesn't work",
            dialog: {
                msg: 'The author of the survey will be notified of the issue. The survey will be paused and will be unavailable until it is reactivated by the author.',
                btn_ok: 'Report',
                btn_cancel: 'Cancel',
                done: 'Report has been sent'
            }
        },
        complete: {
            h: 'Survey completed',
            amount: 'You made',
            charity: {
                h: 'Wanna do some good?',
                txt: "Move the slider to decide what share of your earnings you'd like to donate to charity:",
                btn_yes: 'Donate {0}',
                btn_no: 'Not this time',
                done: 'Thanks to your generosity we donated:'
            },
            rate: 'Rate survey',
            comment: {
                p: 'Leave a comment',
                write: 'Writing',
                save: 'Saved'
            },
            btn_next: 'Next survey'
        },
        confirm: {
            h: 'Confirm your response',
            f: {
                email: {
                    l: 'Enter your respondent account email',
                    p: 'Email',
                    e: 'Email'
                },
                code: {
                    l: 'Confirmation code',
                    p: 'Code',
                    e: 'Code'
                },
                btn_ok: 'Confirm'
            },
            e: {
                WRONG_EMAIL: 'Wrong respondent email',
                NO_SURVEY: 'Wrong confirmation link',
                WRONG_CODE: 'Wrong confirmation code'
            }
        },
        errors: {
            RESPOND_FORBIDDEN: 'The survey is unavailable',
            NOT_FOUND: 'Wrong survey response link'
        }
    }
}
