<template>
    <div>
        <div v-loading.fullscreen.lock="loading"></div>
        <el-container v-if="!loading" id="app-theme">
            <el-header v-if="!remail" style="height: auto; font-weight: 200;">
                <div class="container bg-primary">
                    <div class="d-flex d-flex-center d-flex-space-between">
                        <!-- <div v-if="!$route.params.code && !rcode"></div> -->
                        <div class="pb-1">
                            <span style="font-size: 1em;">
                                ans<span class="text-bold">we</span>o
                            </span>
                        </div>
                        <!-- <div v-if="!$route.params.code && !rcode"></div> -->
                        <!-- <div v-if="$route.params.code || rcode" class="">
                            <el-dropdown size="mini">
                                <el-button size="mini" type="primary" class="px-2">
                                    {{ $t('responds.report.btn') }} <i class="el-icon-arrow-down el-icon--right hide-xs"></i>
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>{{ $t('responds.report.confirm') }}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div> -->
                    </div>
                </div>
            </el-header>
            <el-main id="main-view">
                <div class="container grid-xl">
                    <div class="text-center col-6 col-md-8 col-sm-10 col-xs-12 col-mx-auto">
                        <h4>{{ $t('responds.confirm.h') }}</h4>
                        <el-alert
                            v-if="error"
                            class="mb-2"
                            type="info"
                            show-icon
                            :closable="false"
                            :title="error"
                        ></el-alert>
                        <el-form label-position="left" @submit.native.prevent="submit">
                            <el-form-item
                                :label="$t('responds.confirm.f.email.l')"
                                :error="errors.first('email')"
                            >
                                <el-input
                                    v-model="form.email"
                                    name="email"
                                    type="email"
                                    v-validate="'required|email'"
                                    :data-vv-delay="1000"
                                    :data-vv-as="$t('responds.confirm.f.email.e')"
                                    :placeholder="$t('responds.confirm.f.email.p')"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                v-if="!$route.params.code && !rcode"
                                :label="$t('responds.confirm.f.code.l')"
                                :error="errors.first('code')"
                            >
                                <el-input
                                    v-model="form.code"
                                    name="code"
                                    type="text"
                                    v-validate="'required'"
                                    :data-vv-as="$t('responds.confirm.f.code.e')"
                                    :placeholder="$t('responds.confirm.f.code.p')"
                                ></el-input>
                            </el-form-item>
                            <el-form-item class="text-right">
                                <el-button
                                    native-type="submit"
                                    type="primary"
                                    :disabled="errors.any()"
                                    :loading="loading"
                                >{{ $t('responds.confirm.f.btn_ok') }}</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import Api from '@/Responds/api';
export default {
    components: {
    },
    $_veeValidate: {
        validator: 'new'
    },
    props: {
        remail: {
            type: String,
            default: null
        },
        rcode: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            loading: false,
            form: {
                email: null,
                code: null
            },
            error: null
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
        ...mapGetters([
        ])
    },
    created () {
        this.form.email = this.remail || this.user.email;
        this.form.code = this.rcode || this.$route.params.code;
        if (this.form.email && this.form.code) {
            this.submit();
        }
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        submit () {
            if (!this.loading) {
                this.$validator.validate().then((result) => {
                    if (result) {
                        this.loading = true;
                        Api.confirm(this.form).then((response) => {
                            this.loading = false;
                        }).catch((error) => {
                            if (error.response.status === 404) {
                                if (error.response.data.code === 'NO_SURVEY') {
                                    if (!this.$route.params.code && !this.rcode) {
                                        this.error = this.$t('responds.confirm.e.WRONG_CODE');
                                    } else {
                                        this.error = this.$t('responds.confirm.e.NO_SURVEY');
                                    }
                                } else {
                                    this.error = this.$t('responds.confirm.e.WRONG_EMAIL');
                                }
                            } else {
                                this.$msgbox({
                                    title: this.$t('UNKNOWN_ERROR'),
                                    type: 'warning',
                                    center: true,
                                    showConfirmButton: false,
                                    showClose: false
                                });
                            }
                            this.loading = false;
                        });
                    }
                });
            }
        }
    }
}
</script>

<style lang="scss">
</style>
