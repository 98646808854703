const APP_USER_SET = 'APP_USER_SET';
const APP_AFFILIATE_INFO_SET = 'APP_AFFILIATE_INFO_SET';
const APP_USER_PROFILE_SAVE = 'APP_USER_PROFILE_SAVE';
const APP_HOMELIST_CLEAR = 'APP_HOMELIST_CLEAR';
const APP_HOMELIST_PUSH_NEWER = 'APP_HOMELIST_PUSH_NEWER';
const APP_HOMELIST_PUSH_OLDER = 'APP_HOMELIST_PUSH_OLDER';

export default {
    [APP_USER_SET] (state, payload) {
        state.user = payload;
    },
    [APP_AFFILIATE_INFO_SET] (state, payload) {
        state.affiliateInfo = payload;
    },
    [APP_USER_PROFILE_SAVE] (state, payload) {
        state.user.profile_id = payload.id;
        delete payload.id;
        delete payload.user_id;
        state.user = Object.assign(state.user, payload);
    },
    [APP_HOMELIST_CLEAR] (state, payload) {
        state.homelist = [];
        state.homelistBottom = false;
    },
    [APP_HOMELIST_PUSH_NEWER] (state, payload) {
        for (var item of payload) {
            var index = state.homelist.findIndex(x => x.id === item.id);
            if (index >= 0) {
                state.homelist.splice(index, 1);
            }
        }
        state.homelist.splice(0, 0, ...payload);
    },
    [APP_HOMELIST_PUSH_OLDER] (state, payload) {
        for (var item of payload) {
            var index = state.homelist.findIndex(x => x.id === item.id);
            if (index >= 0) {
                state.homelist.splice(index, 1);
            }
        }
        state.homelist.push(...payload);
        if (payload.length == 0) {
            state.homelistBottom = true;
        }
    }
}
