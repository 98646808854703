<template>
    <div v-if="survey">
        <h1 class="text-center text-primary">{{ $t('surveys.responses.h') }}</h1>
        <h4 class="text-center">{{ survey.name }}</h4>
        <br>
        <el-radio-group
          class="el-radio-group--block"
          v-model="status"
          @change="historyLoadNewer"
        >
          <el-radio-button label="complete">
            <i class="show-xs icon ion-ios-checkmark-circle-outline"></i>
            <span class="hide-xs">{{ $t('surveys.responses.status.complete') }}</span>
          </el-radio-button>
          <el-radio-button label="progress">
            <i class="show-xs icon ion-ios-radio-button-off"></i>
            <span class="hide-xs">{{ $t('surveys.responses.status.progress') }}</span>
          </el-radio-button>
          <el-radio-button label="screenout">
            <i class="show-xs icon ion-ios-remove-circle-outline"></i>
            <span class="hide-xs">{{ $t('surveys.responses.status.screenout') }}</span>
          </el-radio-button>
          <el-radio-button label="">
            <span>{{ $t('surveys.responses.status.all') }}</span>
          </el-radio-button>
        </el-radio-group>
        <br>
        <br>
        <div class="text-center">
            <transition
                mode="out-in"
                name="el-fade-in"
            >
                <div v-if="historyLoading == 'newer'" v-loading="historyLoading"></div>
                <el-button v-else size="mini" icon="el-icon-refresh" circle @click="historyLoadNewer"></el-button>
            </transition>
        </div>
        <br>
        <template v-if="history.length">
        <el-table
            :data="history"
            style="width: 100%"
            size="mini"
        >
          <el-table-column
              prop="create_time"
              :formatter="dateFormat"
              align="center"
              :label="$t('surveys.responses.table.date')"
              width="115"
          ></el-table-column>
          <el-table-column
            align="center"
            :label="$t('surveys.responses.table.time')"
            width="115"
          >
            <template slot-scope="scope">
              <i v-if="!scope.row.complete_time" class="text-large icon ion-ios-radio-button-off"></i>
              <i v-else-if="scope.row.create_time == scope.row.complete_time" class="text-large icon ion-ios-remove-circle-outline"></i>
              <span v-else>{{ timeFormat(scope.row) }}</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="[SID]"
              width="100"
          >
            <samp slot-scope="scope">{{ scope.row.id }}</samp>
          </el-table-column>
          <el-table-column
              align="center"
              label="[UID]"
              width="290"
          >
            <samp slot-scope="scope">{{ scope.row.uuid }}</samp>
          </el-table-column>
          <el-table-column
            align="center"
            :label="$t('surveys.responses.table.sex')"

          >
              <i
                  slot-scope="scope"
                  class="icon text-large"
                  :class="scope.row.profile_sex == 'male' ? 'ion-ios-male' : 'ion-ios-female'"
              ></i>
          </el-table-column>
          <el-table-column
            align="center"
            :label="$t('surveys.responses.table.age')"

          >
              <span slot-scope="scope">{{ differenceInYears(scope.row.create_time, scope.row.profile_birth_date) }}</span>
          </el-table-column>
          <el-table-column
            prop="profile_country_living"
            align="center"
            :label="$t('surveys.responses.table.country')"

          ></el-table-column>
        </el-table>
        <br>
        <div v-if="!historyBottom" class="text-center">
            <div v-if="historyLoading == 'older'" v-loading="historyLoading"></div>
            <el-button v-else size="mini" icon="el-icon-more" circle @click="historyLoadOlder"></el-button>
        </div>
        </template>
    </div>
</template>

<script>
import parseDate from 'date-fns/parse';
import differenceInYears from 'date-fns/difference_in_years';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import Api from '../api';
export default {
    components: {
    },
    data () {
        return {
            status: '',
            loading: null,
            history: [],
            historyBottom: false,
            historyLoading: null
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
        ...mapGetters([
            'SURVEYS_FIND'
        ]),
        survey () {
            return this.SURVEYS_FIND(this.$route.params.id);
        }
    },
    created () {
        if (!this.survey) {
          this.$router.push('/surveys');
        } else {
          this.status = this.$route.params.status;
          this.historyLoadNewer();
        }
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        differenceInYears,
        historyLoadNewer () {
          this.historyLoading = 'newer';
          this.history = [];
          this.historyLoadOlder();
        },
        historyLoadOlder () {
          this.historyLoading = this.historyLoading || 'older';
          if (this.history.length) {
            var last = this.history[this.history.length - 1];
            var params = {
                status: this.status,
                more: 'older',
                create_time: last.create_time,
                id: last.id
            };
          } else {
            var params = {
                status: this.status,
                more: 'older',
                create_time: new Date(),
                id: 2147483647
            };
          }
          Api.responses(this.survey.id, params).then((response) => {
            for (var item of response.data) {
                var index = this.history.findIndex(x => x.id === item.id);
                if (index >= 0) {
                    this.history.splice(index, 1);
                }
            }
            this.history.push(...response.data);
            if (response.data.length == 0) {
                this.historyBottom = true;
            }
            this.historyLoading = null;
          });
        },
        dateFormat (row, column, cellValue, index) {
            return this.$d(parseDate(row.create_time), 'short');
        },
        timeFormat(row) {
          let time = Math.ceil((new Date(row.complete_time) - new Date(row.create_time)) / 1000);
          let seconds = time % 60;
          let minutes = ((time - seconds) / 60) % 60;
          let hours = Math.floor(time / 86400);
          seconds = seconds < 10 ? `0${seconds}` : seconds;
          minutes = minutes < 10 ? `0${minutes}` : minutes;
          return hours ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
        }
    }
}
</script>

<style lang="scss">
</style>
