import Api from '@/App/api';
const APP_INIT = 'APP_INIT';
const APP_SETUP_COMPANY = 'APP_SETUP_COMPANY';
const APP_INVOICE_SAVE = 'APP_INVOICE_SAVE';
const APP_USER_PROFILE_SAVE = 'APP_USER_PROFILE_SAVE';
const APP_USER_RESEARCHER_SAVE = 'APP_USER_RESEARCHER_SAVE';
const APP_HOMELIST_LOAD_NEWER = 'APP_HOMELIST_LOAD_NEWER';
const APP_HOMELIST_LOAD_OLDER = 'APP_HOMELIST_LOAD_OLDER';

export default {
    [APP_INIT] ({ commit, state, rootState }, payload) {
        commit('APP_USER_SET', payload.data.user);
        commit('APP_AFFILIATE_INFO_SET', payload.data.affiliateInfo);
        commit('CREDITS_ACCOUNT_SET', payload.data.account);
    },
    [APP_SETUP_COMPANY] ({ commit, state, rootState }, payload) {
        return Api.setup(payload).then((response) => {
            commit('APP_USER_SET', response.data.user);
        });
    },
    [APP_INVOICE_SAVE] ({ commit, state, rootState }, payload) {
        return Api.invoiceSave(payload).then((response) => {
            commit('APP_USER_SET', response.data.user);
        });
    },
    [APP_USER_PROFILE_SAVE] ({ commit, state, rootState }, payload) {
        return Api.profileSave(payload).then((response) => {
            commit('APP_USER_PROFILE_SAVE', response.data);
        });
    },
    [APP_USER_RESEARCHER_SAVE] ({ commit, state, rootState }, payload) {
        return Api.researcherSave(payload).then((response) => {
            commit('APP_USER_SET', response.data.user);
        });
    },
    [APP_HOMELIST_LOAD_NEWER] ({ dispatch, commit, state }, payload) {
        // if (state.homelist.length) {
        //     var first = state.homelist[0];
        //     var params = {
        //         more: 'newer',
        //         search_index: first.search_index,
        //         id: first.id
        //     };
        // } else {
        //     return dispatch('APP_HOMELIST_LOAD_OLDER', payload);
        // }
        // return Api.surveys(params).then((response) => {
        //     commit('APP_HOMELIST_PUSH_NEWER', response.data);
        // });

        // commit('APP_HOMELIST_CLEAR');
        // return dispatch('APP_HOMELIST_LOAD_OLDER', payload);

        var params = {
            more: 'older',
            search_index: '1+9999999999-99999999999999999999',
            id: 2147483647
        };
        return Api.surveys(params).then((response) => {
            commit('APP_HOMELIST_CLEAR');
            commit('APP_HOMELIST_PUSH_OLDER', response.data);
        });
    },
    [APP_HOMELIST_LOAD_OLDER] ({ dispatch, commit, state }, payload) {
        if (state.homelist.length) {
            var last = state.homelist[state.homelist.length - 1];
            var params = {
                more: 'older',
                search_index: last.search_index,
                id: last.id
            };
        } else {
            var params = {
                more: 'older',
                search_index: '1+9999999999-99999999999999999999',
                id: 2147483647
            };
        }
        return Api.surveys(params).then((response) => {
            commit('APP_HOMELIST_PUSH_OLDER', response.data);
        });
    }
}
