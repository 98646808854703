import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
    state: {
        mylist: [],
        mylistBottom: false,
        archive: [],
        archiveBottom: false,
        comments: [],
        commentsBottom: false
    },
    getters,
    mutations,
    actions
}
