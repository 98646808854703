<template>
    <div class="col-6 col-md-8 col-sm-10 col-xs-12 col-mx-auto">
        <h1 class="text-center text-primary">{{ $t('auth.name.h') }}</h1>
        <el-alert
            v-show="message"
            class="mb-2"
            type="info"
            show-icon
            :closable="false"
            :title="message"
        ></el-alert>
        <el-form @submit.native.prevent="submit">
            <el-form-item
                :label="$t('auth.name.f.name.l')"
                :error="errors.first('name')"
            >
                <el-input
                    v-model="form.name"
                    name="name"
                    v-validate="'required'"
                    :data-vv-as="$t('auth.name.f.name.e')"
                    :placeholder="$t('auth.name.f.name.p')"
                ></el-input>
            </el-form-item>
            <el-form-item class="text-right">
                <el-button @click="cancel">{{ $t('auth.name.f.btn_cl') }}</el-button>
                <el-button
                    native-type="submit"
                    type="primary"
                    :loading="loading"
                    :disabled="errors.any()"
                >{{ $t('auth.name.f.btn_ok') }}</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
    components: {
    },
    $_veeValidate: {
        validator: 'new'
    },
    data () {
        return {
            form: {
                name: null
            },
            loading: false,
            message: ''
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        })
    },
    created () {
        this.form.name = this.user.name;
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
            'AUTH_ACCOUNT_UPDATE'
        ]),
        cancel () {
            this.$router.push('/auth/account/');
        },
        submit () {
            this.$validator.validate().then((result) => {
                if (result) {
                    this.loading = true;
                    this.AUTH_ACCOUNT_UPDATE(this.form).then(() => {
                        this.$router.push('/auth/account/');
                        this.loading = false;
                    }).catch((e) => {
                        this.message = e.response.data ? this.$t('auth.'+e.response.data.code) : ''+e;
                        this.loading = false;
                    });
                }
            });
        }
    }
}
</script>

<style lang="scss">
</style>
