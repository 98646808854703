<template>
    <div class="app-main-theme-menu">
        <router-link
            v-if="user.profile_id"
            to="/"
            tag="div"
            class="app-main-theme-menu-link c-hand mx-1"
            :style="{ order: 1 }"
        >
            <i class="icon ion-ios-search mx-1"></i>
            <small><small class="text-ellipsis">{{ $t('app.menu.surveys') }}</small></small>
        </router-link>
        <router-link
            v-if="user.profile_id"
            to="/responses/my"
            tag="div"
            class="app-main-theme-menu-link c-hand mx-1"
            :style="{ order: 2 }"
        >
            <i class="icon ion-ios-checkmark-circle-outline mx-1"></i>
            <small><small class="text-ellipsis">{{ $t('app.menu.responses') }}</small></small>
        </router-link>
        <router-link
            v-if="user.company"
            to="/surveys/add"
            tag="div"
            class="app-main-theme-menu-link c-hand mx-1"
            :style="{ order: user.company ? 4 : 3 }"
        >
            <i class="icon ion-ios-add-circle-outline mx-1"></i>
            <small><small class="text-ellipsis">{{ $t('app.menu.add') }}</small></small>
        </router-link>
        <router-link
            v-if="user.company || user.researcher"
            to="/surveys"
            tag="div"
            class="app-main-theme-menu-link c-hand mx-1"
            :style="{ order: user.company ? 3 : 4 }"
        >
            <i class="icon ion-ios-help-circle-outline mx-1"></i>
            <small><small class="text-ellipsis">{{ $t('app.menu.my') }}</small></small>
        </router-link>
        <router-link
            to="/credits"
            tag="div"
            class="app-main-theme-menu-link c-hand mx-1"
            :style="{ order: 4 }"
        >
            <span class="icon ion-ios" style="font-size: 1.3em; font-weight: normal; margin: 0.134em 4px;">${{ credits.full }}<sup><small><small>{{ credits.part }}</small></small></sup></span>
            <small><small class="text-ellipsis">
                <!-- <span class="hide-lg">-</span> -->
                <relative-time v-if="account.balance" :time="account.balance_expire_time" />
                <span v-else >{{ $t('app.menu.credits') }}</span>
            </small></small>
        </router-link>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import RelativeTime from '@/App/components/utils/RelativeTime';
export default {
    components: {
        RelativeTime
    },
    data () {
        return {
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user,
            account: state => state.credits.account
        }),
        credits () {
            var balance = this.account.balance - this.account.balance_lock;
            return {
                full: Math.floor(balance/100),
                part: (balance%100).toString().padStart(2, '0')
            }
        }
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ])
    }
}
</script>

<style lang="scss">
.app-main-theme-menu {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.app-main-theme-menu-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
        line-height: 1em;
        font-size: 1.7em;
    }
    small.text-ellipsis {
        max-width: 25vw;
        display: inline-block;
    }
}
@media (min-width: 961px) {
    .app-main-theme-menu-link {
        flex-direction: row;
        small {
            font-size: 0.95em;
        }
        small.text-ellipsis {
            max-width: auto;
            display: inline;
        }
    }
}
</style>
