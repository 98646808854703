<template>
  <div v-if="place">
    <template v-if="place.place_name">

      <h5 class="text-ellipsis my-1">{{ place.place_name }}<span v-if="place.place_name_english && place.place_name_english != place.place_name" class="text-gray"> - {{ place.place_name_english }}</span></h5>
      <div class="text-ellipsis">{{ place.country_name }}<span v-if="place.country_name_english && place.country_name_english != place.country_name" class="text-gray"> - {{ place.country_name_english }}</span></div>
      <div class="text-ellipsis"><small class="text-gray">{{ [place.state_name, place.state_division_name].filter(x => x).join(', ') }}</small></div>

    </template>
    <template v-else-if="place.state_name">

      <h5 class="text-ellipsis my-1">{{ place.state_name }}<span v-if="place.state_name_english && place.state_name_english != place.state_name" class="text-gray"> - {{ place.state_name_english }}</span></h5>
      <div class="text-ellipsis">{{ place.country_name }}<span v-if="place.country_name_english && place.country_name_english != place.country_name" class="text-gray"> - {{ place.country_name_english }}</span></div>

    </template>
    <template v-else>

      <h5 class="text-ellipsis my-1">{{ place.country_name }}<span v-if="place.country_name_english && place.country_name_english != place.country_name" class="text-gray"> - {{ place.country_name_english }}</span></h5>

    </template>
  </div>
</template>

<script>
export default {
    props: {
        place: {
            required: true
        }
    }
}
</script>

<style lang="scss">
</style>
