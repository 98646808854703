export default [{
        code: 'en_US',
        name: 'English'
    }, {
        code: 'gr_GR',
        name: 'Ελληνικά'
    }, {
        code: 'pl_PL',
        name: 'Polski'
    }, {
        code: 'ru_RU',
        name: 'Русский'
}]
