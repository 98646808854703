<template>
    <div>
        <h1 class="text-center text-primary">{{ $t('responds.my.h') }}</h1>
        <br>
        <br>
        <div class="text-center">
            <transition
                mode="out-in"
                name="el-fade-in"
            >
                <div v-if="historyLoading == 'newer'" v-loading="historyLoading"></div>
                <el-button v-else size="mini" icon="el-icon-refresh" circle @click="historyLoadNewer"></el-button>
            </transition>
        </div>
        <br>
        <template v-if="history.length">
        <el-table
            :data="history"
            style="width: 100%"
            size="mini"
        >
          <el-table-column :label="$t('responds.my.table.name')" min-width="300">
          <div slot-scope="scope">
            <h6 class="my-1">{{ scope.row.survey_name }}</h6>
            <div class="text-gray">
              <i v-if="scope.row.company" class="icon ion-ios-business"></i>
              <i v-else class="icon ion-ios-contact"></i>
              <i>{{ scope.row.owner_name }}</i>
            </div>
          </div>
          </el-table-column>
          <el-table-column
              prop="create_time"
              :formatter="dateFormat"
              align="center"
              :label="$t('responds.my.table.date')"
              width="115"
          ></el-table-column>
          <el-table-column
            align="center"
            :label="$t('responds.my.table.status')"
            width="250"
          >
            <template slot-scope="scope">
              <h3 v-if="scope.row.create_time == scope.row.complete_time" class="icon ion-ios-remove-circle m-0"></h3>
              <span v-else-if="scope.row.complete_time">{{ timeFormat(scope.row) }}</span>
              <h3 v-else-if="scope.row.expired" class="icon ion-ios-hourglass rotate-180 m-0"></h3>
              <router-link
                v-else-if="!scope.row.complete_time"
                :to="'/respond/'+scope.row.suuid+'/'+scope.row.ruuid"
                target="_blank"
              >
                <el-button
                  type="danger"
                  size="mini"
                  round
                >
                  <relative-time :time="scope.row.expire_time" />
                  | {{ $t('app.home.btn_continue') }}
                  <i class="el-icon-arrow-right el-icon-right"></i>
                </el-button>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column
              align="right"
              :label="$t('responds.my.table.price')"
              width="130"
          >
            <template slot-scope="scope">
              <strong v-if="!scope.row.complete_time">{{ $n(scope.row.price/100, 'currency', 'en-US') }}</strong>
              <span v-else-if="scope.row.create_time == scope.row.complete_time">--</span>
              <strong v-else class="text-primary">{{ priceAmount(scope.row) }}</strong>
            </template>
          </el-table-column>
          <el-table-column
              align="right"
              :label="$t('responds.my.table.charity')"
              width="90"
          >
            <span slot-scope="scope">{{ charityAmount(scope.row) }}</span>
          </el-table-column>
        </el-table>
        <br>
        <div v-if="!historyBottom" class="text-center">
            <div v-if="historyLoading == 'older'" v-loading="historyLoading"></div>
            <el-button v-else size="mini" icon="el-icon-more" circle @click="historyLoadOlder"></el-button>
        </div>
        </template>
    </div>
</template>

<script>
import parseDate from 'date-fns/parse';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import RelativeTime from '@/App/components/utils/RelativeTime';
import Api from '../api';
export default {
    components: {
      RelativeTime
    },
    data () {
        return {
            history: [],
            historyBottom: false,
            historyLoading: null
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
        ...mapGetters([
        ])
    },
    created () {
        this.historyLoadNewer();
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        historyLoadNewer () {
          this.historyLoading = 'newer';
          this.history = [];
          this.historyLoadOlder();
        },
        historyLoadOlder () {
          this.historyLoading = this.historyLoading || 'older';
          if (this.history.length) {
            var last = this.history[this.history.length - 1];
            var params = {
                more: 'older',
                create_time: last.create_time,
                id: last.id
            };
          } else {
            var params = {
                more: 'older',
                create_time: new Date(),
                id: 2147483647
            };
          }
          Api.responses(params).then((response) => {
            for (var item of response.data) {
                var index = this.history.findIndex(x => x.id === item.id);
                if (index >= 0) {
                    this.history.splice(index, 1);
                }
            }
            this.history.push(...response.data);
            if (response.data.length == 0) {
                this.historyBottom = true;
            }
            this.historyLoading = null;
          });
        },
        dateFormat (row, column, cellValue, index) {
            return this.$d(parseDate(row.create_time), 'short');
        },
        timeFormat(row) {
          let date1 = parseDate(row.create_time);
          let date2 = parseDate(row.complete_time);
          let time = Math.ceil((date2 - date1) / 1000);
          let seconds = time % 60;
          let minutes = ((time - seconds) / 60) % 60;
          let hours = Math.floor(time / 86400);
          seconds = seconds < 10 ? `0${seconds}` : seconds;
          minutes = minutes < 10 ? `0${minutes}` : minutes;
          return hours ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
        },
        priceAmount (row) {
          let price = this.$n(row.price/100, 'currency', 'en-US');
          if (row.payment) {
            let payment = this.$n(row.payment/100, 'currency', 'en-US');
            price = `${price}+${payment}`;
          }
          return price;
        },
        charityAmount (row) {
          if (row.charity_percent) {
            let value = Math.round(row.price * (row.charity_percent || 0) / 100) / 100;
            return '-'+this.$n(value, 'currency', 'en-US');
          } else {
            return '--'
          }
        }
    }
}
</script>

<style lang="scss">
</style>
