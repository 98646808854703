<template>
    <div>
        <br><br>
        <div class="col-6 col-lg-8 col-md-10 col-sm-11 col-xs-12 col-mx-auto">
            <h1 class="text-center text-primary">{{ $t('auth.emailnew.h') }}</h1>
            <br>
            <el-alert
                class="mb-2"
                type="info"
                show-icon
                :closable="false"
                :title="$t('auth.emailnew.txt.t')"
                :description="$t('auth.emailnew.txt.d')"
            ></el-alert>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
    components: {
    },
    data () {
        return {
        }
    },
    computed: {
        ...mapState({
        })
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ])
    }
}
</script>

<style lang="scss">
</style>
