export default Object.freeze({
    sentry: {
        dsn: 'https://ed7a485445d6440ea8dbc612d3650c82@sentry.io/1291252'
    },
    routes: {
        unauthorized: [
            '/auth/sign-in',
            '/auth/sign-in-email-new-account',
            '/auth/sign-in-email-confirm',
            '/auth/lost-password-reset',
            '/auth/email-change-confirm'
        ],
        initialized: [
            '/respond',
            '/confirm',
            '/credits/refill/success',
            '/credits/refill/error'
        ]
    },
    paths: {
        unauthorized: [
            process.env.API_BASE_URL + '/init'
        ]
    },
    payments: {
        sandbox: false
    },
    commissions: {
        exchange: 2.9,
        withdrawal: 1.9,
        refill: 25,
        response: 0
    },
    vat: {
        home: 'PL',
        // https://www.avalara.com/vatlive/en/vat-rates/european-vat-rates.html
        AT: 20,
        BE: 21,
        BG: 20,
        HR: 25,
        CY: 19,
        CZ: 21,
        DK: 25,
        EE: 20,
        FI: 24,
        FR: 20,
        DE: 19,
        GR: 24, // EL
        HU: 27,
        IE: 23,
        IT: 22,
        LV: 21,
        LT: 21,
        LU: 17,
        MT: 18,
        NL: 21,
        PL: 23,
        PT: 23,
        RO: 19,
        SK: 20,
        SI: 22,
        ES: 21,
        SE: 25,
        GB: 20 // UK
    }
});
