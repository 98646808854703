<template>
    <el-card
        class="surveys-mylist-item"
        shadow="always"
    >
        <router-link
            :to="'/surveys/'+survey.id+'/edit'"
            tag="h5"
            class="mb-1 c-hand"
        >
            <div class="d-flex d-flex-space-between">
                <strong>{{ survey.name }}</strong>
                <el-popover
                    placement="bottom-end"
                    trigger="hover"
                    width="300"
                >
                    <small class="text-gray">
                        <div class="columns"><div class="column col-4">{{ $t('surveys.home.t_create') }}:</div><div class="column col-8"><relative-time :time="survey.create_time" :options="{addSuffix: true}" tooltip="right" /></div></div>
                        <div class="columns"><div class="column col-4">{{ $t('surveys.home.t_modify') }}:</div><div class="column col-8"><relative-time :time="survey.modify_time" :options="{addSuffix: true}" tooltip="right" /></div></div>
                        <div v-if="survey.active_time" class="columns"><div class="column col-4">{{ $t('surveys.home.t_publish') }}:</div><div class="column col-8"><relative-time :time="survey.active_time" :options="{addSuffix: true}" tooltip="right" /></div></div>
                        <div v-if="survey.promo_time" class="columns"><div class="column col-4">{{ $t('surveys.home.t_promote') }}:</div><div class="column col-8"><relative-time :time="survey.promo_time" :options="{addSuffix: true}" tooltip="right" /></div></div>
                        <div v-if="survey.stop_time" class="columns"><div class="column col-4">{{ $t('surveys.home.t_pause') }}:</div><div class="column col-8"><relative-time :time="survey.stop_time" :options="{addSuffix: true}" tooltip="right" /></div></div>
                        <div v-if="survey.archive_time" class="columns"><div class="column col-4">{{ $t('surveys.home.t_archive') }}:</div><div class="column col-8"><relative-time :time="survey.archive_time" :options="{addSuffix: true}" tooltip="right" /></div></div>
                    </small>
                    <i class="el-icon-edit text-primary" slot="reference"></i>
                </el-popover>
            </div>
        </router-link>
        <div>{{ survey.description }}</div>
        <div v-if="survey.active_time && !survey.stop_time" class="text-primary text-wrap">
            <small>{{ affiliateLink }}</small>
            <span class="text-nowrap">
                <a
                    :href="'http://www.facebook.com/share.php?u=' + affiliateLink"
                    target="_blank"
                    class="ml-2"
                >
                    <i class="icon ion-logo-facebook"></i>
                </a>
                <a
                    :href="'http://twitter.com/home?status=' + affiliateLink"
                    target="_blank"
                    class="ml-2"
                >
                    <i class="icon ion-logo-twitter"></i>
                </a>
            </span>
        </div>
        <div class="mt-2 pt-2 d-flex d-flex-space-between" style="align-items: flex-end; flex-wrap: wrap">
            <div class="text-nowrap mt-2 mr-2">
                <template v-if="survey.active_time">
                <!-- <router-link
                    v-if="!survey.stop_time && (survey.count_complete + survey.count_progress < survey.complete_quota)"
                    :to="'/surveys/'+survey.id+'/promote'"
                >
                    <el-button
                        type="primary"
                        size="mini"
                        round
                    >{{ $t('surveys.home.btn_promote') }}</el-button>
                </router-link> -->
                <h6 v-if="survey.count_complete + survey.count_progress >= survey.complete_quota" class="text-primary">{{ $t('surveys.home.btn_full') }}</h6>
                <el-button
                    v-if="survey.stop_time"
                    type="primary"
                    size="mini"
                    round
                    @click="resume"
                >{{ $t('surveys.home.btn_resume') }}</el-button>
                <el-button
                    v-else
                    type="warning"
                    size="mini"
                    round
                    @click="stop"
                >{{ $t('surveys.home.btn_stop') }}</el-button>
                <el-button
                    v-if="survey.stop_time && survey.count_complete > 0 && survey.count_progress == 0 && !survey.archive_time"
                    size="mini"
                    round
                    @click="archive"
                >{{ $t('surveys.home.btn_archive') }}</el-button>
                </template>
                <router-link
                    v-else
                    :to="'/surveys/'+survey.id+'/publish'"
                >
                    <el-button
                        type="danger"
                        size="mini"
                        round
                    >{{ $t('surveys.home.btn_publish') }}</el-button>
                </router-link>
                <el-button
                    v-if="survey.count_complete + survey.count_progress + survey.count_dropped == 0"
                    size="mini"
                    round
                    @click="del"
                >{{ $t('surveys.home.btn_delete') }}</el-button>
            </div>
            <div class="text-gray mt-2">
                <small class="text-nowrap mr-2">{{ $t('surveys.home.btn_price', [(survey.price/100).toFixed(2)]) }}</small>
                <small class="text-nowrap mr-2">{{ $t('surveys.home.btn_age', [survey.profile_age_min, survey.profile_age_max]) }}</small>
                <small class="text-nowrap mr-2"><i v-if="!survey.profile_sex || survey.profile_sex == 'female'" class="icon ion-ios-woman"></i><span v-if="!survey.profile_sex">+</span><i v-if="!survey.profile_sex || survey.profile_sex == 'male'" class="icon ion-ios-man"></i> ({{ survey.complete_quota }})</small>
                <small class="text-nowrap mr-2" v-if="screen_out.length"><i class="el-icon-share"></i>({{ screen_out.length }})</small>
            </div>
        </div>
        <div class="surveys-mylist-item-stats text-gray">
            <div class="columns text-center">
                <div class="column col-sm-4">
                    <span>{{ $t('surveys.home.i_complete') }}</span><span class="hide-md">: </span>
                    <br class="show-md">
                    <router-link
                        v-if="survey.count_complete"
                        :to="'/surveys/'+survey.id+'/responses/complete'"
                        tag="strong"
                        class="text-primary c-hand"
                    >{{ survey.count_complete }}</router-link>
                    <strong v-else>--</strong>
                </div>
                <div class="column col-sm-4">
                    <span>{{ $t('surveys.home.i_progress') }}</span><span class="hide-md">: </span>
                    <br class="show-md">
                    <router-link
                        v-if="survey.count_progress"
                        :to="'/surveys/'+survey.id+'/responses/progress'"
                        tag="strong"
                        class="text-primary c-hand"
                    >{{ survey.count_progress }}</router-link>
                    <span v-if="survey.count_progress && survey.count_dropped">+</span>
                    <span v-if="survey.count_dropped">{{ survey.count_dropped }}</span>
                    <strong v-if="survey.count_progress + survey.count_dropped === 0">--</strong>
                </div>
                <div class="column col-sm-4">
                    <span>{{ $t('surveys.home.i_out') }}</span><span class="hide-md">: </span>
                    <br class="show-md">
                    <router-link
                        v-if="survey.count_screen_out"
                        :to="'/surveys/'+survey.id+'/responses/screenout'"
                        tag="strong"
                        class="text-primary c-hand"
                    >{{ survey.count_screen_out }}</router-link>
                    <strong v-else>--</strong>
                </div>
                <div class="column col-sm-4">
                    <span>{{ $t('surveys.home.i_cost') }}</span><span class="hide-md">: </span>
                    <br class="show-md">
                    <router-link
                        v-if="survey.cost"
                        :to="'/surveys/'+survey.id+'/payments'"
                        tag="strong"
                        class="text-primary c-hand"
                    >{{ $n(survey.cost/100, 'currency', 'en-US') }}</router-link>
                    <strong v-else>--</strong>
                </div>
                <div class="column col-sm-4">
                    <span>{{ $t('surveys.home.i_time') }}</span><span class="hide-md">: </span>
                    <br class="show-md">
                    <strong>{{ meanTime }}</strong>
                </div>
                <div class="column col-sm-4 text-center pr-1">
                    <span>
                        <i class="icon ion-ios-star"></i>
                        <br v-if="!survey.comments_count" class="show-md">
                        <strong>{{ rating }}</strong>
                    </span>
                    <span class="hide-md">&nbsp;&nbsp;</span>
                    <br class="show-md">
                    <span
                        v-if="survey.comments_count"
                        class="text-primary c-hand"
                        @click="commentsShow = true"
                    >
                        <i class="icon ion-ios-chatbubbles"></i>
                        <strong class="pr-1">{{ survey.comments_count || '--' }}</strong>
                    </span>
                </div>
            </div>
        </div>
        <el-dialog
            :visible.sync="commentsShow"
            :title="$t('surveys.comments')"
        >
            <dialog-survey-comments
                v-if="commentsShow"
                :survey="survey"
            ></dialog-survey-comments>
        </el-dialog>
    </el-card>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import RelativeTime from '@/App/components/utils/RelativeTime';
import DialogSurveyComments from '@/Surveys/components/DialogSurveyComments';
export default {
    components: {
        RelativeTime,
        DialogSurveyComments
    },
    props: {
        survey: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            commentsShow: false
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
        ...mapGetters([
        ]),
        screen_out () {
            return JSON.parse(this.survey.screen_out);
        },
        meanTime () {
            var seconds = '--';
            var hours = '--';
            if (this.survey.mean_time_count) {
                var mean = Math.ceil(this.survey.mean_time_sum / this.survey.mean_time_count);
                seconds = mean % 60;
                seconds = seconds < 10 ? '0'+seconds : seconds;
                hours = Math.floor(mean / 60);
            }
            return hours + ':' + seconds;
        },
        rating () {
            if (this.survey.rating_count) {
                return (this.survey.rating_sum / this.survey.rating_count).toFixed(1);
            } else {
                return '--';
            }
        },
        affiliateLink () {
            return 'https://'+process.env.APP_BASE_DOMAIN+'/aff/'+this.user.affiliate_code+'/'+this.survey.uuid;
        }
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
            'SURVEYS_STOP',
            'SURVEYS_RESUME',
            'SURVEYS_ARCHIVE',
            'SURVEYS_DELETE'
        ]),
        stop () {
            this.SURVEYS_STOP(this.survey);
        },
        resume () {
            this.SURVEYS_RESUME(this.survey);
        },
        archive () {
            this.SURVEYS_ARCHIVE(this.survey);
        },
        del () {
            this.$confirm(this.$t('surveys.home.btn_delete_msg'), {
                confirmButtonText: this.$t('surveys.home.btn_delete_ok'),
                cancelButtonText: this.$t('surveys.home.btn_delete_cancel'),
                showClose: false,
                type: 'warning',
                center: true
            }).then(() => {
                this.SURVEYS_DELETE(this.survey);
            }).catch(() => {});
        }
    }
}
</script>

<style lang="scss">
.surveys-mylist-item {
    margin-bottom: 40px;
}
.surveys-mylist-item-stats {
    margin: 20px -20px -20px;
    padding: 8px;
    font-size: 0.8em;
    color: #666;
    background-color: #fafafa;
    border-top: 1px solid #f3f3f3;
}
</style>
