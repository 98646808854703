import Api from '../api';
const CREDITS_ACCOUNT_REFRESH = 'CREDITS_ACCOUNT_REFRESH';
const CREDITS_ACCOUNT_HISTORY_LOAD_NEWER = 'CREDITS_ACCOUNT_HISTORY_LOAD_NEWER';
const CREDITS_ACCOUNT_HISTORY_LOAD_OLDER = 'CREDITS_ACCOUNT_HISTORY_LOAD_OLDER';

export default {
    [CREDITS_ACCOUNT_REFRESH] ({ commit, state }) {
        Api.account(state.account).then((response) => {
            commit('CREDITS_ACCOUNT_SET', response.data);
        });
    },
    [CREDITS_ACCOUNT_HISTORY_LOAD_NEWER] ({ dispatch, commit, state, rootState }, payload) {
        dispatch('CREDITS_ACCOUNT_REFRESH');
        if (state.history.length) {
            var first = state.history[0];
            var params = {
                more: 'newer',
                modify_time: first.modify_time,
                id: first.id
            };
        } else {
            return dispatch('CREDITS_ACCOUNT_HISTORY_LOAD_OLDER', payload);
        }
        return Api.history(payload, params).then((response) => {
            commit('CREDITS_ACCOUNT_HISTORY_PUSH_NEWER', response.data);
        });
    },
    [CREDITS_ACCOUNT_HISTORY_LOAD_OLDER] ({ dispatch, commit, state, rootState }, payload) {
        if (state.history.length) {
            var last = state.history[state.history.length - 1];
            var params = {
                more: 'older',
                modify_time: last.modify_time,
                id: last.id
            };
        } else {
            var params = {
                more: 'older',
                modify_time: new Date(),
                id: 2147483647
            };
        }
        return Api.history(payload, params).then((response) => {
            commit('CREDITS_ACCOUNT_HISTORY_PUSH_OLDER', response.data);
        });
    }
}
