import Api from '../api';
const RESPONDS_ = 'RESPONDS_';

export default {
    [RESPONDS_] ({ commit, state, rootState }, payload) {
        return Api._().then((response) => {
            commit('RESPONDS_', response.data);
        });
    }
}
