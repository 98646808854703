const CREDITS_ACCOUNT_SET = 'CREDITS_ACCOUNT_SET';
const CREDITS_ACCOUNT_HISTORY_PUSH_NEWER = 'CREDITS_ACCOUNT_HISTORY_PUSH_NEWER';
const CREDITS_ACCOUNT_HISTORY_PUSH_OLDER = 'CREDITS_ACCOUNT_HISTORY_PUSH_OLDER';

export default {
    [CREDITS_ACCOUNT_SET] (state, payload) {
        state.account = payload;
    },
    [CREDITS_ACCOUNT_HISTORY_PUSH_NEWER] (state, payload) {
        for (var item of payload) {
            var index = state.history.findIndex(x => x.id === item.id);
            if (index >= 0) {
                state.history.splice(index, 1);
            }
        }
        state.history.splice(0, 0, ...payload);
    },
    [CREDITS_ACCOUNT_HISTORY_PUSH_OLDER] (state, payload) {
        for (var item of payload) {
            var index = state.history.findIndex(x => x.id === item.id);
            if (index >= 0) {
                state.history.splice(index, 1);
            }
        }
        state.history.push(...payload);
        if (payload.length == 0) {
            state.historyBottom = true;
        }
    }
}
