<template>
    <div>
        <h1 class="text-center text-primary">{{ $t('credits.home.h') }}</h1>
        <div class="columns">
            <div class="column col-6 col-md-8 col-sm-12 col-mx-auto">
                <el-card shadow="never" class="text-center m-2">
                    <h2 class="m-2"><strong>${{ (amount/100).toFixed(2) }}</strong></h2>
                    <div v-if="account.balance && account.balance_expire_time" class="text-center">
                        <small class="text-gray">{{ $t('credits.home.l_expire') }}</small>
                        <h5 class="mt-1 mb-0"><relative-time :time="account.balance_expire_time" /></h5>
                    </div>
                </el-card>
                <p
                    v-if="missing > 0"
                    class="text-center text-gray mb-0"
                ><small>{{ $t('credits.home.l_need_more', [$n(missing/100, 'currency', 'en-US')]) }}</small></p>
            </div>
        </div>
        <br>
        <div class="text-center">
            <router-link v-if="user.company || user.researcher" :to="'/credits/refill'">
                <el-button
                    class="m-2"
                    type="danger"
                    round
                >{{ $t('credits.home.btn_buy') }}</el-button>
            </router-link>
            <br>
            <el-button
                v-if="user.company === false && (amount >= 500)"
                class="m-2"
                type="primary"
                round
                :loading="loading.withdraw"
                @click="withdrawal = true"
            >{{ $t('credits.home.btn_get') }}</el-button>
            <el-button
                v-if="user.company"
                class="m-2"
                type="info"
                round
                :loading="loading.voucher"
                @click="voucher = true"
            >{{ $t('credits.home.btn_voucher') }}</el-button>
            <br>
        </div>
        <dialog-withdrawal
            v-if="withdrawal"
            :visible.sync="withdrawal"
            @done="loadNewer"
        ></dialog-withdrawal>
        <dialog-voucher
            v-if="voucher"
            :visible.sync="voucher"
            @done="loadNewer"
        ></dialog-voucher>
        <br>
        <div class="text-center">
            <transition
                mode="out-in"
                name="el-fade-in"
            >
                <div v-if="loading.newer" v-loading="loading.newer"></div>
                <el-button v-else size="mini" icon="el-icon-refresh" circle @click="loadNewer"></el-button>
            </transition>
        </div>
        <br>
        <template v-if="history.length">
            <el-table
                :data="history"
                style="width: 100%"
                size="mini"
                :row-class-name="rowClassName"
            >
                <el-table-column
                    prop="close_time"
                    :formatter="dateFormat"
                    align="center"
                    :label="$t('credits.home.list.date')"
                    width="115"
                ></el-table-column>
                <el-table-column
                    prop="status"
                    align="right"
                    class-name="text-nowrap"
                    label=""
                    width="30"
                >
                    <template slot-scope="scope">
                        <el-tooltip
                            v-if="rowStatus(scope.row) == 'pending'"
                            effect="light"
                            :content="statusFormat(scope.row)"
                            placement="top-start"
                        >
                                <i class="icon ion-ios-lock"></i>
                        </el-tooltip>
                        <i v-else-if="rowStatus(scope.row) == 'timeout'" class="icon ion-md-time"></i>
                        <i v-else-if="rowStatus(scope.row) == 'error'" class="icon ion-ios-warning"></i>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="name"
                    :label="$t('credits.home.list.operation')"
                    min-width="150"
                ></el-table-column>
                <el-table-column
                    prop="amount"
                    :formatter="amountFormat"
                    align="right"
                    :label="$t('credits.home.list.amount')"
                    fixed="right"
                    width="90"
                >
                    <span
                        slot-scope="scope"
                        class="text-nowrap"
                        :class="scope.row.status == 'complete' ? (scope.row.amount < 0 ? 'text-error' : 'text-primary') : ''"
                    >{{ $n(scope.row.amount/100, 'currency', 'en-US') }}</span>
                </el-table-column>
                <!-- <el-table-column
                    prop="balance"
                    :formatter="balanceFormat"
                    align="right"
                    :label="$t('credits.home.list.balance')"
                    fixed="right"
                    width="90"
                ></el-table-column> -->
            </el-table>
            <br>
            <div v-if="!bottom" class="text-center">
                <div v-if="loading.older" v-loading="loading.older"></div>
                <el-button v-else size="mini" icon="el-icon-more" circle @click="loadOlder"></el-button>
            </div>
        </template>

        <div v-else class="text-center m-2 p-2 text-gray">{{ $t('credits.home.nodata') }}</div>
    </div>
</template>

<script>
import parseDate from 'date-fns/parse';
import { distanceInWordsToNow } from '@/locale/i18n';
import { mapState, mapMutations, mapActions } from 'vuex';
import RelativeTime from '@/App/components/utils/RelativeTime';
import DialogWithdrawal from '@/Credits/components/DialogWithdrawal';
import DialogVoucher from '@/Credits/components/DialogVoucher';
export default {
    components: {
        RelativeTime,
        DialogWithdrawal,
        DialogVoucher
    },
    data () {
        return {
            loading: {
                newer: false,
                older: false,
                withdrawal: false,
                voucher: false
            },
            withdrawal: false,
            voucher: false
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user,
            account: state => state.credits.account,
            history: state => state.credits.history,
            bottom: state => state.credits.historyBottom
        }),
        amount () {
            return this.account.balance - this.account.balance_lock;
        },
        missing () {
            return 500 - this.amount;
        }
    },
    created () {
        this.loadNewer();
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
            'CREDITS_ACCOUNT_HISTORY_LOAD_NEWER',
            'CREDITS_ACCOUNT_HISTORY_LOAD_OLDER'
        ]),
        loadNewer () {
            this.loading.newer = true;
            this.CREDITS_ACCOUNT_HISTORY_LOAD_NEWER(this.account).then(() => {
                this.loading.newer = false;
            });
        },
        loadOlder () {
            this.loading.older = true;
            this.CREDITS_ACCOUNT_HISTORY_LOAD_OLDER(this.account).then(() => {
                this.loading.older = false;
            });
        },
        rowClassName ({row}) {
            return row.status !== 'complete' ? 'text-gray' : '';
        },
        rowStatus (row) {
            if (row.status == 'timeout' || (row.status == 'pending' && parseDate(row.expire_time) < new Date())) {
                return 'timeout';
            } else {
                return row.status;
            }
        },
        dateFormat (row, column, cellValue, index) {
            var date = (row.status == 'pending' ? row.open_time : row.close_time);
            return this.$d(parseDate(date), 'short');
        },
        statusFormat (row, column, cellValue, index) {
            return distanceInWordsToNow(parseDate(row.expire_time));
        },
        amountFormat (row, column, cellValue, index) {
            return this.$n(cellValue/100, 'currency', 'en-US');
        },
        balanceFormat (row, column, cellValue, index) {
            return row.status === 'complete' ? this.$n(cellValue/100, 'currency', 'en-US') : '';
        }
    }
}
</script>

<style lang="scss">
.el-table__row.text-gray {
    opacity: 0.5;
}
</style>
