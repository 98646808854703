<template>
    <div v-if="survey">
        <h1 class="text-center text-primary">{{ $t('surveys.publish.h') }}</h1>
        <h4 class="text-center">{{ survey.name }}</h4>
        <br>
        <div class="text-center">
            <p class="text-gray mb-1"><small>{{ $t('surveys.publish.h_link_aff') }}</small></p>
            <h6 class="text-primary text-wrap mb-2">{{ affiliateLink }}</h6>
            <a
                :href="'http://www.facebook.com/share.php?u=' + affiliateLink"
                target="_blank"
                class="m-2 p-2"
            >
                <i class="icon icon-3x ion-logo-facebook"></i>
            </a>
            <a
                :href="'http://twitter.com/home?status=' + affiliateLink"
                target="_blank"
                class="m-2 p-2"
            >
                <i class="icon icon-3x ion-logo-twitter"></i>
            </a>
            <br>
        </div>
        <br><br>
        <el-card shadow="always">
            <div slot="header">
                <h4 class="text-center m-0">{{ $t('surveys.publish.warning') }} !!!</h4>
            </div>
            <div class="text-center">
                <p v-html="$t('surveys.publish.info')"></p>
                <br>
                <p class="text-gray mb-1"><small>{{ $t('surveys.publish.h_link') }}</small></p>
                <h5 class="text-primary text-break">{{ endLink }}</h5>
                <br><br>
                <div class="text-center">
                    <el-button
                        type="danger"
                        round
                        icon="el-icon-success"
                        @click="publish"
                    >{{ $t('surveys.publish.btn_ok') }}</el-button>
                </div>
                <br>
            </div>
            <br><br>
            <p class="text-gray mb-2"><small>{{ $t('surveys.publish.h_msg') }}</small></p>
            <el-card shadow="never">
                <div v-html="$t('surveys.publish.msg', [siteLink, endLink])"></div>...
            </el-card>
        </el-card>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
export default {
    components: {
    },
    data () {
        return {
            loading: false
        }
    },
    computed: {
        ...mapState({
            user: state => state.app.user
        }),
        ...mapGetters([
            'SURVEYS_FIND'
        ]),
        survey () {
            return this.SURVEYS_FIND(this.$route.params.id);
        },
        endLink () {
            return 'https://'+process.env.APP_BASE_DOMAIN+'/end/'+this.survey.complete_code;
        },
        affiliateLink () {
            return 'https://'+process.env.APP_BASE_DOMAIN+'/aff/'+this.user.affiliate_code+'/'+this.survey.uuid;
        },
        siteLink () {
            return 'http://'+process.env.APP_BASE_DOMAIN;
        }
    },
    created () {
        if (!this.survey) {
            this.$router.push('/surveys');
        }
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
            'SURVEYS_ACTIVATE'
        ]),
        publish () {
            this.loading = true;
            this.SURVEYS_ACTIVATE(this.survey).then(() => {
                this.loading = false;
                this.$router.push('/surveys');
            });
        }
    }
}
</script>

<style lang="scss">
</style>
